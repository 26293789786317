// @ts-nocheck
import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
  ErrorRounded,
  RefreshRounded,
  EmailRounded,
  HourglassTopRounded,
  LocationOnRounded,
  CalendarTodayRounded,
} from "@mui/icons-material";
import CheckContainer from "./V2_Home_Page/CheckContainer";
import Alert from "./TailwindComponents/Alert";
import StatusIndicator from "./TailwindComponents/StatusIndicator";
import { differenceInYears, format, parseISO } from "date-fns";
import RecommendationBox from "./RecommendationBox";
import { Tab } from "@headlessui/react";
import SmartTabs from "./TailwindComponents/SmartTabs";
import { ADVERSE_MEDIA_CHECK_ID_V2, PEP_CHECK_ID_V2 } from "@/constants/vars";

interface EmbedChecksLayoutProps {
  jobMetadata: any;
  checks: any[];
  error: string | null;
  agentKey: string;
  apiKey: string;
  endpointUrl: string;
  onRefresh: () => void;
  view: "report" | "tabs";
}

const tabOrder = [ADVERSE_MEDIA_CHECK_ID_V2, PEP_CHECK_ID_V2];

const EmbedChecksLayout = ({
  jobMetadata,
  checks,
  error,
  agentKey,
  apiKey,
  endpointUrl,
  onRefresh,
  view = "report",
}: EmbedChecksLayoutProps) => {
  const [toastState, setToastState] = useState<{ show: boolean; status: string }>({
    show: false,
    status: "in progress",
  });
  const [displayedCheck, setDisplayedCheck] = useState<any | null>(null);

  const reportContainerRef = useRef(null);

  useEffect(() => {
    if (checks && checks.length > 0 && !displayedCheck) {
      setDisplayedCheck(checks.find((check: { command_id: string }) => check.command_id === tabOrder[0]) || checks[0]);
    } else if (checks && checks.length > 0 && displayedCheck !== null) {
      setDisplayedCheck(
        checks?.find(
          (check: { command_instance_id: string }) => check.command_instance_id === displayedCheck?.command_instance_id,
        ),
      );
    }
  }, [checks]);

  const selfAttestedData = jobMetadata?.job?.input_payload?.self_attested_data;

  const onContactSupportClickHandler = () => {
    let mailtoLink;
    if (error) {
      const subject = `Error report in ${agentKey}`;
      const body = `The following error occurred while loading the data.\n\n${error}`;
      mailtoLink = `mailto:support@parcha.ai?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
        body || "",
      )}`;
    } else {
      const subject = `Error report in ${agentKey} for job ${jobMetadata?.job?.id}`;
      const body = `There was an error with job ID ${jobMetadata?.job?.id}.\n\n`;
      mailtoLink = `mailto:support@parcha.ai?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
        body || "",
      )}`;
    }
    window.open(mailtoLink, "_blank");
  };

  const Toast = () => {
    if (!toastState.show) return null;
    return <div className="fixed top-4 right-4 z-50">{/* ... Toast implementation ... */}</div>;
  };

  const getRowsCount = (check: any) => {
    if (check.command_id === ADVERSE_MEDIA_CHECK_ID_V2) {
      return check.check_result?.verified_adverse_media_hits?.length ?? 0;
    }
    if (check.command_id === PEP_CHECK_ID_V2) {
      return check.check_result?.verified_pep_hits?.length ?? 0;
    }
    return 1;
  };

  const getBadgeLevel = (check: any) => {
    const listToCheck =
      check.command_id === ADVERSE_MEDIA_CHECK_ID_V2
        ? check.check_result?.verified_adverse_media_hits
        : check.check_result?.verified_pep_hits;

    return listToCheck?.some((hit: any) => Boolean(hit.escalate_for_review)) ? "high" : "low";
  };

  const renderChecks = () => {
    if (!checks || checks.length === 0 || jobMetadata?.job?.status === "queued") return null;

    const sortedChecks = [...checks].sort((a, b) => a.command_name.localeCompare(b.command_name));

    const onTabSelectHandler = (tabId: string) => {
      setDisplayedCheck(checks?.find((check) => check?.command_id === tabId));
    };

    if (view === "tabs") {
      const tabs = [
        ...tabOrder
          .map((commandId) => {
            const check = sortedChecks.find((c) => c.command_id === commandId);
            if (!check) return null;
            return {
              id: check?.command_id,
              label: check?.command_name.replace(" Check", "") || check?.command_id,
              rowsCount: getRowsCount(check),
              badgeLevel: check.passed === false ? "high" : "low",
            };
          })
          .filter(Boolean),
        ...checks
          .filter((check) => !tabOrder.includes(check.command_id))
          .map((check) => ({
            id: check.command_id,
            label: check.command_name.replace(" Check", "") || check.command_id,
            rowsCount: getRowsCount(check),
            badgeLevel: getBadgeLevel(check),
          })),
      ];

      return (
        <>
          <SmartTabs
            tabs={tabs}
            selectedTab={displayedCheck?.command_id}
            onSelect={onTabSelectHandler}
            className="ignore-in-pdf"
          />
          {displayedCheck && (
            <div key={displayedCheck.commandInstanceId} className="flex flex-col gap-y-5">
              <CheckContainer
                title={displayedCheck.command_name}
                agentRun={jobMetadata?.job}
                apiKey={apiKey}
                endpointUrl={endpointUrl}
                commandResult={displayedCheck}
                key={displayedCheck.commandInstanceId}
                messages={displayedCheck.status_messages || []}
                auditLogMessages={
                  displayedCheck.status_messages?.filter((message: any) =>
                    ["api_results", "command_results", "thought"].includes(message.event),
                  ) || []
                }
                compact={true}
                showAllContent={true}
              />
            </div>
          )}
        </>
      );
    }

    return (
      <div className="flex flex-col gap-y-4">
        {sortedChecks.map((check) => (
          <div
            key={check.command_instance_id}
            className="flex flex-col gap-y-2 relative mb-8 pb-8 border-b border-slate-200"
          >
            <h2 className="text-xl font-medium text-slate-900 px-5">{check.command_name.replace(" Check", "")}</h2>
            {check.recommendation && (
              <Alert
                level={
                  check.recommendation === "Approve"
                    ? "success"
                    : check.recommendation === "Review"
                      ? "partial"
                      : check.recommendation === "Deny"
                        ? "failure"
                        : "normal"
                }
                message={
                  <div className="flex flex-col gap-y-2">
                    <div className="flex items-center gap-x-2">
                      {check.recommendation && check.answer && (
                        <span>
                          <span className="capitalize font-semibold">
                            {check.recommendation === "Approve"
                              ? "Low Risk"
                              : check.recommendation === "Review"
                                ? "Medium Risk"
                                : check.recommendation === "Deny"
                                  ? "High Risk"
                                  : check.recommendation}
                            :
                          </span>{" "}
                          {check.answer}
                        </span>
                      )}
                    </div>
                  </div>
                }
              />
            )}
            <CheckContainer
              title={check.command_name}
              agentRun={jobMetadata?.job}
              apiKey={apiKey}
              endpointUrl={endpointUrl}
              commandResult={check}
              messages={check.status_messages || []}
              auditLogMessages={[]}
              compact={true}
              showAllContent={true}
              auditLogVisible={false}
              displayOnlyMatches={true}
              hideFeedbackControls={true}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="relative flex flex-col gap-y-4 h-full overflow-auto px-8">
      <Toast />
      <div id="report-container" ref={reportContainerRef} className="flex flex-col gap-y-2 h-full">
        {selfAttestedData && (
          <div className="flex justify-between items-baseline p-5 bg-slate-50 text-xs text-slate-900 border-b border-slate-200">
            <div className="flex flex-col gap-y-2">
              <span className="text-sm font-semibold">
                {selfAttestedData?.first_name} {selfAttestedData?.last_name}
              </span>
              <div className="flex items-center gap-x-5 text-slate-700">
                {selfAttestedData?.date_of_birth && (
                  <div className="flex items-center gap-x-1">
                    <CalendarTodayRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                    <span>
                      {differenceInYears(new Date(), parseISO(selfAttestedData.date_of_birth))},{" "}
                      {format(parseISO(selfAttestedData.date_of_birth), "MMMM do yyyy")}
                    </span>
                  </div>
                )}
                {selfAttestedData?.country_of_residence && (
                  <div className="flex items-center gap-x-1">
                    <LocationOnRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                    <span>{selfAttestedData?.country_of_residence}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-y-2 items-end">
              {jobMetadata?.job?.status === "complete" ? (
                <RecommendationBox recommendation={jobMetadata?.job?.recommendation} />
              ) : null}
              {jobMetadata?.job?.status === "queued" && (
                <div className="flex items-center gap-x-1">
                  <StatusIndicator status={jobMetadata?.job?.status} />
                  <span className="text-xs text-slate-500 capitalize">{jobMetadata?.job?.status}</span>
                </div>
              )}
            </div>
          </div>
        )}

        {jobMetadata?.job?.status === "queued" ? (
          <div className="flex flex-col justify-center items-center w-full h-full text-brand-purple text-sm gap-y-2">
            <HourglassTopRounded sx={{ fontSize: "2rem" }} className="text-slate-500" />
            <div className="text-center">
              <p className="font-bold">This job is queued up</p>
              <p>It will start executing once the current in progress jobs are completed.</p>
            </div>
          </div>
        ) : jobMetadata?.job?.status === "error" || error ? (
          <div className="flex items-center h-full mx-auto w-3/4">
            <div className="flex flex-col justify-center items-center w-full py-10 px-5 lg:p-10 text-sm gap-y-2 bg-slate-50 rounded-[1.25rem]">
              <ErrorRounded sx={{ fontSize: "2rem" }} className="text-red-600" />
              <div className="text-center">
                <p className="text-xs lg:text-sm font-semibold lg:font-bold text-slate-700">An error has occurred</p>
                {error && <p className="text-slate-500 text-xs">There was an issue loading the data.</p>}
                <button
                  type="button"
                  className="mt-2 text-sm relative inline-flex justify-center items-center rounded-md bg-white border border-solid border-slate-300 p-2 h-4 text-slate-900 focus:z-10"
                  onClick={onRefresh}
                >
                  <RefreshRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                  <span className="line-clamp-1 text-xs">Refresh This Page</span>
                </button>
                <p className="mt-4 text-xs text-slate-500">
                  If the problem persists please contact support and we will look into the issue as soon as possible.
                </p>
                <button
                  type="button"
                  className="mt-2 text-sm relative inline-flex justify-center items-center rounded-md bg-white border border-solid border-slate-300 p-2 h-4 text-slate-900 focus:z-10"
                  onClick={onContactSupportClickHandler}
                >
                  <EmailRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                  <span className="line-clamp-1 text-xs">Contact Support</span>
                </button>
              </div>
            </div>
          </div>
        ) : null}

        {jobMetadata?.job?.status !== "queued" && checks && checks.length > 0 && renderChecks()}
      </div>
    </div>
  );
};

export default EmbedChecksLayout;
