import { BusinessCenterRounded, SearchOffRounded } from "@mui/icons-material";
import RiskCardV3 from "./TailwindComponents/RiskCardV3";
import { useEffect, useState } from "react";
import { useParchaApi } from "@/hooks/useParchaApi";
import { formatNumberToCurrency } from "@/utils";
import { LinkedInExperienceEntryV2 } from "./V2_Home_Page/CheckContainer/index";
import SourceItem from "./TailwindComponents/SourceItem";
import { ProfileReview } from "@/types";

interface Profile {
  id?: string;
  reference_id?: string;
  full_name: string | null | undefined;
  profile_review: ProfileReview;
  source_of_wealth_estimation?: {
    estimation_reasoning?: string;
    current_annual_salary_low?: number;
    current_annual_salary_high?: number;
    working_experiences_with_wealth?: Array<any>; // Type from LinkedInExperienceEntryV2 props
  };
  linkedin_profile_url?: string;
}

interface SourceOfWealthV2Props {
  apiKey?: string;
  agentKey: string;
  agentInstanceId: string;
  commandId: string;
  commandInstanceId: string;
  endpointUrl: string;
  jobId: string;
  showAllContent?: boolean;
  profiles?: Profile[];
  input?: any;
  hideHeader?: boolean;
  allCollapsed?: boolean;
}

interface FeedbackInput {
  score: boolean;
  data: {
    world_check_id: string;
  };
  row_id: string;
  comment: string;
  created_at: Date;
}

const SourceOfWealthV2 = ({
  apiKey,
  agentKey,
  agentInstanceId,
  commandId,
  commandInstanceId,
  endpointUrl,
  jobId,
  showAllContent,
  profiles,
  input,
  hideHeader = false,
  allCollapsed = false,
}: SourceOfWealthV2Props) => {
  const parchaApi = useParchaApi();
  const [feedbackInputs, setFeedbackInputs] = useState<any[]>([]);
  const [isLoadingFeedback, setIsLoadingFeedback] = useState(true);

  const filteredProfiles = profiles?.filter(
    (profile) => profile.profile_review?.match_rating?.match === "strong_match",
  );

  const groupedByWorldCheckId = feedbackInputs?.reduce((acc, feedback) => {
    const rowId = feedback.row_id;

    if (!acc[rowId] || new Date(acc[rowId].created_at) < new Date(feedback.created_at)) {
      acc[rowId] = feedback;
    }
    return acc;
  }, {});

  useEffect(() => {
    const getFeedbackInput = async () => {
      let feedbackInputs;
      if (apiKey) {
        feedbackInputs = await parchaApi.getFeedbackInputsByKeyFromAPI(endpointUrl, jobId, "source_of_wealth", apiKey);
      } else {
        feedbackInputs = await parchaApi.getFeedbackInputsByKey(endpointUrl, jobId, "source_of_wealth");
      }

      if (feedbackInputs) {
        setFeedbackInputs(feedbackInputs);
      }
      setIsLoadingFeedback(false);
    };
    getFeedbackInput();
  }, [apiKey]);

  const handleSubmitFeedback = async (isValid: boolean, rowId: string, comment: string) => {
    if (apiKey) {
      await parchaApi.sendFeedbackToAPI(
        endpointUrl,
        jobId,
        "source_of_wealth",
        isValid,
        undefined,
        {
          world_check_id: rowId,
          endpoint: {
            agentName: agentKey,
            endpointUrl,
          },
          job_id: jobId,
          job_url: window.location.href.replace(/([?&])api_key=[^&]*(&|$)/, "$1"),
          feedback_key: `${agentInstanceId}${commandInstanceId}`,
          agent_instance_id: agentInstanceId,
          command_instance_id: commandInstanceId,
          command_id: commandId,
          agent_key: agentKey,
          command_result: {
            content: {
              output: {
                profiles,
              },
            },
          },
        },
        comment,
        rowId,
        apiKey,
      );
    } else {
      await parchaApi.sendFeedback(
        endpointUrl,
        jobId,
        "source_of_wealth",
        isValid,
        undefined,
        {
          world_check_id: rowId,
          endpoint: {
            agentName: agentKey,
            endpointUrl,
          },
          job_id: jobId,
          job_url: window.location.href.replace(/([?&])api_key=[^&]*(&|$)/, "$1"),
          feedback_key: `${agentInstanceId}${commandInstanceId}`,
          agent_instance_id: agentInstanceId,
          command_instance_id: commandInstanceId,
          command_id: commandId,
          agent_key: agentKey,
          command_result: {
            content: {
              output: {
                profiles,
              },
            },
          },
        },
        comment,
        rowId,
      );
    }
    setFeedbackInputs([
      ...feedbackInputs.filter((feedback) => feedback.row_id !== rowId),
      {
        score: isValid,
        data: {
          world_check_id: rowId,
        },
        row_id: rowId,
        comment,
        created_at: new Date(),
      },
    ]);
  };

  return (
    <div className="flex justify-center w-full">
      <div className="flex flex-col w-full">
        {!hideHeader && (
          <div className="flex items-center justify-between bg-white rounded-lg w-full mb-4">
            <div className="flex items-center space-x-2">
              <BusinessCenterRounded className="h-5 w-5" />
              <div className="text-sm font-medium text-slate-900">Sources of Income</div>
            </div>
          </div>
        )}
        {filteredProfiles?.length === 0 ? (
          <div className="flex flex-col h-full bg-slate-50 p-3 rounded-[8px] w-auto">
            <div className="flex items-center">
              <SearchOffRounded className="h-4 w-4 text-slate-500 mr-2" />
              <div className="text-sm text-slate-700">
                Could not find enough information to confidently provide sources of income.
              </div>
            </div>
          </div>
        ) : (
          <>
            {filteredProfiles?.map((profile, index) => (
              <div key={index} className="border border-slate-200 rounded-lg mb-3">
                <RiskCardV3
                  type="source_of_wealth"
                  key={`source-of-wealth-hit-${profile.id}-${index}`}
                  hit={profile}
                  input={input}
                  handleSubmitFeedback={handleSubmitFeedback}
                  feedback={groupedByWorldCheckId[profile.reference_id ?? profile.id ?? ""]}
                  expandByDefault={index === 0 && !allCollapsed}
                  isLoadingFeedback={isLoadingFeedback}
                  showAllContent={showAllContent ?? false}
                  renderContent={() => (
                    <div className="flex flex-col">
                      {/* Profile Details */}
                      <div className="flex justify-between items-center gap-x-10 mb-4">
                        {profile.source_of_wealth_estimation &&
                          profile.source_of_wealth_estimation.estimation_reasoning && (
                            <div className="text-xs text-slate-700 bg-slate-100 p-5 border-b-0 rounded-lg w-2/3">
                              {profile.source_of_wealth_estimation.estimation_reasoning}
                            </div>
                          )}
                        {profile.source_of_wealth_estimation &&
                          profile.source_of_wealth_estimation.current_annual_salary_low != null &&
                          profile.source_of_wealth_estimation.current_annual_salary_high != null && (
                            <div className="text-sm text-slate-700 w-1/3">
                              <div className="flex items-center font-semibold justify-end">
                                Annual Salary:{" "}
                                <span className="text-green-700 ml-1">
                                  {formatNumberToCurrency(
                                    profile.source_of_wealth_estimation.current_annual_salary_low,
                                  )}{" "}
                                  -{" "}
                                  {formatNumberToCurrency(
                                    profile.source_of_wealth_estimation.current_annual_salary_high,
                                  )}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                      <div className="border-t border-slate-200 mb-5">
                        {profile.source_of_wealth_estimation?.working_experiences_with_wealth?.map((entry) => (
                          <LinkedInExperienceEntryV2 key={entry.title} {...entry} />
                        ))}

                        {profile.linkedin_profile_url && (
                          <>
                            <h4 className="text-sm font-medium text-slate-900 mt-9 mb-4">Sources</h4>
                            <SourceItem
                              key={profile.linkedin_profile_url ?? "xxx"}
                              url={profile.linkedin_profile_url ?? undefined}
                              sourceName={profile.linkedin_profile_url ?? undefined}
                              title={profile.linkedin_profile_url ?? undefined}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  showVendorId={false}
                  greenColor={true}
                />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default SourceOfWealthV2;
