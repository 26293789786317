import React from "react";
import clsx from "clsx";
import { formatDistanceToNowStrict } from "date-fns";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";

import ApproveBadge from "@/components/TailwindComponents/ApproveBadge";
import DenyBadge from "@/components/TailwindComponents/DenyBadge";
import ReviewBadge from "@/components/TailwindComponents/ReviewBadge";
import WrappedValue from "@/components/TailwindComponents/WrappedValue";
import StatusIndicator from "@/components/TailwindComponents/StatusIndicator";

import { AgentRun } from "@/types";
import { Link } from "react-router-dom";

const AgentRunItem: React.FC<{
  agentRun: AgentRun;
  isSelected: boolean;
  id: string;
  setSelectedAgentJobId: React.Dispatch<React.SetStateAction<string | null>>;
}> = ({ agentRun, id, isSelected }: { agentRun: AgentRun; id: string; isSelected: boolean }) => {
  if (!agentRun) return null;

  const getJobName = () => {
    if (!agentRun?.input_payload?.self_attested_data) return "";

    const files = [
      ...(agentRun.input_payload?.self_attested_data?.business_ownership_documents ?? []),
      ...(agentRun.input_payload?.self_attested_data?.cannabis_license_documents ?? []),
      ...(agentRun.input_payload?.self_attested_data?.incorporation_documents ?? []),
      ...(agentRun.input_payload?.self_attested_data?.business_registration_documents ?? []),
    ];

    const fileNames = files.map((f) => f.file_name);

    return (
      agentRun.input_payload?.self_attested_data?.name ||
      agentRun.input_payload?.self_attested_data?.business_name ||
      agentRun.input_payload?.self_attested_data?.registered_business_name ||
      agentRun.input_payload?.self_attested_data?.website?.replace(/https?:\/\/(www\.)?|www\./gi, "") ||
      (agentRun.input_payload?.self_attested_data?.first_name &&
        agentRun.input_payload?.self_attested_data?.last_name &&
        `${agentRun.input_payload?.self_attested_data?.first_name}${
          agentRun.input_payload?.self_attested_data?.middle_name
            ? ` ${agentRun.input_payload?.self_attested_data?.middle_name} `
            : " "
        }${agentRun.input_payload?.self_attested_data?.last_name}`) ||
      fileNames.join(", ")
    );
  };

  const renderRecommendationBadge = () => {
    if (!agentRun || !agentRun.recommendation) return null;

    const isFlutterwaveMCC = agentRun.agent_id === "kyb-mcc-flutterwave";

    if (isFlutterwaveMCC) {
      const isHighRisk = agentRun.isHighRisk;
      const inputMccCode = agentRun.input_payload?.self_attested_data.mcc_code;
      const recommendationMccCode = agentRun.recommendation;

      if (isHighRisk) {
        return (
          <DenyBadge>
            <>
              <WarningIcon sx={{ fontSize: "1rem" }} className="mr-1" />
              <span className="truncate">High Risk</span>
            </>
          </DenyBadge>
        );
      } else if (recommendationMccCode === "unknown") {
        return (
          <DenyBadge>
            <>Unknown</>
          </DenyBadge>
        );
      } else if (inputMccCode && recommendationMccCode && inputMccCode === recommendationMccCode) {
        return (
          <ApproveBadge>
            <>
              <CheckIcon sx={{ fontSize: "1rem" }} className="mr-1" />
              <span className="truncate">{agentRun.recommendation}</span>
            </>
          </ApproveBadge>
        );
      } else if (
        (inputMccCode && recommendationMccCode && inputMccCode !== recommendationMccCode) ||
        recommendationMccCode === "Review"
      ) {
        return (
          <ReviewBadge>
            <>
              <ErrorIcon sx={{ fontSize: "1rem" }} className="mr-1" />
              <span className="truncate">{agentRun.recommendation}</span>
            </>
          </ReviewBadge>
        );
      }
      return <WrappedValue text={agentRun.recommendation} />;
    }

    return (
      {
        Review: <ReviewBadge />,
        Approve: <ApproveBadge />,
        Passed: <ApproveBadge />,
        Failed: <DenyBadge />,
        Deny: <DenyBadge />,
        unknown: <WrappedValue text={agentRun.recommendation} />,
      }[agentRun.recommendation] || <WrappedValue text={agentRun.recommendation} />
    );
  };

  return (
    <Link
      to={`/jobs/${agentRun.agent_id}/${agentRun.id?.replaceAll("-", "")}`}
      className={clsx("flex flex-col p-2.5 md:p-5 border-b border-b-slate-200 gap-3", {
        "bg-slate-100": isSelected,
      })}
      id={id}
    >
      <div className="flex justify-between gap-x-2">
        <div className="line-clamp-2 md:line-clamp-4 font-inter font-semibold text-sm md:text-md leading-normal">
          {getJobName()}
        </div>
        {renderRecommendationBadge()}
      </div>
      <div className="flex flex-wrap items-center gap-1 md:gap-2 text-xs md:text-sm">
        <div>
          <StatusIndicator status={agentRun.status} />
        </div>
        <div className="capitalize">{agentRun.status}</div>
        <div className="hidden md:block">•</div>
        <div className="hidden md:block">
          {formatDistanceToNowStrict(new Date(`${agentRun.created_at}Z`), { addSuffix: true })}
        </div>
      </div>
    </Link>
  );
};

export default AgentRunItem;
