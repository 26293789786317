import { useEffect, useState, FC, ReactNode } from "react";
import { Paper, Typography, Grid, List, ListItem } from "@mui/material";
import { JobMetadata, ParchaDocument } from "@parcha-src/types";
import RecommendationBox from "../RecommendationBox";
import DocumentWithUrl from "../DocumentWithUrl";
import { format, formatDistanceStrict } from "date-fns";
import StatusBox from "../StatusBox";

interface JobInfoCardProps {
  jobMetadata: JobMetadata; // Replace 'any' with the actual type of 'data'
}

interface JobInfoItemProps {
  label: string;
  value: ReactNode;
}

const JobInfoItem: FC<JobInfoItemProps> = ({ label, value }) => {
  const labelWidth = 4;
  const valueWidth = 8;

  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={labelWidth} container alignItems={label === "Documents:" ? "flex-start" : "center"}>
          <Typography variant="body1">
            <strong>{label}</strong>
          </Typography>
        </Grid>
        <Grid item xs={valueWidth}>
          <Typography variant="body1">{value}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

function formatDuration(started_at: string, completed_at: string): string {
  return formatDistanceStrict(
    new Date(started_at), // Convert to milliseconds
    new Date(completed_at),
  );
}

import { intervalToDuration } from "date-fns";

function formatDurationToMinutesAndSeconds(startTimestamp: number, endTimestamp: number): string {
  const duration = intervalToDuration({ start: new Date(startTimestamp * 1000), end: new Date(endTimestamp * 1000) });
  const minutes = duration.minutes?.toString();
  const seconds = duration.seconds?.toString().padStart(2, "0");
  return `${minutes} minutes and ${seconds} seconds`;
}
function formatDateTime(date_str: string): string {
  return format(
    new Date(date_str), // Convert to milliseconds
    "PPpp", // 'PPpp' is a format string that represents date and time in a nice format
  );
}

interface JobInfo {
  label: string;
  value: ReactNode;
}

const JobInfoCard: FC<JobInfoCardProps> = ({ jobMetadata }: JobInfoCardProps) => {
  const [jobInfoList, setJobInfoList] = useState<JobInfo[]>([]);
  useEffect(() => {
    const info = [
      { label: "Case ID:", value: jobMetadata.agentRun.case_id || jobMetadata.agentRun.input_payload?.id },
      { label: "Job ID:", value: jobMetadata.agentRun.id },
      { label: "Job Created by:", value: jobMetadata.agentRun.owner_id },
      { label: "Job Source:", value: "API" },
      {
        label: "Job Started At:",
        value: jobMetadata.agentRun.started_at ? formatDateTime(jobMetadata.agentRun.started_at) : "",
      },
      {
        label: "Job Finished:",
        value: jobMetadata.agentRun.completed_at ? formatDateTime(jobMetadata.agentRun.completed_at) : "",
      },
      {
        label: "Job Duration:",
        value:
          jobMetadata.agentRun.started_at && jobMetadata.agentRun.completed_at
            ? formatDuration(jobMetadata.agentRun.started_at, jobMetadata.agentRun.completed_at)
            : "",
      },
      {
        label: "Job Status:",
        value: <StatusBox status={jobMetadata.agentRun.status} />,
      },
      {
        label: "Recommendation:",
        value: jobMetadata.recommendation && <RecommendationBox recommendation={jobMetadata.recommendation} />,
      },
      {
        label: "Documents:",
        value: (
          <List sx={{ padding: 0 }}>
            {jobMetadata.documents.map((document: ParchaDocument, index: number) => (
              <>
                <ListItem key={index} sx={{ padding: "2px 0" }}>
                  <DocumentWithUrl {...document} />
                </ListItem>
              </>
            ))}
          </List>
        ),
      },
    ];
    setJobInfoList(info);
  }, [jobMetadata]);

  return (
    <Paper elevation={0} sx={{ padding: 2 }}>
      <Grid container spacing={2}>
        {jobInfoList.map((item, index) => (
          <JobInfoItem key={index} label={item.label} value={item.value} />
        ))}
      </Grid>
    </Paper>
  );
};

export default JobInfoCard;
