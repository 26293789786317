import { useContext, useEffect } from "react";
import useAuth from "@parcha-src/hooks/useAuth";
import { usePostHog } from "posthog-js/react";
import { Navigate, useLocation } from "react-router-dom";
import { useKoala } from "@getkoala/react";
import UserContext from "@/contexts/UserContext";

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredRole?: string;
  agentKey?: string;
}

export default function ProtectedRoute({ children, requiredRole, agentKey }: ProtectedRouteProps) {
  const posthog = usePostHog();
  const { isLoading, isAuthenticated, user, endpoints } = useAuth();
  const { koala, ready } = useKoala();
  const userContext = useContext(UserContext);
  const { login, hasRole, rbacService, selectedEndpoint, setSelectedEndpoint } = userContext;
  const location = useLocation();

  useEffect(() => {
    if (user && isAuthenticated && ready) {
      const tenant = user.userTenants && user.userTenants.length > 0 ? user.userTenants[0] : null;
      koala.identify({
        email: user.email,
        name: user.name,
        $account: { name: tenant?.tenantName ?? undefined },
      });
    }
  }, [isAuthenticated, ready, user]);

  useEffect(() => {
    if (user && isAuthenticated) {
      posthog?.identify(user.userId, {
        email: user.email,
      });
    }
  }, [user, isAuthenticated, posthog]);

  useEffect(() => {
    if (isAuthenticated && user) {
      login?.(user);
    }
  }, [login, isAuthenticated, user]);

  useEffect(() => {
    if (requiredRole && endpoints && endpoints.length > 0 && !selectedEndpoint) {
      setSelectedEndpoint(endpoints[0]);
    }
  }, [requiredRole, endpoints, selectedEndpoint, setSelectedEndpoint]);

  if (isLoading) return null;

  if (!isAuthenticated || !user) {
    if (
      (location.pathname === "/" || location.pathname.includes("/reports")) &&
      import.meta.env.VITE_PREVENT_CREATE_REPORT !== "true"
    ) {
      return <Navigate to="/create-report" />;
    }

    return <Navigate to={`/signin?redirectTo=${encodeURIComponent(location.pathname)}`} />;
  }

  if (requiredRole && (!selectedEndpoint || !userContext.isRolesReady)) return null;

  if (requiredRole && selectedEndpoint) {
    if (agentKey && rbacService) {
      // Check dynamic role for specific agent
      if (!rbacService.checkDynamicRole(agentKey, requiredRole as any)) {
        return <Navigate to="/" />;
      }
    } else {
      if (requiredRole === "ParchaAdmin") {
        // Special case for ParchaAdmin as a composite role
        if (!userContext.roles?.parchaAdmin) {
          return <Navigate to="/" />;
        }
      } else if (!hasRole(requiredRole)) {
        return <Navigate to="/" />;
      }
      return <>{children}</>;
    }

    if (requiredRole === "ParchaAdmin" && !userContext.roles?.parchaAdmin) {
      return <Navigate to="/" />;
    }

    if (requiredRole !== "ParchaAdmin" && !hasRole(requiredRole)) {
      return <Navigate to="/" />;
    }
  }

  return <>{children}</>;
}
