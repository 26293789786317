import { useParchaApi } from "@/hooks/useParchaApi";
import { ChangeEvent, Fragment, useEffect, useRef, useState } from "react";
import { twJoin } from "tailwind-merge";
import {
  Close,
  UploadFileRounded,
  PlagiarismRounded,
  AutoAwesomeRounded,
  DescriptionRounded,
  DeleteForeverRounded,
  ExpandMoreRounded,
  ErrorRounded,
  EditRounded,
  ExpandLessRounded,
} from "@mui/icons-material";
import { Agent, Endpoint, NewJobMetadata } from "@/types";
import { Dialog, Menu, Transition } from "@headlessui/react";
import BusinessRegistrationForm from "./DocumentForms/BusinessRegistrationForm";
import ProofOfAddressForm from "./DocumentForms/ProofOfAddressForm";
import EINDocumentForm from "./DocumentForms/EINDocumentForm";
import CannabisLicenseForm from "./DocumentForms/CannabisLicenseForm";
import BusinessOwnershipForm from "./DocumentForms/BusinessOwnershipForm";
import DocumentValidation from "./TailwindComponents/DocumentValidation";
import {
  INCORPORATION_DOCUMENT_CHECK_ID,
  PROOF_OF_ADDRESS_CHECK_ID,
  EIN_DOCUMENT_CHECK_ID,
  CHECK_ID_TO_TITLE,
  CANNABIS_LICENSE_CHECK_ID,
  BUSINESS_OWNERSHIP_CHECK_ID,
} from "@/constants/vars";
import Skeleton from "./TailwindComponents/Skeleton";
import { useNavigate } from "react-router-dom";
import { isAddressProvided } from "@/utils";

type DocumentValidationModalProps = {
  agent: Agent | null;
  endpoint: Endpoint | null;
  show: boolean;
  setShow: (show: boolean) => void;
  validationCheckIds: (keyof typeof DOCUMENT_TYPES)[];
};

const DOCUMENT_TYPES = {
  [INCORPORATION_DOCUMENT_CHECK_ID]: "Incorporation",
  [PROOF_OF_ADDRESS_CHECK_ID]: "Proof of Address",
  [EIN_DOCUMENT_CHECK_ID]: "EIN Document",
  [CANNABIS_LICENSE_CHECK_ID]: "Cannabis License",
  [BUSINESS_OWNERSHIP_CHECK_ID]: "Ownership Document",
};

const DocumentValidationModal = ({
  agent,
  endpoint,
  show,
  setShow,
  validationCheckIds,
}: DocumentValidationModalProps) => {
  const [enableExtractionOnly, setEnableExtractionOnly] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(true);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState<string>(
    validationCheckIds.length ? validationCheckIds[0] : "",
  );
  const [view, setView] = useState<"upload" | "starting_job" | "results">("upload");
  const [checkRunResponse, setCheckRunResponse] = useState<{ id: string } | null>(null);
  const [jobMetadata, setJobMetadata] = useState<NewJobMetadata | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [isVisualVerificationVisible, setIsVisualVerificationVisible] = useState<boolean>(false);
  const [isFraudCheckVisible, setIsFraudCheckVisible] = useState<boolean>(false);

  const [enableVisualVerification, setEnableVisualVerification] = useState<boolean>(false);
  const [enableFraudCheck, setEnableFraudCheck] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const parchaApi = useParchaApi();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    business_name: "",
    cannabis_license_number: "",
    description: "",
    registered_business_name: "",
    business_registration_number: "",
    tin_number: "",
    incorporation_date: "",
    address_of_incorporation: {
      street_1: "",
      street_2: "",
      city: "",
      state: "",
      country_code: "",
      postal_code: "",
    },
    address_of_operation: {
      street_1: "",
      street_2: "",
      city: "",
      state: "",
      country_code: "",
      postal_code: "",
    },
    validity_period: 90,
    associated_individuals: [
      {
        first_name: "",
        middle_name: "",
        last_name: "",
        business_ownership_percentage: "",
      },
    ],
  });

  useEffect(() => {
    if (selectedDocumentType === "" && validationCheckIds.length > 0) {
      setSelectedDocumentType(validationCheckIds[0]);
    }
  }, [validationCheckIds]);

  useEffect(() => {
    const selectedAgentStep = agent?.steps?.find((step) => step.command_id === selectedDocumentType);

    const dataLoaderArgs = selectedAgentStep?.data_loader_args;
    const toolArgs = selectedAgentStep?.tool_args;

    if (dataLoaderArgs) {
      setEnableVisualVerification(Boolean(dataLoaderArgs.enable_visual_verification));
      setEnableFraudCheck(Boolean(dataLoaderArgs.enable_fraud_check));
      setIsVisualVerificationVisible(Boolean(dataLoaderArgs.enable_visual_verification));
      setIsFraudCheckVisible(Boolean(dataLoaderArgs.enable_fraud_check));
    }

    if (toolArgs) {
      setEnableExtractionOnly(Boolean(toolArgs.check_self_attested_ownership));
      setShowForm(!Boolean(toolArgs.check_self_attested_ownership));
    } else {
      setEnableExtractionOnly(false);
      setShowForm(true);
    }
  }, [selectedDocumentType]);

  useEffect(() => {
    if (!show) {
      // Reset form data when modal is closed
      setFormData({
        business_name: "",
        registered_business_name: "",
        business_registration_number: "",
        tin_number: "",
        incorporation_date: "",
        cannabis_license_number: "",
        description: "",
        address_of_incorporation: {
          street_1: "",
          street_2: "",
          city: "",
          state: "",
          country_code: "",
          postal_code: "",
        },
        address_of_operation: {
          street_1: "",
          street_2: "",
          city: "",
          state: "",
          country_code: "",
          postal_code: "",
        },
        validity_period: 90,
        associated_individuals: [
          {
            first_name: "",
            middle_name: "",
            last_name: "",
            business_ownership_percentage: "",
          },
        ],
      });
      setFormErrors([]);
      setError(null);
      setView("upload");
      setCheckRunResponse(null);
      setJobMetadata(null);
      setSelectedFile(null);
      setSelectedDocumentType("");
      setEnableVisualVerification(false);
      setEnableFraudCheck(false);
    }
  }, [show]);

  const processFile = async (file: File) => {
    setSelectedFile(file);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const files = e.dataTransfer.files;

    if (!files || !files.length) return;
    processFile(files[0]);
  };

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || !files.length) return;
    processFile(files[0]);
  };

  const handleDocumentInputClick = () => {
    inputRef.current?.click();
  };

  // Helper function to convert File to base64
  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result?.toString().split(",")[1];
        if (base64String) {
          resolve(base64String);
        } else {
          reject(new Error("Failed to convert file to base64"));
        }
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const hasRequiredOwnershipInfo = (formData: any) => {
    return (
      formData.registered_business_name?.length > 0 &&
      formData.associated_individuals.some(
        (individual: { first_name: string; last_name: string; business_ownership_percentage: string }) =>
          individual.first_name && individual.last_name && individual.business_ownership_percentage,
      )
    );
  };

  const runFileCheck = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!selectedFile || !endpoint) return;

    // Check for required fields
    if (
      [INCORPORATION_DOCUMENT_CHECK_ID, EIN_DOCUMENT_CHECK_ID].includes(selectedDocumentType) &&
      !formData.registered_business_name
    ) {
      setFormErrors(["Registered Business Name is required"]);
      return;
    } else if (selectedDocumentType === PROOF_OF_ADDRESS_CHECK_ID && !formData.business_name) {
      setFormErrors(["Business Name is required"]);
      return;
    } else if (
      selectedDocumentType === CANNABIS_LICENSE_CHECK_ID &&
      !formData.business_name &&
      !formData.cannabis_license_number
    ) {
      setFormErrors(["Business Name and License Number are required"]);
      return;
    }

    setFormErrors([]);
    setError(null);
    setView("starting_job");
    // Convert file to base64
    const base64String = await fileToBase64(selectedFile);
    try {
      const checkRunResponse = await parchaApi.runCheck(endpoint.endpointUrl, selectedDocumentType, {
        agent_key: endpoint.agentKey,
        kyb_schema: {
          id: crypto.randomUUID(),
          self_attested_data: {
            cannabis_license_number: formData.cannabis_license_number?.length
              ? formData.cannabis_license_number
              : undefined,
            description: formData.description?.length ? formData.description : undefined,
            business_name: formData.business_name?.length ? formData.business_name : undefined,
            registered_business_name: formData.registered_business_name?.length
              ? formData.registered_business_name
              : undefined,
            business_registration_number: formData.business_registration_number?.length
              ? formData.business_registration_number
              : undefined,
            business_ownership_documents:
              selectedDocumentType === BUSINESS_OWNERSHIP_CHECK_ID
                ? [{ file_name: selectedFile.name, b64_document: base64String }]
                : undefined,
            cannabis_license_documents:
              selectedDocumentType === CANNABIS_LICENSE_CHECK_ID
                ? [{ file_name: selectedFile.name, b64_document: base64String }]
                : undefined,
            proof_of_address_documents:
              selectedDocumentType === PROOF_OF_ADDRESS_CHECK_ID
                ? [{ file_name: selectedFile.name, b64_document: base64String }]
                : undefined,
            incorporation_documents:
              selectedDocumentType === INCORPORATION_DOCUMENT_CHECK_ID
                ? [{ file_name: selectedFile.name, b64_document: base64String }]
                : undefined,
            ein_documents:
              selectedDocumentType === EIN_DOCUMENT_CHECK_ID
                ? [{ file_name: selectedFile.name, b64_document: base64String }]
                : undefined,
            address_of_incorporation: isAddressProvided({
              street1: formData.address_of_incorporation.street_1,
              street2: formData.address_of_incorporation.street_2,
              city: formData.address_of_incorporation.city,
              state: formData.address_of_incorporation.state,
              countryCode: formData.address_of_incorporation.country_code,
              postalCode: formData.address_of_incorporation.postal_code,
            })
              ? formData.address_of_incorporation
              : undefined,
            address_of_operation: isAddressProvided({
              street1: formData.address_of_operation.street_1,
              street2: formData.address_of_operation.street_2,
              city: formData.address_of_operation.city,
              state: formData.address_of_operation.state,
              countryCode: formData.address_of_operation.country_code,
              postalCode: formData.address_of_operation.postal_code,
            })
              ? formData.address_of_operation
              : undefined,

            incorporation_date: formData.incorporation_date?.length ? formData.incorporation_date : undefined,
            tin_number: formData.tin_number?.length ? formData.tin_number : undefined,
          },
          associated_individuals: formData.associated_individuals.some(
            (individual) => individual.first_name && individual.last_name && individual.business_ownership_percentage,
          )
            ? formData.associated_individuals.map((individual) => ({
                id: `individual-${individual.first_name}-${individual.last_name}`,
                self_attested_data: {
                  first_name: individual.first_name,
                  middle_name: individual.middle_name,
                  last_name: individual.last_name,
                  business_ownership_percentage: individual.business_ownership_percentage,
                },
              }))
            : undefined,
        },
        check_args: {
          enable_visual_verification:
            selectedDocumentType === INCORPORATION_DOCUMENT_CHECK_ID ? enableVisualVerification : undefined,
          enable_fraud_check: enableFraudCheck,
          validity_period: selectedDocumentType === PROOF_OF_ADDRESS_CHECK_ID ? formData.validity_period : undefined,
        },
        tool_args: {
          check_self_attested_ownership:
            selectedDocumentType === BUSINESS_OWNERSHIP_CHECK_ID
              ? Boolean(formData.registered_business_name) && hasRequiredOwnershipInfo(formData)
              : true,
        },
      });

      resetFormData();
      setCheckRunResponse(checkRunResponse);
      parchaApi
        .getJobMetadata(endpoint.endpointUrl, checkRunResponse.id)
        .then((jobMetadata) => {
          setJobMetadata(jobMetadata);
          setView("results");
          navigate(`/jobs/${endpoint.agentKey}/${checkRunResponse.id}`);
          localStorage.setItem("should_go_to_documents", "true");
        })
        .catch((error) => {
          console.error("Error getting job metadata:", error);
        });
    } catch (error) {
      setView("upload");
      setError("An error has occured while running this check. Please try again.");
    }
  };

  const resetFormData = () => {
    setFormData({
      business_name: "",
      cannabis_license_number: "",
      description: "",
      registered_business_name: "",
      business_registration_number: "",
      tin_number: "",
      incorporation_date: "",
      address_of_incorporation: {
        street_1: "",
        street_2: "",
        city: "",
        state: "",
        country_code: "",
        postal_code: "",
      },
      address_of_operation: {
        street_1: "",
        street_2: "",
        city: "",
        state: "",
        country_code: "",
        postal_code: "",
      },
      validity_period: 90,
      associated_individuals: [
        {
          first_name: "",
          middle_name: "",
          last_name: "",
          business_ownership_percentage: "",
        },
      ],
    });
    setFormErrors([]);
    setError(null);
  };

  const renderInfoForm = () => {
    switch (selectedDocumentType) {
      case INCORPORATION_DOCUMENT_CHECK_ID:
        return <BusinessRegistrationForm formData={formData} setFormData={setFormData} formErrors={formErrors} />;
      case PROOF_OF_ADDRESS_CHECK_ID:
        return <ProofOfAddressForm formData={formData} setFormData={setFormData} formErrors={formErrors} />;
      case EIN_DOCUMENT_CHECK_ID:
        return <EINDocumentForm formData={formData} setFormData={setFormData} formErrors={formErrors} />;
      case CANNABIS_LICENSE_CHECK_ID:
        return <CannabisLicenseForm formData={formData} setFormData={setFormData} formErrors={formErrors} />;
      case BUSINESS_OWNERSHIP_CHECK_ID:
        return <BusinessOwnershipForm formData={formData} setFormData={setFormData} formErrors={formErrors} />;
      default:
        return null;
    }
  };

  const handleOnClose = () => {
    setShow(false);
  };

  const getDocumentUrl = () => {
    if (!jobMetadata) return null;

    const selfAttestedData = jobMetadata.job.input_payload.self_attested_data;

    switch (selectedDocumentType) {
      case INCORPORATION_DOCUMENT_CHECK_ID:
        return selfAttestedData.incorporation_documents[0].url;
      case PROOF_OF_ADDRESS_CHECK_ID:
        return selfAttestedData.proof_of_address_documents[0].url;
      case EIN_DOCUMENT_CHECK_ID:
        return selfAttestedData.ein_documents[0].url;
      case BUSINESS_OWNERSHIP_CHECK_ID:
        return selfAttestedData.business_ownership_documents[0].url;
      default:
        return null;
    }
  };

  const getDocumentName = () => {
    if (!jobMetadata) return null;

    const selfAttestedData = jobMetadata.job.input_payload.self_attested_data;

    switch (selectedDocumentType) {
      case INCORPORATION_DOCUMENT_CHECK_ID:
        return selfAttestedData.incorporation_documents[0].file_name;
      case PROOF_OF_ADDRESS_CHECK_ID:
        return selfAttestedData.proof_of_address_documents[0].file_name;
      case EIN_DOCUMENT_CHECK_ID:
        return selfAttestedData.ein_documents[0].file_name;
      case BUSINESS_OWNERSHIP_CHECK_ID:
        return selfAttestedData.business_ownership_documents[0].file_name;
      default:
        return null;
    }
  };

  const getJobName = () => {
    if (!jobMetadata) return "";

    return jobMetadata.job.input_payload.self_attested_data?.registered_business_name ?? "";
  };

  const renderStartingJob = () => {
    return (
      <div className="flex h-full text-xs">
        <div className="w-1/4 h-full  border-r bg-white overflow-y-auto">
          <div className="p-4">
            <div className="mb-4 flex flex-wrap items-center justify-between gap-x-4">
              <Skeleton width="w-3/4" height="h-4" shape="rounded" />
            </div>
            <div className="space-y-4 pb-12">
              <Skeleton width="w-full" height="h-[10rem]" shape="rounded" />
              <Skeleton width="w-full" height="h-[10rem]" shape="rounded" />
              <Skeleton width="w-full" height="h-[10rem]" shape="rounded" />
              <Skeleton width="w-full" height="h-[10rem]" shape="rounded" />
            </div>
          </div>
        </div>
        <div className="h-full flex flex-1 mb-4">
          <div className="flex flex-col h-[calc(100%-3.5rem)] w-full gap-y-4 w-3/4 rounded-md">
            <div className="h-full pt-10 relative">
              <Skeleton width="w-3/4" height="h-full" shape="rounded" additionalClasses="mx-auto"></Skeleton>
            </div>
          </div>

          <div className="h-full flex flex-col gap-y-4 w-1/4 bg-slate-100 border-l border-solid border-slate-200 pt-10">
            <Skeleton width="w-1/2" height="h-[10rem]" shape="rounded" additionalClasses="mx-auto" />
            <Skeleton width="w-1/2" height="h-[10rem]" shape="rounded" additionalClasses="mx-auto" />
            <Skeleton width="w-1/2" height="h-[10rem]" shape="rounded" additionalClasses="mx-auto" />
            <Skeleton width="w-1/2" height="h-[10rem]" shape="rounded" additionalClasses="mx-auto" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={handleOnClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-20 w-screen overflow-y-auto h-screen">
          <div className="flex w-full h-full min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="flex flex-col relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all h-full w-full">
                <div className="sm:flex sm:items-center sm:justify-between border-b border-slate-300 p-4">
                  <Dialog.Title as="h3" className="flex items-center gap-x-2 text-base font-semibold text-slate-900">
                    <div className="rounded-full bg-indigo-100 flex items-center justify-center p-2">
                      <PlagiarismRounded sx={{ fontSize: "1rem" }} className="text-brand-purple" />
                    </div>
                    Document Verification
                  </Dialog.Title>
                  <div>
                    <button
                      className="ml-auto inline-flex items-center border border-solid border-slate-200 px-2 py-1 rounded-md text-sm"
                      onClick={handleOnClose}
                    >
                      <Close sx={{ fontSize: "1rem" }} className="mr-1" />
                      <span>Close</span>
                    </button>
                  </div>
                </div>
                {view === "upload" ? (
                  <div className="mx-auto max-w-4xl w-full py-10 px-20 overflow-y-auto">
                    {error && (
                      <div className="flex items-center gap-2 bg-red-50 text-sm text-red-700 p-4 rounded-md">
                        <ErrorRounded className="text-red-600" sx={{ fontSize: "1rem" }} />
                        {error}
                      </div>
                    )}
                    <div className="px-4 flex-1 flex flex-col gap-y-10 mt-10">
                      <div>
                        <label htmlFor="document-type" className="block text-sm font-semibold text-slate-700">
                          Select document type to verify
                        </label>
                        <Menu as="div" className="relative inline-block text-left">
                          <div>
                            <Menu.Button className="flex w-56 gap-x-1.5 rounded-md bg-white p-2 text-xs md:text-md text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50">
                              <span className="flex-1 line-clamp-1 text-left">
                                {DOCUMENT_TYPES[selectedDocumentType as keyof typeof DOCUMENT_TYPES]}
                              </span>
                              <ExpandMoreRounded
                                sx={{ fontSize: "1rem" }}
                                className="-mr-1 h-4 w-4 text-slate-400"
                                aria-hidden="true"
                              />
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="h-fit w-full max-h-[70vh] overflow-y-auto absolute left-0 z-50 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                {validationCheckIds.map((checkId) => {
                                  return (
                                    <Menu.Item key={DOCUMENT_TYPES[checkId as keyof typeof DOCUMENT_TYPES]}>
                                      <button
                                        onClick={() => setSelectedDocumentType(checkId)}
                                        className={twJoin(
                                          selectedDocumentType === checkId
                                            ? "bg-slate-100 text-slate-900"
                                            : "text-slate-700",
                                          "block px-4 py-2 text-xs w-full text-left hover:bg-slate-100 hover:text-slate-900 disabled:cursor-not-allowed disabled:bg-opacity-50 disabled:text-slate-500",
                                        )}
                                      >
                                        {DOCUMENT_TYPES[checkId as keyof typeof DOCUMENT_TYPES]}
                                      </button>
                                    </Menu.Item>
                                  );
                                })}
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                      <div className="flex flex-col gap-y-3">
                        <div className="flex items-center gap-x-2">
                          {enableExtractionOnly && (
                            <button onClick={() => setShowForm(!showForm)} className="text-slate-500 text-sm">
                              {showForm ? (
                                <ExpandLessRounded sx={{ fontSize: "1.25rem" }} className="w-5 h-5" />
                              ) : (
                                <ExpandMoreRounded sx={{ fontSize: "1.25rem" }} className="w-5 h-5" />
                              )}
                            </button>
                          )}
                          <label htmlFor="provide-business-info" className="block text-sm font-semibold text-slate-900">
                            Provide business information to validate in the document(s)
                          </label>
                        </div>
                        {showForm ? renderInfoForm() : null}
                      </div>
                      <div className="flex flex-col gap-y-2">
                        <label className="block text-sm font-semibold text-slate-900">
                          Upload the document(s) you want to verify
                        </label>
                        {selectedFile ? (
                          <div className="flex items-center gap-2 h-full text-sm border py-6 px-3 rounded-xl border-slate-300 text-brand-purple overflow-hidden">
                            <DescriptionRounded sx={{ fontSize: "1.25rem" }} />
                            <span className="line-clamp-1 w-fit">{selectedFile.name}</span>
                            <button onClick={() => setSelectedFile(null)} aria-label="Remove file">
                              <DeleteForeverRounded sx={{ fontSize: "1.25rem" }} className="text-red-600" />
                            </button>
                          </div>
                        ) : (
                          <div
                            className={twJoin(
                              "flex items-center gap-2 justify-center h-full text-sm border-2 border-dashed py-6 px-3 rounded-xl",
                              isDraggingOver ? "border-blue-500 bg-blue-50" : "border-slate-300",
                            )}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            onDragExit={() => setIsDraggingOver(false)}
                            onDragLeave={() => setIsDraggingOver(false)}
                          >
                            <UploadFileRounded sx={{ fontSize: "1.25rem" }} className="text-slate-400" />
                            <p className="text-slate-700">
                              Drag & Drop or{" "}
                              <button className="underline text-brand-purple" onClick={handleDocumentInputClick}>
                                Choose a document
                              </button>{" "}
                              to upload
                            </p>
                            <input
                              type="file"
                              name={selectedDocumentType}
                              accept="image/jpg,image/jpeg,image/png,application/pdf"
                              onChange={handleFileSelect}
                              onBlur={(e) => e.preventDefault()}
                              ref={inputRef}
                              className="hidden"
                            />
                          </div>
                        )}
                      </div>
                      {(isVisualVerificationVisible || isFraudCheckVisible) && (
                        <div>
                          <p className="block text-sm font-semibold text-slate-900">
                            Enhance your verification by selecting below options
                          </p>
                          <div className="mt-2 space-y-2">
                            {isVisualVerificationVisible && (
                              <div className="flex items-center">
                                <input
                                  id="vision-model"
                                  name="vision-model"
                                  type="checkbox"
                                  checked={enableVisualVerification}
                                  onChange={() => setEnableVisualVerification(!enableVisualVerification)}
                                  className="h-4 w-4 rounded border-2 border-slate-400 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label htmlFor="vision-model" className="ml-2 block text-sm text-slate-900">
                                  Visual verification
                                </label>
                              </div>
                            )}
                            {isFraudCheckVisible && (
                              <div className="flex items-center">
                                <input
                                  id="anti-tampering"
                                  name="anti-tampering"
                                  type="checkbox"
                                  checked={enableFraudCheck}
                                  onChange={() => setEnableFraudCheck(!enableFraudCheck)}
                                  className="h-4 w-4 rounded border-2 border-slate-400 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label htmlFor="anti-tampering" className="ml-2 block text-sm text-slate-900">
                                  Anti-tampering and fraud detection
                                </label>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="justify-self-end sm:mt-10 sm:flex sm:flex-row-reverse px-4">
                      <button
                        type="button"
                        className="inline-flex gap-x-1 items-center w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto disabled:cursor-not-allowed disabled:bg-opacity-50"
                        onClick={runFileCheck}
                        disabled={
                          !selectedFile ||
                          formErrors.length > 0 ||
                          ([INCORPORATION_DOCUMENT_CHECK_ID, EIN_DOCUMENT_CHECK_ID].includes(selectedDocumentType) &&
                            !formData.registered_business_name) ||
                          (selectedDocumentType === PROOF_OF_ADDRESS_CHECK_ID && !formData.business_name) ||
                          (selectedDocumentType === CANNABIS_LICENSE_CHECK_ID &&
                            !formData.business_name &&
                            !formData.cannabis_license_number)
                        }
                      >
                        <AutoAwesomeRounded sx={{ fontSize: "1.25rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                        Verify Document
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-medium text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 hover:bg-slate-50 sm:mt-0 sm:w-auto"
                        onClick={handleOnClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : view === "starting_job" ? (
                  renderStartingJob()
                ) : view === "results" && agent && endpoint && checkRunResponse && jobMetadata ? (
                  <DocumentValidation
                    agentInstanceId={Object.keys(jobMetadata.kyb)[0]}
                    jobId={checkRunResponse.id}
                    endpointUrl={endpoint.endpointUrl}
                    documentUrl={getDocumentUrl()}
                    documentName={getDocumentName()}
                    title={getJobName()}
                    agentType={agent.agent_type}
                    checkId={selectedDocumentType}
                    checkName={`${
                      CHECK_ID_TO_TITLE[selectedDocumentType as keyof typeof CHECK_ID_TO_TITLE]
                    } Document Verification`}
                    onCloseHandler={handleOnClose}
                  />
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DocumentValidationModal;
