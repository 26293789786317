import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
  useRouteError,
} from "react-router-dom";
import "./App.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { AuthProvider } from "@descope/react-sdk";
import { PostHogConfig } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import SignIn from "./components/Signin";
import { DESCOPE_PROJECT_ID } from "./constants/vars";
import SignOut from "./components/SignOut";
import ProtectedRoute from "./components/ProtectedRoute";
import ErrorBoundary from "./components/ErrorBoundary";
import { ErrorProvider } from "./contexts/ErrorProvider";
import ChecksOverview from "./components/ChecksOverview";
import Remediation from "./components/RemediationPage";
import Layout from "./components/TailwindComponents/Layout";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SOFKYBRemediation from "./components/SOFKYBRemediation";
import SOFKYCRemediation from "./components/SOFKYCRemediation";
import CaseRedirect, { loader as caseRedirectLoader } from "./components/TailwindComponents/CaseRedirect";
import PersonaLoader, { loader as personaLoader } from "./components/PersonaLoader";
import IncorporationDocRemediation from "./components/IncorporationDocRemediation";
import PublicReportForm from "./components/V2_Home_Page/PublicReportForm";
import ReportsList from "./components/V2_Home_Page/PublicBDDComponents/ReportsList";
import ReportView from "./components/V2_Home_Page/PublicBDDComponents/Report";
import { KoalaProvider } from "@getkoala/react";
import { RefreshRounded, WarningRounded } from "@mui/icons-material";
import { UserProvider } from "./contexts/UserContext";
import NewAgentsIndex from "./components/NewAgentsIndex";
import NewJobsList from "./components/NewJobsList";
import NewJobView from "./components/NewJobView";
import AdminView from "./components/Admin";
import UsersTable from "./components/Admin/UsersList";
import ApiKeysManager from "./components/ApiKeysManager";
import TenantsList from "./components/Admin/TenantsList";
import Integrations from "./components/Settings/Integrations";

import DocumentRequestSubmission from "./components/TailwindComponents/DocumentRequestSubmission";
import DocumentRequestsListView from "./components/DocumentRequestsListView";
import DocumentRequestsMainView from "./components/DocumentRequestsMainView";
import EmbedChecksViewByJobId from "./components/EmbedChecksViewByJobId";
import EmbedChecksViewByCaseId from "./components/EmbedChecksViewByCaseId";
import CasesOverviewView from "./components/TailwindComponents/CasesOverviewView";
import JobBatchesView from "./components/TailwindComponents/JobBatchesView";
import FlashCheckPlayground from "./components/FlashCheckPlayground";
// changes to the theme here will affect all components that use the theme
// see https://mui.com/customization/default-theme/ for more info
const theme = createTheme({
  palette: {
    primary: {
      main: "#5D5FEF",
    },
    secondary: {
      main: "#E9EEF4",
    },
    // Add more customization here
  },
  typography: {
    fontFamily: "Inter, Arial, sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
  },
});

const postHogOptions: Partial<PostHogConfig> = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
  person_profiles: "identified_only",
};

const preventCreateReport = import.meta.env.VITE_PREVENT_CREATE_REPORT === "true";

const App = () => {
  const routes = createRoutesFromElements(
    <>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signout" element={<SignOut />} />
      {!preventCreateReport && <Route path="/create-report" element={<PublicReportForm />} />}
      <Route path="/embed_by_caseid" element={<EmbedChecksViewByCaseId />} />
      <Route path="/embed_by_jobid" element={<EmbedChecksViewByJobId />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }
      >
        <Route path="flash-check" element={<FlashCheckPlayground />} />
        <Route path="/document-requests" element={<DocumentRequestsMainView />} errorElement={<ReportError />}>
          <Route path=":agentKey" element={<DocumentRequestsListView />} />
        </Route>
        <Route path="/submit-doc" element={<DocumentRequestSubmission />} />
        <Route path="/sof-kyc-remediation" element={<SOFKYCRemediation />} />
        <Route path="/incorporation-doc-remediation" element={<IncorporationDocRemediation />} />
        <Route path="/sof-kyb-remediation" element={<SOFKYBRemediation />} />
        <Route path="remediation" element={<Remediation />} />
        <Route path="/api_keys" element={<ApiKeysManager />} />
        <Route path="/checks-overview" element={<ChecksOverview />} />
        <Route path="/cases/:agentKey/:caseId" element={<CaseRedirect />} loader={caseRedirectLoader} />
        <Route path="/persona/:agentKey/:caseId" element={<PersonaLoader />} loader={personaLoader} />
        <Route path="/cases-overview" element={<CasesOverviewView />} errorElement={<ReportError />} />
        <Route path="/job-batches" element={<JobBatchesView />} errorElement={<ReportError />} />

        <Route path="jobs" element={<NewAgentsIndex />} errorElement={<ReportError />}>
          <Route path=":agentKey" element={<NewJobsList />} errorElement={<ReportError />}>
            <Route path=":jobId" element={<NewJobView />} errorElement={<ReportError />} />
          </Route>
        </Route>

        {!preventCreateReport && (
          <Route path="reports" element={<ReportsList />}>
            <Route path=":reportJobId" element={<ReportView />} errorElement={<ReportError />} />
          </Route>
        )}
        <Route
          path="admin"
          element={
            <ProtectedRoute requiredRole="developer">
              <AdminView />
            </ProtectedRoute>
          }
        >
          <Route path="users" element={<UsersTable />}>
            <Route path=":agentKey" element={<UsersTable />} />
          </Route>
          <Route path="apiKeys" element={<ApiKeysManager />} />
          <Route path="integrations" element={<Integrations />} />
          <Route
            path="tenants"
            element={
              <ProtectedRoute requiredRole="ParchaAdmin">
                <TenantsList />
              </ProtectedRoute>
            }
          />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/jobs" />} />
    </>,
  );

  const router = createBrowserRouter(routes);

  return (
    <ThemeProvider theme={theme}>
      <ErrorProvider>
        <AuthProvider projectId={DESCOPE_PROJECT_ID}>
          <ErrorBoundary>
            <PostHogProvider apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY} options={postHogOptions}>
              <KoalaProvider publicApiKey={import.meta.env.VITE_KOALA_API_KEY}>
                <UserProvider>
                  <RouterProvider router={router} />
                </UserProvider>
              </KoalaProvider>
            </PostHogProvider>
          </ErrorBoundary>
        </AuthProvider>
      </ErrorProvider>
    </ThemeProvider>
  );
};

const ReportError = () => {
  const error = useRouteError();

  const subject = `Error loading Parcha report`;
  const body = `The following error occured while loading the report data.\n\n${error}\nReport URL:${window.location.href}`;
  const mailtoLink = `mailto:support@parcha.ai?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
    body || "",
  )}`;

  return (
    <div className="w-full flex justify-center px-20 pt-[5rem]">
      <div className="w-[30rem] flex flex-col items-center gap-y-[2.5rem]">
        <div className="flex items-center justify-center w-[4rem] h-[4rem] bg-indigo-100 rounded-full">
          <WarningRounded sx={{ fontSize: "2rem" }} className="text-brand-purple" />
        </div>
        <span className="text-center font-bold text-xl">It looks like there was an issue loading this report.</span>
        <span className="text-center text-base">
          Please try again by refreshing the page. If the issue persists, please contact support.
        </span>
        <div className="flex gap-x-4">
          <a
            href={mailtoLink}
            className="inline-flex items-center gap-x-1.5 rounded-md bg- px-3 py-2 text-xs font-semibold border border-solid border-slate-300  text-slate-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
          >
            Contact Support
          </a>
          <button
            type="button"
            className="inline-flex items-center gap-x-1.5 rounded-md bg-brand-purple px-3 py-2 text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
            onClick={() => window.location.reload()}
          >
            <RefreshRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
            <span className="line-clamp-1 text-xs">Refresh This Page</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default App;
