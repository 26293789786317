// @ts-nocheck
import { useState, useEffect } from "react";
import _ from "lodash";
import { AdverseMediaHit as AdverseMediaHitType, CommandResult, SanctionHit, StatusMessage } from "@/types";
import Alert from "@/components/TailwindComponents/Alert";
import GovernmentIDCard from "@/components/TailwindComponents/GovernmentIDCard";
import OwnershipDocument from "@/components/TailwindComponents/OwnershipDocument";
import HighRiskIndustryCard from "@/components/TailwindComponents/HighRiskIndustryCard";
import IncorporationDocument from "@/components/TailwindComponents/IncorporationDocument";
import KYCProofOfAddressCard from "@/components/TailwindComponents/KYCProofOfAddressCard";
import KYBProofOfAddressCard from "@/components/TailwindComponents/KYBProofOfAddressCard";
import AdverseMediaHit from "@/components/TailwindComponents/AdverseMediaHit";
import BusinessCenterIcon from "@/assets/business_center.png";
import BuildingIcon from "@/assets/building.png";
import BusinessDueDiligenceCard from "@/components/TailwindComponents/BusinessDueDiligenceCheckCard";
import ProhibitedHighRiskCountryCard from "@/components/TailwindComponents/ProhibitedHighRiskCountryCard";
import SanctionsWatchlistCard from "@/components/TailwindComponents/SanctionsWatchlistCard";
import OpenCorporatesCard from "@/components/TailwindComponents/OpenCorporatesCard";
import MCCCard from "@/components/TailwindComponents/MCCCard";
import ResultAlerts from "@/components/TailwindComponents/ResultAlerts";
import ChatContent from "../../Chat/Content";
import TINCheckCard from "@/components/TailwindComponents/TINCheckCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircleRounded";
import WarningIcon from "@mui/icons-material/WarningRounded";
import CancelRounded from "@mui/icons-material/CancelRounded";
import Icon from "@mui/material/Icon";
import Accordion from "../Accordion";
import { ArrowUpward, ArrowDownward, ErrorOutline, ArrowRightAlt, InfoRounded } from "@mui/icons-material";
import { ChecksFeedback } from "@/components/TailwindComponents/ChecksFeedback";
import CheckStatusMessage from "@/components/TailwindComponents/CheckStatusMessage";
import FakeProgress from "fake-progress";
import Progress from "@/components/ui/progress";
import OC_icon from "@/assets/OC_icon.png";
import OpenCorporatesOfficersCard from "@/components/TailwindComponents/OpenCorporatesOfficersCard";
import AddressVerificationCard from "@/components/TailwindComponents/AddressVerificationCard";
import OpenCorporatesAssociatedEntitiesCard from "@/components/TailwindComponents/OpenCorporatesAssociatedEntitiesCard";
import OpenSourceBusinessDueDiligenceCard from "@/components/TailwindComponents/OpenSourceBusinessDueDiligenceCard";
import UDAPDocumentCard from "@/components/TailwindComponents/UDAPDocumentCard";
import WebsiteResearchCard from "@/components/TailwindComponents/WebsiteResearchCard";
import WebsitePolicyCard, { PolicyType } from "@/components/TailwindComponents/WebsitePolicyCard";
import EINCard from "@/components/TailwindComponents/EINCard";
import SourceOfFundsCard from "@/components/TailwindComponents/SourceOfFundsCard";
import AdverseMediaPotentialHitV2 from "@/components/TailwindComponents/AdverseMediaPotentialHitV2";
import BusinessBasicProfile from "@/components/TailwindComponents/BusinessBasicProfile";
import WebPresenceList from "@/components/TailwindComponents/WebPresence";
import AddressesFindings from "@/components/TailwindComponents/AddressesFindings";
import PoliciesFindings from "@/components/TailwindComponents/PoliciesFindings";
import BusinessOwnersFindings from "@/components/TailwindComponents/BusinessOwnersFindings";
import HighRiskCountryFindings from "@/components/TailwindComponents/HighRiskCountryFindings";
import HighRiskIndustryFindings from "@/components/TailwindComponents/HighRiskIndustryFindings";
import TabularAdverseMedia from "@/components/TailwindComponents/TabularAdverseMedia";
import PEPV2Container from "@/components/TailwindComponents/PEPV2Container";
import TabularPEP from "@/components/TailwindComponents/TabularPEP";
import AdverseMediaV2 from "@/components/TailwindComponents/AdverseMediaV2";
import CannabisLicenseCard from "@/components/TailwindComponents/CannabisLicenseCard";
import SelfAttestedAddressVerificationCard from "@/components/TailwindComponents/SelfAttestedAddressVerificationCard";
import AdverseMediaV3 from "@/components/TailwindComponents/AdverseMediaV3";
import PEPCardV3 from "@/components/TailwindComponents/PEPCardV3";
import numeral from "numeral";
import StrengthIndicator from "@/components/TailwindComponents/StrengthIndicator";
import SourceItem from "@/components/TailwindComponents/SourceItem";
import linkedInLogoIcon from "../../../assets/linked_in_logo.png";
import facebookLogo from "../../../assets/facebook_logo.png";
import CannabisRegistrationCard from "@/components/TailwindComponents/CannabisRegistrationCard";
import defaultCompanyLogo from "../../../assets/default_company_logo.png";
import defaultPeopleProfile from "../../../assets/default_people_profile.png";
import RiskCardV3 from "@/components/TailwindComponents/RiskCardV3";
import BankCheckCard from "@/components/TailwindComponents/BankCheckCard";
import { AnyPtrRecord } from "dns";
import RealEstateCard from "@/components/TailwindComponents/RealEstateCard";
import SourceOfWealthOpenCorporatesOfficersCard from "@/components/TailwindComponents/SourceOfWealthOpenCorporatesOfficersCard";
import { formatNumberToCurrency } from "@/utils";
import { useParchaApi } from "@/hooks/useParchaApi";

const iconsMap: { [k: string]: string } = {
  "KYC Government ID Verification Tool": "badge",
  "Government ID Verification": "badge",
  "KYB TIN Format Check Tool": "pin",
  "KYB TIN Check Tool": "pin",
  "TIN Check": "pin",
  "TIN Format Check": "pin",
  "EIN Verification Tool": "pin",
  "Employer Identification Number (EIN) Verification": "pin",
  "KYB High Risk Country Screening Tool": "public_off",
  "High Risk Country Check": "public_off",
  "High-Risk Countries": "public_off",
  "KYB High Risk Industry Screening Tool": "domain_disabled",
  "KYB Self Attested Address Verification Tool": "markunread_mailbox",
  "High Risk Industry Check": "domain_disabled",
  "High-Risk Industries": "domain_disabled",
  "Address Verification": "markunread_mailbox",
  "Proof of Address Verification Tool": "markunread_mailbox",
  "Proof of Address Extraction Tool": "markunread_mailbox",
  "Proof of Address Check for Business": "markunread_mailbox",
  "Proof of Address": "markunread_mailbox",
  "Incorporation Document Check": "description",
  "Business Registration Extraction Tool": "description",
  "Business Registration Verification Tool": "description",
  "Business Ownership Document Check": "description",
  "Business Ownership Verification tool": "description",
  "Business Ownership Extraction tool": "description",
  "KYB Sanctions List Screening Tool": "visibility",
  "Adverse Media Check": "receipt_long",
  "KYB Adverse Media Screening Tool": "receipt_long",
  "KYC Adverse Media Screening Tool": "receipt_long",
  "Adverse Media Profile Check": "receipt_long",
  "Adverse Media Event Check": "receipt_long",
  "Adverse Media and OSInt Check": "receipt_long",
  "Adverse Media": "receipt_long",
  "PEP Screening Check": "tour",
  "KYC PEP Screening Tool": "tour",
  "Politically Exposed Person Check": "tour",
  "UBO Entity Sanctions Watchlist Check": "visibility",
  "Sanctions Watchlist Check": "visibility",
  "Sanctions Watchlist": "visibility",
  "Sanctions and Watchlists Check": "visibility",
  "Individual Watchlist Check": "visibility",
  "KYC Sanctions Watchlist Screening Tool": "visibility",
  "Open Source Business Due Diligence Check": "business",
  "KYB Open Source Business Due Diligence Check Tool": "business",
  "Perform Web Research on the Business": "screen_search_desktop",
  "KYB Web Research Tool": "screen_search_desktop",
  "KYB MCC Check Tool": "factory",
  "MCC Code Check": "factory",
  "MCC Code": "factory",
  "UDAP Check": "policy",
  "Source of Funds Document Verification Tool": "request_quote",
  "Basic Profile Check": "business",
  "Business Basic Profile": "business",
  Addresses: "location_on",
  "Addresses Check": "location_on",
  "Policies Check": "policy",
  Policies: "policy",
  "Business Owners Check": "person",
  "Web Presence Check": "language",
  "Online Presence": "language",
  "Self Attested Address Verification": "markunread_mailbox",
  "Source of Wealth Check": "request_quote",
  "Bank Check Validation": "assured_workload",
  "Cannabis License Verification": "local_florist",
  "Cannabis License Document Verification": "local_florist",
  "Cannabis Registration Check": "local_florist",
  "Real Estate Property Check": "building",
  "Owned Properties": "home_work",
  "Owned Businesses": "business",
  "Income Estimation": "business_center",
  "Business Reviews Check": "star_half",
};

type LinkedInExperienceEntryProps = {
  title: string;
  company: string;
  location?: string;
  start: string;
  end: string;
  annual_salary_high?: string;
  annual_salary_low?: string;
  total_earnings_high?: string;
  total_earnings_low?: string;
};

type LinkedInExperienceEntryV2Props = {
  title: string;
  company: string;
  location?: string;
  start: string;
  end: string;
  annual_salary_high?: string;
  annual_salary_low?: string;
  total_earnings_high?: string;
  total_earnings_low?: string;
};

const CheckContainer = ({
  caseId,
  agentRun,
  apiKey,
  commandResult,
  auditLogMessages,
  commandInstanceId,
  endpointUrl,
  feedbackData,
  sendFeedbackHandler,
  messages,
  title,
  isLegacyJob,
  setSelectedSidePanelTabValue,
  compact,
  showAllContent,
  selectedGenders = [],
  selectedLocations = [],
  expanded = true,
  auditLogVisible = true,
  handleDocumentValidationClick,
  displayOnlyMatches = false,
  hideFeedbackControls = false,
}: any) => {
  const [isAuditLogExpanded, setIsAuditLogExpanded] = useState(false);
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [progress, setProgress] = useState(0);
  const [activeFeedbackId, setActiveFeedbackId] = useState<string | null>(null);
  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState("");
  const [expandedProfile, setExpandedProfile] = useState(0);
  const [feedbackInputs, setFeedbackInputs] = useState<any[]>([]);
  const [isLoadingFeedback, setIsLoadingFeedback] = useState(true);
  const parchaApi = useParchaApi();

  useEffect(() => {
    if (showAllContent) {
      setIsAuditLogExpanded(true);
      setIsExpanded(true);
    } else {
      setIsExpanded(expanded);
    }
  }, [showAllContent]);

  useEffect(() => {
    if (commandResult) return;

    const p = new FakeProgress({
      timeConstant: 10000,
      autoStart: true,
    });

    const interval = setInterval(() => {
      setProgress(p.progress);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!agentRun.id) return;

    const getFeedbackInput = async () => {
      let feedbackInputs;
      const jobId = agentRun.id;

      if (apiKey) {
        feedbackInputs = await parchaApi.getFeedbackInputsByKeyFromAPI(endpointUrl, jobId, "adverse_media", apiKey);
      } else {
        feedbackInputs = await parchaApi.getFeedbackInputsByKey(endpointUrl, jobId, "adverse_media");
      }

      if (feedbackInputs) {
        setFeedbackInputs(feedbackInputs);
      }
      setIsLoadingFeedback(false);
    };
    getFeedbackInput();
  }, [apiKey, agentRun.id]);

  const groupedByWorldCheckId = feedbackInputs.reduce((acc, feedback) => {
    const rowId = feedback.row_id;

    if (!acc[rowId] || new Date(acc[rowId].created_at) < new Date(feedback.created_at)) {
      acc[rowId] = feedback;
    }
    return acc;
  }, {});

  const renderAccordionTitle = (messages: StatusMessage[]) => {
    const commandResultMessage = messages.find((m) => m.event === "command_results");
    const errorMessage = messages.some((m) => m.event === "error");
    const didPass = ["true", "pass", "passed", "yes"].includes(commandResult?.passed?.toString().toLowerCase());

    title = title === "Source of Wealth Check" ? "Income Estimation" : title;
    title = title === "Open Corporates Business Check" ? "Owned Businesses" : title;
    title = title === "Real Estate Property Check" ? "Owned Properties" : title;

    const iconKey = iconsMap[title];
    const alerts = commandResultMessage?.content?.output?.alerts
      ? Object.entries(commandResultMessage.content.output.alerts)
      : [];

    return compact ? null : (
      <div className="h-full w-full flex flex-col gap-x-2 gap-y-4">
        <div className="flex items-center gap-2 lg:gap-0 lg:flex-row justify-between">
          <div className="flex gap-x-2 lg:items-center grow line-clamp-1">
            {title?.includes("Open Corporates") ? (
              <img src={OC_icon} width={20} height={20} className="h-5 w-5" />
            ) : (
              <Icon sx={{ fontSize: "1.25rem" }} className="material-icons-round text-slate-400">
                {iconKey}
              </Icon>
            )}
            <span>{title}</span>
          </div>

          <div className="flex flex-col lg:flex-row gap-1 items-center">
            {!commandResult && !["error", "failed", "complete"].includes(agentRun.status) && firstMessage && (
              <Progress className="w-[5rem] h-[0.5rem]" value={progress * 100} />
            )}
            {errorMessage && <ErrorOutline sx={{ fontSize: "1.25rem" }} className="text-red-500" />}
            {alerts.length > 0 && <WarningIcon sx={{ fontSize: "1.25rem" }} className="text-yellow-500" />}
            {commandResult.passed !== null ? (
              didPass ? (
                <CheckCircleIcon sx={{ fontSize: "1.25rem" }} className="text-green-600" />
              ) : (
                <CancelRounded sx={{ fontSize: "1.25rem" }} className="text-red-500" />
              )
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const renderAccordionMain = (messages: StatusMessage[]) => {
    const commandResult = messages.find((m) => m.event === "command_results");
    const lastStatusMessage = messages.findLast((message) => message.event === "status");
    const errorMessage = messages.find((m) => m.event === "error");

    return (
      <>
        {!commandResult && !errorMessage && auditLogMessages.length > 0 ? (
          <ChatContent
            key={commandInstanceId}
            statusMessages={auditLogMessages}
            isParchaUser={false}
            jobStatus={agentRun.status}
            feedbackData={feedbackData}
            caseId={caseId}
            endpointUrl={endpointUrl}
            showAllContent={showAllContent}
          />
        ) : null}
        {errorMessage ? (
          <div className="flex flex-col gap-4 mt-2">
            <div className="border-2 border-red-500 rounded-md w-full p-4 text-red-700 text-xs">
              <span className="font-semibold">Error: </span>
              <p className="overflow-hidden text-ellipsis nowrap">{errorMessage.content.output.error_display}</p>
            </div>
            <div className="place-self-end">
              <button
                type="button"
                className="inline-flex items-center gap-x-1 rounded-md border-solid border-slate-300 border bg-white px-3 py-1.5 font-medium text-xs text-slate-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple"
                onClick={() => onContactSupportClickHandler(errorMessage.content.output)}
              >
                Contact Support
              </button>
            </div>
          </div>
        ) : lastStatusMessage && !commandResult && agentRun.status === "in progress" ? (
          <div className="ml-2.5">
            <CheckStatusMessage messageContents={{ status: lastStatusMessage.content.status || "" }} />
          </div>
        ) : null}
      </>
    );
  };

  const renderPEP = (verifiedPepHits, input, agentKey, commandId, agentInstanceId, commandInstanceId) => {
    return (
      <PEPCardV3
        apiKey={apiKey}
        verifiedPepHits={verifiedPepHits}
        input={input}
        commandId={commandId}
        agentInstanceId={agentInstanceId}
        commandInstanceId={commandInstanceId}
        agentKey={agentKey}
        endpointUrl={endpointUrl}
        jobId={agentRun.id}
        showAllContent={showAllContent}
      />
    );
  };

  const renderAdverseMedia = (
    verifiedAdverseMediaHits,
    input,
    agentKey,
    commandId,
    agentInstanceId,
    commandInstanceId,
  ) => {
    return (
      <AdverseMediaV3
        apiKey={apiKey}
        verifiedAdverseMediaHits={verifiedAdverseMediaHits}
        input={input}
        commandId={commandId}
        agentInstanceId={agentInstanceId}
        commandInstanceId={commandInstanceId}
        agentKey={agentKey}
        endpointUrl={endpointUrl}
        jobId={agentRun.id}
        showAllContent={showAllContent}
        displayOnlyMatches={displayOnlyMatches}
      />
    );
  };

  const renderAccordionContent = (messages: StatusMessage[]) => {
    const selfAttestedDataMessage = agentRun.status_messages?.find((message) => {
      const commandResult = message.content as CommandResult;
      const output = commandResult?.output as OutputSchema;
      const payload = output?.payload;
      const type = payload?.type;

      return message.event === "api_results" && type === "SelfAttestedData";
    });

    const commandResultMessage = messages.find((message) => message.event === "command_results");

    const alerts = commandResult?.alerts ? Object.entries(commandResult.alerts) : [];

    const didPass = ["true", "pass", "passed", "yes"].includes(commandResult?.passed?.toString().toLowerCase());
    const output = commandResult.check_result;
    const payload = commandResult.check_result;
    const verifiedData = commandResult.verified_data;
    const level = didPass ? "success" : "failure";
    const feedbackKey = `${commandResult.agent_instance_id}${commandResult.command_instance_id}`;

    const getIncorporationDocuments = () => {
      // First, try the old way with payload.verified_documents
      if (payload.verified_documents?.some((entry) => entry.document)) {
        return payload.verified_documents.map((entry) => ({
          fileName: entry.document.file_name,
          url: entry.document?.url
            ? `${
                window.location.protocol
              }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(entry.document.url)}`
            : null,
          summary: entry.summary,
        }));
      }

      const documents = [
        ...(payload.invalid_documents || []),
        ...(payload.valid_documents || []),
        ...(verifiedData.valid_documents || []),
        ...(verifiedData.invalid_documents || []),
      ].filter((entry) => entry.document);

      if (documents.length > 0) {
        return documents
          .filter((entry) => entry.document)
          .map((entry) => ({
            fileName: entry.document.file_name,
            url: entry.document?.url
              ? `${
                  window.location.protocol
                }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(entry.document.url)}`
              : null,
            summary: entry.summary || entry.extraction_data?.summary,
            documentType: entry.document_type || entry.extraction_data?.document_type,
            isValidDocument: entry.is_valid_document || entry.extraction_data?.is_valid_document,
          }));
      }

      // If the old way isn't available, try the new way with commandResult.verified_data.documented_incorporation_information
      if (commandResult.verified_data?.documented_incorporation_information?.some((record) => record.document)) {
        return commandResult.verified_data.documented_incorporation_information.map((entry) => ({
          fileName: entry.document.file_name,
          url: `${
            window.location.protocol
          }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(entry.document.url)}`,
          summary: entry.summary,
          fraudVerificationData: entry.fraud_verification_data,
          isValidDocument: entry.is_valid_document,
        }));
      }

      // If neither is available, return an empty array
      return [];
    };

    const LinkedInExperienceEntry = ({
      title,
      company,
      location,
      start,
      end,
      duration,
      salary_explanation,
      annual_salary_high,
      annual_salary_low,
      total_earnings_high,
      total_earnings_low,
      source_url,
      source_type,
      hit_match,
    }: LinkedInExperienceEntryProps) => {
      return (
        <div className="flex flex-col gap-y-1 text-slate-900 py-5 border-b border-slate-200">
          <div className="flex justify-between items-start">
            <div className="gap-y-1 w-2/3">
              <div className="text-sm font-semibold inline-flex items-center">
                {title}
                <span className="ml-1 text-xs font-normal text-slate-700">{company}</span>
                {source_url &&
                  source_type &&
                  (source_type === "linkedin_profile_link" ? (
                    <a href={source_url} target="_blank" rel="noopener noreferrer" className="ml-3">
                      <img src={linkedInLogoIcon} alt="LinkedIn" className="w-3 h-3" />
                    </a>
                  ) : null)}
              </div>
              <div className="text-xs font-normal text-slate-500">
                {start && end
                  ? `${start} - ${end}${duration ? ` • ${duration}` : ""}${location ? ` • ${location}` : ""}`
                  : duration
                    ? `${duration}`
                    : null}
              </div>
              <div className="mt-2 text-xs font-normal text-slate-700">{salary_explanation}</div>
            </div>

            <div className="text-right flex flex-col justify-center h-full w-1/3">
              {total_earnings_low != null && total_earnings_high != null && (
                <div className="mb-1">
                  <div className="text-xs text-slate-700 font-semibold">
                    Total Earnings:{" "}
                    <span className="text-xs text-green-700">
                      {formatNumberToCurrency(total_earnings_low)} - {formatNumberToCurrency(total_earnings_high)}
                    </span>
                  </div>
                </div>
              )}
              {annual_salary_low != null && annual_salary_high != null && (
                <div className="mb-2">
                  <div className="text-xs text-slate-700 font-normal">
                    Annual Salary:{" "}
                    <span className="text-xs text-green-700">
                      {formatNumberToCurrency(annual_salary_low)} - {formatNumberToCurrency(annual_salary_high)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    };

    const LinkedInExperienceEntryV2 = ({
      title,
      associate_business_basic_info,
      company,
      company_logo_url,
      location,
      start,
      end,
      duration,
      salary_explanation,
      salary_reference_urls,
      work_experience_url,
      company_experience_url,
      annual_salary_high,
      annual_salary_low,
      total_earnings_high,
      total_earnings_low,
      source_url,
      source_type,
      hit_match,
    }: LinkedInExperienceEntryV2Props) => {
      if (start && !end) {
        end = 'Present';
      }
      return (
        <div className="flex flex-col text-slate-900 py-5 border-b border-slate-200">
          <div className={`flex justify-between${associate_business_basic_info ? " pb-[10px] border-b border-slate-200" : ""}`}>
            {!associate_business_basic_info && (
              <img
                src={company_logo_url || defaultCompanyLogo}
                alt={`${company} logo`}
                className="w-10 h-10 mr-3"
                style={{ borderRadius: "4px" }}
              />
            )}
            <div className="items-start w-2/3 gap-y-0">
              <div className="text-sm font-semibold inline-flex items-center">
                {title}
                <span className="ml-1 text-xs font-normal text-slate-700">at {company}</span>
                <a
                  key={`work-exp-url`}
                  href={work_experience_url.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-3 w-3.5 h-3.5 rounded-full bg-slate-200 flex items-center justify-center text-[8px] text-slate-500 hover:bg-slate-300"
                  onClick={(e) => e.stopPropagation()}
                >
                  {work_experience_url.index}
                </a>
                {/* {salary_reference_urls?.map((reference, index) => (
                  <a 
                    key={`salary-ref-${index}`}
                    href={reference.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-1 w-3.5 h-3.5 rounded-full bg-slate-200 flex items-center justify-center text-[8px] text-slate-500 hover:bg-slate-300"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {reference.index}
                  </a>
                ))} */}
                {source_url &&
                  source_type &&
                  (source_type === "linkedin_profile_link" ? (
                    <a href={source_url} target="_blank" rel="noopener noreferrer" className="ml-3">
                      <img src={linkedInLogoIcon} alt="LinkedIn" className="w-3 h-3" />
                    </a>
                  ) : null)}
              </div>
              <div className="text-xs font-normal text-slate-500">
                {start && end
                  ? `${start} - ${end}${duration ? ` • ${duration}` : ""}${location ? ` • ${location}` : ""}`
                  : duration
                    ? `${duration}`
                    : null}
              </div>
              <div className="mt-2 text-xs font-normal text-slate-700">{salary_explanation}</div>
            </div>

            <div className="text-right w-1/3">
              {annual_salary_low != null && annual_salary_high != null && (
                <div className="text-xs text-slate-700 font-normal flex items-center justify-end gap-x-1">
                  Annual Salary:{" "}
                  <span className="text-xs text-green-700">
                    {formatNumberToCurrency(annual_salary_low)} - {formatNumberToCurrency(annual_salary_high)}
                  </span>
                  <div className="flex items-center gap-x-1">
                    {salary_reference_urls?.map((reference, index) => (
                      <a
                        key={`salary-ref-${index}`}
                        href={reference.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="w-3.5 h-3.5 rounded-full bg-slate-200 flex items-center justify-center text-[8px] text-slate-500 hover:bg-slate-300"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {reference.index}
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          {associate_business_basic_info && (
            <div className="flex justify-between mt-[10px]">
              <img
                src={company_logo_url || defaultCompanyLogo}
                alt={`${company} logo`}
                className="w-10 h-10 mr-3"
                style={{ borderRadius: "4px" }}
              />
              <div className="items-start w-full gap-y-0">
                <div className="text-sm font-semibold inline-flex items-center">
                  {company}
                  <a
                    key={`work-exp-url`}
                    href={company_experience_url.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-3 w-3.5 h-3.5 rounded-full bg-slate-200 flex items-center justify-center text-[8px] text-slate-500 hover:bg-slate-300"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {company_experience_url.index}
                  </a>
                  {source_url &&
                    source_type &&
                      (source_type === "linkedin_profile_link" ? (
                        <a href={source_url} target="_blank" rel="noopener noreferrer" className="ml-3">
                          <img src={linkedInLogoIcon} alt="LinkedIn" className="w-3 h-3" />
                        </a>
                      ) : null)}
                </div>
                <div className="flex items-center gap-x-5 text-xs font-normal text-slate-500">
                  {associate_business_basic_info.industry && (
                    <div className="flex">
                      <Icon 
                        className="material-icons-round text-slate-400 mr-2"
                        sx={{ fontSize: "1rem" }}
                      >
                        factory
                      </Icon>
                      {associate_business_basic_info.industry}
                    </div>
                  )}
                  {associate_business_basic_info.location && (
                    <div className="flex">
                      <Icon 
                        className="material-icons-round text-slate-400 mr-2"
                        sx={{ fontSize: "1rem" }}
                      >
                        pin_drop
                      </Icon>
                      {associate_business_basic_info.location}
                    </div>
                  )}
                  {associate_business_basic_info.employee_size && (
                    <div className="flex">
                      <Icon 
                        className="material-icons-round text-slate-400 mr-2"
                        sx={{ fontSize: "1rem" }}
                      >
                        people
                      </Icon>
                      {associate_business_basic_info.employee_size}
                    </div>
                  )}
                </div>
                {associate_business_basic_info.business_description && (
                  <div className="text-xs font-normal text-slate-700 mt-2">{associate_business_basic_info.business_description}</div>
                )}
              </div>
            </div>
          )}
        </div>
      );
    };

    function formatNumberToCurrency(num: number | null | undefined): string {
      // Return early if num is null/undefined
      if (num === null || num === undefined) {
        return "$0";
      }

      // Convert to number if it's a string
      const value = Number(num);

      // Check if conversion resulted in a valid number
      if (isNaN(value)) {
        return "$0";
      }

      if (value >= 1e9) {
        return `$${(value / 1e9).toFixed(value % 1e9 === 0 ? 0 : 2)}B`; // Billion
      } else if (value >= 1e6) {
        return `$${(value / 1e6).toFixed(value % 1e6 === 0 ? 0 : 2)}M`; // Million
      } else if (value >= 1e3) {
        return `$${(value / 1e3).toFixed(value % 1e3 === 0 ? 0 : 2)}K`; // Thousand
      } else {
        return `$${value.toFixed(value % 1 === 0 ? 0 : 2)}`; // Less than thousand
      }
    }

    if (payload?.type === "KYCSourceOfWealthCheckResultV2" && payload?.profiles?.length > 0) {
      // Add salary sources from all profiles
      let urlCounter = 1; // Initialize counter
      const salary_sources = payload?.profiles?.flatMap((profile) => {
        const sources = profile.linkedin_profile_url
          ? [
              {
                url: profile.linkedin_profile_url,
                title: `LinkedIn Profile for ${profile.full_name}`,
                index: urlCounter++,
              },
            ]
          : [];

        // Add salary reference URLs from working experiences
        if (profile.source_of_wealth_estimation?.working_experiences_with_wealth) {
          profile.source_of_wealth_estimation.working_experiences_with_wealth.forEach((exp) => {
            if (exp.associate_business_basic_info) {
              const company_source = {
                url: exp.company_linkedin_url,
                title: `Company LinkedIn Profile for ${exp.company}`,
                index: urlCounter++,
              }
              sources.push(company_source);
              exp.company_experience_url = company_source;
            }
            if (exp.salary_reference_urls) {
              // Create URL objects array starting with LinkedIn profile if it exists
              const urlObjects = [];

              // Add LinkedIn profile URL first if it exists
              if (profile.linkedin_profile_url && !exp.work_experience_url) {
                exp.work_experience_url = sources[0];
              }

              // Add the rest of the salary reference URLs
              const additionalUrls = exp.salary_reference_urls.map((urlString) => ({
                url: urlString,
                title: `${exp.title} - Salary Reference`,
                index: urlCounter++,
              }));
              urlObjects.push(...additionalUrls);

              sources.push(...additionalUrls);
              if (!profile.source_of_wealth_estimation.total_sources) {
                exp.salary_reference_urls = urlObjects;
              }
            }
          });
        }

        if (profile.source_of_wealth_estimation && !profile.source_of_wealth_estimation.total_sources) {
          profile.source_of_wealth_estimation.total_sources = sources;
        }

        return sources;
      });
    }

    console.log(payload);

    return (
      <div key={commandResult?.command_instance_id} className="flex flex-col gap-4">
        {commandResult?.answer && (
          <div className="mt-2">
            <Alert level={didPass ? "success" : "failure"} message={commandResult.answer} />
          </div>
        )}
        {alerts.length > 0 && <ResultAlerts alerts={alerts} />}
        {payload?.type === "AdverseMediaCheckResult" && payload?.verified_adverse_media_hits
          ? payload.verified_adverse_media_hits.map((mediaHit: AdverseMediaHitType, index: number) => {
              if (!mediaHit.matched_lists || mediaHit.matched_lists?.length === 0) return null;

              return (
                <div key={index} className="flex flex-col gap-5 mt-5">
                  <SanctionsWatchlistCard
                    countryCode={mediaHit.matched_lists?.length > 0 ? mediaHit.matched_lists[0].split("_")[0] : null}
                    url={mediaHit.source_url}
                    sanctionListName={mediaHit.matched_lists[0]}
                    matchedNames={mediaHit.matched_names}
                    level="failure"
                    description={mediaHit.description}
                  />
                </div>
              );
            })
          : null}
        {payload?.verified_sanctions_hits
          ? payload.verified_sanctions_hits.map((sanctionHit: SanctionHit, index: number) => {
              if (!sanctionHit.matched_lists || sanctionHit.matched_lists?.length === 0) return null;

              return (
                <div key={index} className="flex flex-col gap-5 mt-5">
                  <SanctionsWatchlistCard
                    countryCode={
                      sanctionHit.matched_lists?.length > 0 ? sanctionHit.matched_lists[0].split("_")[0] : null
                    }
                    url={sanctionHit.source_url}
                    sanctionListName={sanctionHit.matched_lists[0]}
                    matchedNames={sanctionHit.matched_names}
                    level="failure"
                    description={sanctionHit.description}
                  />
                </div>
              );
            })
          : null}
        {payload?.type === "PEPScreeningCheckResult" && payload?.verified_pep_hits?.length
          ? payload.verified_pep_hits.map((sanctionHit: SanctionHit, index: number) => {
              if (!sanctionHit.matched_lists || sanctionHit.matched_lists?.length === 0) return null;

              return (
                <div key={index} className="flex flex-col gap-5 mt-5">
                  <SanctionsWatchlistCard
                    countryCode={
                      sanctionHit.matched_lists?.length > 0 ? sanctionHit.matched_lists[0].split("_")[0] : null
                    }
                    url={sanctionHit.source_url}
                    sanctionListName={sanctionHit.matched_lists[0]}
                    matchedNames={sanctionHit.matched_names}
                    level="failure"
                    description={sanctionHit.description}
                  />
                </div>
              );
            })
          : null}

        {payload?.type === "OpenCorporatesBusinessCheckResult" &&
          payload.open_corporates_hits?.map((openCorporateResult: OpencorporatesData) => {
            return (
              <OpenCorporatesCard
                key={openCorporateResult.name + openCorporateResult.source}
                source={openCorporateResult.source}
                name={openCorporateResult.name}
                inactive={openCorporateResult.inactive}
                address={{
                  street1: openCorporateResult.address,
                  street2: null,
                  city: null,
                  state: null,
                  countryCode: null,
                  postalCode: null,
                }}
                incorporationDate={openCorporateResult.incorporation_date}
                openCorporatesUrl={openCorporateResult.opencorporates_url}
                registryUrl={openCorporateResult.registry_url}
                level={level}
              />
            );
          })}
        {payload?.type === "OpenCorporatesOfficersCheckResult" && (
          <div className="flex flex-col gap-y-2">
            {payload.verified_business_owners?.map(
              (owner: {
                name: string;
                open_corporates_business: {
                  business_name: string;
                  open_corporates_url: string;
                  positions_held_by_business_owner: string[];
                }[];
              }) => (
                <OpenCorporatesOfficersCard
                  key={owner.name}
                  name={owner.name}
                  companies={
                    owner.open_corporates_business?.map((business) => ({
                      name: business.business_name,
                      url: business.open_corporates_url,
                      position: business.positions_held_by_business_owner,
                    })) || []
                  }
                  level={level}
                  tag="Matched"
                  tagLevel="success"
                />
              ),
            )}
            {payload.not_attested_business_owners?.map((notAttestedOwner: string) => {
              return (
                <OpenCorporatesOfficersCard
                  key={notAttestedOwner}
                  name={notAttestedOwner}
                  companies={[]}
                  level="failure"
                  tag="Not Listed"
                  tagLevel="failure"
                />
              );
            })}

            {payload.unverified_business_owners?.map((unverifiedOwner: string) => {
              return (
                <OpenCorporatesOfficersCard
                  key={unverifiedOwner}
                  name={unverifiedOwner}
                  companies={[]}
                  level={"failure"}
                  tag="Not Found"
                  tagLevel="failure"
                />
              );
            })}
          </div>
        )}
        {payload?.type === "OpenCorporatesOfficersAssociationsCheckResult" &&
          payload.business_potential_associations?.length > 0 && (
            <div className="flex flex-col gap-y-4">
              {selfAttestedDataMessage ? (
                <div className="p-4 text-xs bg-blue-100 text-slate-700 rounded flex gap-x-1 items-center">
                  <InfoRounded sx={{ fontSize: "1rem" }} className="text-slate-700" />
                  <span className="font-medium">
                    {`The following businesses are potentially associated with officers of ${
                      selfAttestedDataMessage.content.output.payload.business_name ||
                      selfAttestedDataMessage.content.output.payload.registered_business_name
                    }.`}
                  </span>
                </div>
              ) : null}
              <div className="flex flex-col gap-y-2">
                {payload.business_potential_associations?.map(
                  (business: {
                    business_name: string;
                    opencorporates_url: string;
                    associated_business_owners: {
                      name: string;
                      open_corporates_url: string | null;
                      positions_held_by_business_owner: string[];
                    }[];
                  }) => (
                    <OpenCorporatesAssociatedEntitiesCard
                      key={business.business_name}
                      name={business.business_name}
                      url={business.opencorporates_url}
                      officers={business.associated_business_owners?.map((owner) => ({
                        name: owner.name,
                        position: owner.position,
                      }))}
                      level={"normal"}
                    />
                  ),
                )}
              </div>
            </div>
          )}
        {payload?.type === "SelfAttestedAddressCheckResult" && (
          <div className="mt-4">
            <AddressVerificationCard
              level={level}
              address={{
                street1: commandResult.input_data.address_of_operation.street_1,
                street2: commandResult.input_data.address_of_operation.street_2,
                city: commandResult.input_data.address_of_operation.city,
                state: commandResult.input_data.address_of_operation.state,
                postalCode: commandResult.input_data.address_of_operation.postal_code,
                countryCode: commandResult.input_data.address_of_operation.country_code,
              }}
              addressType={
                payload.operating_address_is_business
                  ? "Business"
                  : payload.operating_address_is_residential
                    ? "Residential"
                    : payload.operating_address_is_pobox
                      ? "PO Box"
                      : "Address not valid"
              }
            />
          </div>
        )}
        {payload?.type === "OpenSourceBusinessDueDiligenceCheckResult" ? (
          payload.verified_business_name ? (
            <div className="flex justify-center items-center flex-col">
              <BusinessDueDiligenceCard
                level={level}
                name={payload.verified_business_name}
                address={
                  payload.verified_business_address
                    ? {
                        street1: payload.verified_business_address.street_1,
                        street2: payload.verified_business_address.street_2,
                        city: payload.verified_business_address.city,
                        state: payload.verified_business_address.state,
                        postalCode: payload.verified_business_address.postal_code,
                        countryCode: payload.verified_business_address.country_code,
                      }
                    : {
                        street1: "",
                        street2: "",
                        city: "",
                        state: "",
                        postalCode: "",
                        countryCode: "",
                      }
                }
                description={payload.verified_business_description}
              />
            </div>
          ) : (
            <OpenSourceBusinessDueDiligenceCard
              level={level}
              name={commandResult.input_data.business_name}
              description={commandResult.input_data.business_description}
              businessDescriptionMatch={payload.business_description_is_exact_match_validated}
              websiteUrl={commandResult.input_data.business_website}
              businessNameMatch={
                payload.business_name_is_exact_match_validated
                  ? "exact"
                  : payload.business_name_is_soft_match_validated
                    ? "soft"
                    : "none"
              }
              businessWebsiteMatch={payload.business_website_is_exact_match_validated}
              incorporationAddress={{
                street1: commandResult.input_data.address_of_incorporation.street_1,
                street2: commandResult.input_data.address_of_incorporation.street_2,
                city: commandResult.input_data.address_of_incorporation.city,
                state: commandResult.input_data.address_of_incorporation.state,
                postalCode: commandResult.input_data.address_of_incorporation.postal_code,
                countryCode: commandResult.input_data.address_of_incorporation.country_code,
              }}
              operationAddress={{
                street1: commandResult.input_data.address_of_operation.street_1,
                street2: commandResult.input_data.address_of_operation.street_2,
                city: commandResult.input_data.address_of_operation.city,
                state: commandResult.input_data.address_of_operation.state,
                postalCode: commandResult.input_data.address_of_operation.postal_code,
                countryCode: commandResult.input_data.address_of_operation.country_code,
              }}
              addressOfOperationMatch={
                payload.address_of_operation_is_exact_match_validated
                  ? "exact"
                  : payload.address_of_operation_is_soft_match_validated
                    ? "soft"
                    : "none"
              }
              addressOfIncorporationMatch={
                payload.address_of_incorporation_is_exact_match_validated
                  ? "exact"
                  : payload.address_of_incorporation_is_soft_match_validated
                    ? "soft"
                    : "none"
              }
              businessOwnerMatches={[]}
            />
          )
        ) : null}

        {payload?.type === "OpenSourceBusinessDueDiligenceCheckResultV2" ? (
          payload.verified_business_name ? (
            <div className="flex justify-center items-center flex-col">
              <BusinessDueDiligenceCard
                level={level}
                name={payload.verified_business_name}
                address={
                  payload.verified_business_address
                    ? {
                        street1: payload.verified_business_address.street_1,
                        street2: payload.verified_business_address.street_2,
                        city: payload.verified_business_address.city,
                        state: payload.verified_business_address.state,
                        postalCode: payload.verified_business_address.postal_code,
                        countryCode: payload.verified_business_address.country_code,
                      }
                    : {
                        street1: "",
                        street2: "",
                        city: "",
                        state: "",
                        postalCode: "",
                        countryCode: "",
                      }
                }
                description={payload.verified_business_description}
              />
            </div>
          ) : (
            <OpenSourceBusinessDueDiligenceCard
              level={level}
              name={payload.business_name_match?.name || commandResult.input_data.business_name}
              description={payload.business_description_match?.description}
              businessDescriptionMatchExplanation={payload.business_description_match?.explanation}
              businessDescriptionMatch={
                payload.business_description_match?.exact_match
                  ? "exact"
                  : payload.business_description_match?.partial_match
                    ? "soft"
                    : "none"
              }
              websiteUrl={payload.business_website_match?.website}
              businessNameMatchExplanation={payload.business_name_match?.explanation}
              businessNameMatch={
                payload.business_name_match?.exact_match
                  ? "exact"
                  : payload.business_name_match?.partial_match
                    ? "soft"
                    : "none"
              }
              businessWebsiteMatchExplanation={payload.business_website_match?.explanation}
              businessWebsiteMatch={
                payload.business_website_match?.exact_match
                  ? "exact"
                  : payload.business_website_match?.partial_match
                    ? "soft"
                    : "none"
              }
              incorporationAddress={{
                street1:
                  payload.business_incorporation_address_match?.address?.street_1 ||
                  commandResult.input_data.address_of_incorporation.street_1,
                street2:
                  payload.business_incorporation_address_match?.address?.street_2 ||
                  commandResult.input_data.address_of_incorporation.street_2,
                city:
                  payload.business_incorporation_address_match?.address?.city ||
                  commandResult.input_data.address_of_incorporation.city,
                state:
                  payload.business_incorporation_address_match?.address?.state ||
                  commandResult.input_data.address_of_incorporation.state,
                postalCode:
                  payload.business_incorporation_address_match?.address?.postal_code ||
                  commandResult.input_data.address_of_incorporation.postal_code,
                countryCode:
                  payload.business_incorporation_address_match?.address?.country_code ||
                  commandResult.input_data.address_of_incorporation.country_code,
              }}
              operationAddress={{
                street1:
                  payload.business_operating_address_match?.address?.street_1 ||
                  commandResult.input_data.address_of_operation.street_1,
                street2:
                  payload.business_operating_address_match?.address?.street_2 ||
                  commandResult.input_data.address_of_operation.street_2,
                city:
                  payload.business_operating_address_match?.address?.city ||
                  commandResult.input_data.address_of_operation.city,
                state:
                  payload.business_operating_address_match?.address?.state ||
                  commandResult.input_data.address_of_operation.state,
                postalCode:
                  payload.business_operating_address_match?.address?.postal_code ||
                  commandResult.input_data.address_of_operation.postal_code,
                countryCode:
                  payload.business_operating_address_match?.address?.country_code ||
                  commandResult.input_data.address_of_operation.country_code,
              }}
              businessOperatingAddressMatchExplanation={payload.business_operating_address_match?.explanation}
              addressOfOperationMatch={
                payload.business_operating_address_match?.exact_match
                  ? "exact"
                  : payload.business_operating_address_match?.partial_match
                    ? "soft"
                    : "none"
              }
              addressOfIncorporationMatch={
                payload.address_of_incorporation_match?.exact_match
                  ? "exact"
                  : payload.address_of_incorporation_match?.partial_match
                    ? "soft"
                    : "none"
              }
              businessIncorporationAddressMatchExplanation={payload.business_incorporation_address_match?.explanation}
              businessOwnerMatches={
                payload.business_owner_matches?.map(
                  (match: { name: string; exact_match: boolean; partial_match: boolean }) => ({
                    name: match.name,
                    match: match.exact_match ? "exact" : match.partial_match ? "soft" : "none",
                  }),
                ) || []
              }
              sources={payload.sources || []}
            />
          )
        ) : null}

        {payload?.type === "ProofOfAddressCheckResult" ? (
          <div className="flex justify-center">
            <KYBProofOfAddressCard
              address={
                payload.verified_address
                  ? {
                      street1: payload.verified_address.street_1,
                      street2: payload.verified_address.street_2,
                      city: payload.verified_address.city,
                      state: payload.verified_address.state,
                      postalCode: payload.verified_address.postal_code,
                      countryCode: payload.verified_address.country_code,
                    }
                  : {
                      street1: "",
                      street2: "",
                      city: "",
                      state: "",
                      postalCode: "",
                      countryCode: "",
                    }
              }
              businessName={
                payload.verified_name ||
                commandResult.business_name ||
                commandResult.input_data.business_name ||
                commandResult.input_data.registered_business_name
              }
              level={level}
              proofOfAddressDocuments={[
                ...(commandResult.check_result?.valid_documents || []),
                ...(commandResult.check_result?.invalid_documents || []),
                ...(commandResult.verified_data?.valid_documents || []),
                ...(commandResult.verified_data?.invalid_documents || []),
                ...(commandResult.verified_data?.proof_of_address_documents || []),
              ]
                ?.filter((entry) => entry.document)
                .map((data) => ({
                  fileName: data.document.file_name,
                  url: `${
                    window.location.protocol
                  }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(data.document.url)}`,
                  documentType: data.document_type,
                  fraudVerificationData: data.fraud_verification_data,
                }))}
              handleDocumentValidationClick={handleDocumentValidationClick}
              checkResult={commandResult}
            />
          </div>
        ) : null}
        {payload?.type === "KYCProofOfAddressCheckResult" ? (
          <div className="flex justify-center">
            <KYCProofOfAddressCard
              address={
                payload.verified_address
                  ? {
                      street1: payload.verified_address.street_1,
                      street2: payload.verified_address.street_2,
                      city: payload.verified_address.city,
                      state: payload.verified_address.state,
                      postalCode: payload.verified_address.postal_code,
                      countryCode: payload.verified_address.country_code,
                    }
                  : {
                      street1: "",
                      street2: "",
                      city: "",
                      state: "",
                      postalCode: "",
                      countryCode: "",
                    }
              }
              name={payload.verified_name}
              level={level}
              title={null}
              proofOfAddressDocuments={[
                ...(commandResult.verified_data?.valid_documents || []),
                ...(commandResult.verified_data?.invalid_documents || []),
                ...(commandResult.verified_data?.proof_of_address_documents || []),
              ]
                .filter((entry) => entry.document)
                .map((data) => ({
                  fileName: data.document.file_name,
                  url: `${
                    window.location.protocol
                  }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(data.document.url)}`,
                  documentType: data.document_type,
                  fraudVerificationData: data.fraud_verification_data,
                }))}
              handleDocumentValidationClick={handleDocumentValidationClick}
              checkResult={commandResult}
            />
          </div>
        ) : null}
        {payload?.type === "KYBIncorporationDocumentVerificationResult" ? (
          <div className="flex justify-center">
            <IncorporationDocument
              level={level}
              address={
                payload.verified_business_address
                  ? {
                      street1: payload.verified_business_address.street_1,
                      street2: payload.verified_business_address.street_2,
                      city: payload.verified_business_address.city,
                      state: payload.verified_business_address.state,
                      postalCode: payload.verified_business_address.postal_code,
                      countryCode: payload.verified_business_address.country_code,
                    }
                  : {
                      street1: "",
                      street2: "",
                      city: "",
                      state: "",
                      postalCode: "",
                      countryCode: "",
                    }
              }
              businessActivity={payload.verified_business_activity}
              companyName={payload.verified_business_name}
              companyNumber={
                payload.verified_business_registration_number || commandResult.input_data.business_registration_number
              }
              incorporationDate={payload.verified_incorporation_date}
              incorporationDocuments={getIncorporationDocuments()}
              handleDocumentValidationClick={handleDocumentValidationClick}
              checkResult={commandResult}
            />
          </div>
        ) : null}
        {payload?.type === "KYBBusinessOwnershipVerificationResult" ? (
          <div className="flex justify-center">
            <OwnershipDocument
              level={level}
              businessOwners={
                payload.verified_business_owners?.map(
                  (owner: { full_name: string; percentage: number | null; shares: number | null }) => ({
                    fullName: owner.full_name,
                    percentage: owner.percentage,
                    shares: owner.shares,
                  }),
                ) || []
              }
              ownershipDocuments={(
                commandResult.verified_data?.valid_documents || commandResult.verified_data?.documented_ownership
              )
                ?.filter((entry) => entry.document)
                .map(
                  (record: {
                    document: { file_name: string; url: string };

                    fraud_verification_data?: FraudVerificationData;
                    summary?: string;
                  }) => ({
                    fileName: record.document.file_name,
                    url: `${
                      window.location.protocol
                    }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(
                      record.document.url,
                    )}`,
                    summary: record.summary,
                    fraudVerificationData: record.fraud_verification_data,
                    isValidDocument: record.is_valid_document,
                    documentType: record.document_type,
                  }),
                )}
              handleDocumentValidationClick={handleDocumentValidationClick}
              checkResult={commandResult}
            />
          </div>
        ) : null}
        {payload?.type === "GovernmentIDCheckResult" ? (
          <div className="flex justify-center">
            <GovernmentIDCard
              name={`${payload.verified_first_name ?? ""}${
                payload.verified_middle_names ? ` ${payload.verified_middle_names}` : ""
              }${payload.verified_last_name ? ` ${payload.verified_last_name}` : ""}
                    `}
              address={
                payload.verified_address
                  ? {
                      street1: payload.verified_address.street_1,
                      street2: payload.verified_address.street_2,
                      city: payload.verified_address.city,
                      state: payload.verified_address.state,
                      postalCode: payload.verified_address.postal_code,
                      countryCode: payload.verified_address.country_code,
                    }
                  : {
                      street1: "",
                      street2: "",
                      city: "",
                      state: "",
                      postalCode: "",
                      countryCode: "",
                    }
              }
              countryOfResidence={payload.verified_country_of_residence}
              dateOfBirth={payload.verified_date_of_birth}
              idType={payload.government_id_type}
              level={level}
              idImageURL={commandResult.verified_data.id_front_image_url}
              evidenceMessage={output.answer}
            />
          </div>
        ) : null}
        {payload?.type === "TINFormatCheckResult" ? (
          <TINCheckCard
            businessName={commandResult.input_data.business_name}
            tin={commandResult.input_data.tin_number}
            level={level}
          />
        ) : null}
        {payload?.type === "HighRiskCountryCheckResult" &&
        payload?.verified_country &&
        payload?.verified_country !== "" ? (
          <ProhibitedHighRiskCountryCard
            level={level}
            countries={[{ name: payload.verified_country, countryCode: payload.verified_country }]}
            evidenceMessage={output.answer}
          />
        ) : null}

        {/* {payload?.type === "IndustryActivityCheckResult" ? (
          <HighRiskIndustryCard
            industry={payload.verified_industry}
            businessActivity={payload.verified_business_activity}
            level={level}
            evidenceMessage={output.explanation}
          />
        ) : null} */}

        {payload?.type === "UDAPCheckResults" ? (
          <div className="flex flex-col gap-5">
            {payload.processed_documents.map((document, index) => {
              return (
                <UDAPDocumentCard
                  key={index}
                  level="failure"
                  pageCount={document.UDAP_results.length}
                  documentTitle={document?.document?.file_name}
                  documentUrl={document?.document?.url}
                  matchingEntries={document.UDAP_results.map((udapResult, index) => {
                    const violations = udapResult.violations.map((violation) => {
                      return {
                        contextText: violation.context_text,
                        triggeredKeyword: violation.triggered_keyword,
                        reason: violation.reason,
                        pageNumber: index + 1,
                        highlightArea: {
                          pageIndex: index,
                          height: (violation.bounding_box?.y_max - violation.bounding_box?.y_min) * 100 + 0.5,
                          width: (violation.bounding_box?.x_max - violation.bounding_box?.x_min) * 100 + 0.75,
                          top: violation.bounding_box?.y_min * 100 - 0.27125,
                          left: violation.bounding_box?.x_min * 100 - 0.4,
                          triggeredKeyword: violation.triggered_keyword,
                          reason: violation.reason,
                          contextText: violation.context_text,
                        },
                      };
                    });
                    return violations;
                  }).flat()}
                />
              );
            })}
          </div>
        ) : null}

        {payload?.type === "MCCCodeCheckResult" ? (
          <MCCCard
            businessDescription={payload.mcc_code_description}
            businessName={commandResult.input_data?.business_name}
            level={didPass ? "success" : "failure"}
            mccCode={payload.mcc_code}
            productsSold={payload.products_sold}
            website={commandResult.input_data?.website ?? null}
          />
        ) : null}

        {payload?.type === "WebResearchDataCheckResult" ? (
          <div className="flex flex-col gap-y-5">
            {payload.self_attested_webpages?.map((webpage, index) => {
              return (
                <WebsiteResearchCard
                  key={index}
                  domain={webpage.webpage_metadata?.domain}
                  isRelatedUrl={webpage.is_relevant}
                  isValidUrl={webpage.is_valid_url}
                  screenshotUrl={webpage.screenshot_url}
                  title={webpage.webpage_title}
                  url={webpage.webpage_url}
                  websiteType={webpage.webpage_metadata?.webpage_type}
                  websiteSummary={webpage.webpage_metadata?.summary}
                />
              );
            })}
            {payload.website_checks?.length > 0 && (
              <div className="flex flex-col gap-y-5 text-xs">
                <span className="font-semibold">Policies</span>
                {payload.website_checks.map((websiteCheck: PolicyType, index: number) => {
                  return <WebsitePolicyCard {...websiteCheck} key={index} />;
                })}
              </div>
            )}
          </div>
        ) : null}

        {payload?.type === "KYBEINDocumentVerificationResult" ? (
          <div className="flex justify-center">
            <EINCard
              addressOfOperation={
                payload.verified_address
                  ? {
                      street1: payload.verified_address.street_1,
                      street2: payload.verified_address.street_2,
                      city: payload.verified_address.city,
                      state: payload.verified_address.state,
                      postalCode: payload.verified_address.postal_code,
                      countryCode: payload.verified_address.country_code,
                    }
                  : {
                      street1: null,
                      street2: null,
                      city: null,
                      state: null,
                      postalCode: null,
                      countryCode: null,
                    }
              }
              businessName={payload.verified_business_name}
              documents={payload.valid_documents || payload.invalid_documents}
              level={level}
              einNumber={payload.verified_ein}
              handleDocumentValidationClick={handleDocumentValidationClick}
              checkResult={commandResult}
            />
          </div>
        ) : null}

        {payload?.type === "CannabisLicenseVerificationResult" ? (
          <div className="flex justify-center">
            <CannabisLicenseCard
              level={level}
              verifiedBusinessName={payload.verified_business_name}
              verifiedLicenseNumber={payload.verified_license_number}
              documentDate={payload.document_date}
              validDocuments={payload.valid_documents?.map((entry) => ({
                fileName: entry.document?.file_name || "Not available",
                url: entry.document?.url
                  ? `${
                      window.location.protocol
                    }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(
                      entry.document.url,
                    )}`
                  : null,
                expirationDate: entry.expiration_date,
                licenseNumber: entry.license_number,
                visualInspection: entry.inspection_details,
                isValidDocument: entry.is_valid_document,
                documentType: entry.document_type,
              }))}
              invalidDocuments={payload.invalid_documents?.map((entry) => ({
                fileName: entry.document?.file_name || "Not available",
                url: entry.document?.url
                  ? `${
                      window.location.protocol
                    }//${endpointUrl}/getDocument?case_id=${caseId}&expired_url=${encodeURIComponent(
                      entry.document.url,
                    )}`
                  : null,
                summary: entry.summary,
                expirationDate: entry.expiration_date,
                licenseNumber: entry.license_number,
                visualInspection: entry.inspection_details,
                isValidDocument: entry.is_valid_document,
                documentType: entry.document_type,
              }))}
              handleDocumentValidationClick={handleDocumentValidationClick}
              checkResult={commandResult}
            />
          </div>
        ) : null}

        {payload?.type === "KYBSourceOfFundsDocumentVerificationResult" ? (
          <div className="flex justify-center">
            <SourceOfFundsCard
              level={level}
              amount={payload.verified_amount}
              businessName={payload.verified_business_name}
              documents={payload.verified_source_of_funds_documents}
              handleDocumentValidationClick={handleDocumentValidationClick}
              checkResult={commandResult}
            />
          </div>
        ) : null}

        {payload?.type === "KYCAdverseMediaScreeningCheckResultV2" && commandResult?.input_data
          ? renderAdverseMedia(
              payload.verified_adverse_media_hits || payload.perpetrator_profiles,
              commandResult.input_data,
              commandResultMessage.content.agent_key,
              commandResultMessage.content.command_id,
              commandResultMessage.content.agent_instance_id,
              commandResultMessage.content.command_instance_id,
              showAllContent,
            )
          : null}

        {payload?.type === "KYCAdverseMediaEventScreeningCheckData" && payload?.potential_matches?.length > 0 ? (
          <div className="mt-4 flex flex-col gap-y-3">
            {payload.potential_matches.map((potentialMatch: any, index: number) => {
              return <AdverseMediaPotentialHitV2 key={index} {...potentialMatch} />;
            })}
          </div>
        ) : null}

        {commandResultMessage?.content?.output?.payload?.type === "PEPScreeningCheckResultV2" &&
        commandResultMessage?.content?.output?.payload?.verified_pep_hits?.length > 0 &&
        commandResult?.input_data
          ? renderPEP(
              commandResultMessage.content.output.payload.verified_pep_hits,
              commandResult?.input_data,
              commandResultMessage.content.agent_key,
              commandResultMessage.content.command_id,
              commandResultMessage.content.agent_instance_id,
              commandResultMessage.content.command_instance_id,
              showAllContent,
            )
          : null}

        {payload?.type === "BasicBusinessProfileCheckResult" && (
          <BusinessBasicProfile
            checkResult={{
              verified_data: commandResult.verified_data,
              metadata: { business_name: commandResult.input_data?.business_name },
              status: "complete",
            }}
            hideHeader={!apiKey}
          />
        )}

        {payload?.type === "WebPresenceCheckResult" && (
          <WebPresenceList
            checkResult={{
              verified_data: commandResult.verified_data,
              metadata: { business_name: commandResult.input_data?.business_name },
              status: "complete",
            }}
            hideHeader={!apiKey}
          />
        )}

        {payload?.type === "AddressesCheckResult" && (
          <AddressesFindings
            checkResult={{
              verified_data: commandResult.verified_data,
              metadata: { business_name: commandResult.input_data?.business_name },
              status: "complete",
            }}
            hideHeader={!apiKey}
          />
        )}

        {payload?.type === "PoliciesCheckResult" && (
          <PoliciesFindings
            checkResult={{
              check_result: {
                policies_found: payload.policies_found,
              },
              metadata: { business_name: commandResult.input_data?.business_name },
              status: "complete",
            }}
            endpointUrl={endpointUrl}
            apiKey={apiKey}
            hideHeader={!apiKey}
          />
        )}

        {payload?.type === "BusinessOwnersCheckResult" && (
          <BusinessOwnersFindings
            checkResult={{
              metadata: { business_name: commandResult.input_data?.business_name },
              status: "complete",
              check_result: payload,
            }}
            endpointUrl={endpointUrl}
            apiKey={apiKey}
            hideHeader={!apiKey}
          />
        )}

        {payload?.type === "HighRiskCountryCheckResultV2" && (
          <HighRiskCountryFindings
            checkResult={{
              check_result: payload,
              metadata: { business_name: commandResult.input_data?.business_name },
              status: "complete",
              passed: true,
            }}
            hideHeader={!apiKey}
          />
        )}

        {/* {payload?.type === "IndustryActivityCheckResultV2" && (
          <HighRiskIndustryFindings
            checkResult={{
              check_result: payload,
              metadata: { business_name: commandResult.input_data?.business_name },
              status: "complete",
            }}
            hideHeader={!apiKey}
          />
        )} */}

        {payload?.type === "BusinessAddressVerificationResult" && (
          <SelfAttestedAddressVerificationCard
            address={payload.address}
            business_name={payload.found_entity_name}
            address_type={payload.address_type}
            found_website={payload.found_entity_website}
            passed={commandResult.passed}
            explanation={commandResult.answer}
          />
        )}

        {payload?.type === "CannabisRegistrationDecisionMetadataResult" &&
        payload.cannabis_registration_hits?.length > 0 ? (
          <div className="flex flex-col gap-y-4">
            {payload.cannabis_registration_hits.map((hit, index) => (
              <CannabisRegistrationCard
                key={index}
                level={
                  hit.business_name_match && hit.state_match && hit.country_match && hit.is_active
                    ? "success"
                    : "failure"
                }
                name={hit.business_name}
                licenseId={hit.license_id}
                cnbId={hit.cnb_id}
                status={hit.status}
                activities={hit.activities || []}
                issuedDate={hit.issued_date}
                expirationDate={hit.expiration_date}
                lastUpdated={hit.last_updated}
                address={hit.address}
                cnbUrl={hit.cnb_url}
                companyName={hit.company_name}
                companyId={hit.company_id}
                country={hit.country}
                state={hit.state}
                handleDocumentValidationClick={handleDocumentValidationClick}
                checkResult={checkResult}
              />
            ))}
          </div>
        ) : null}

        {payload?.type === "KYCSourceOfWealthCheckResultV2" && payload?.profiles?.length > 0 ? (
          <div className="flex justify-center w-full">
            <div className="flex flex-col w-full">
              {/* <div className="flex items-center justify-between bg-white rounded-lg w-full mb-4">
                <div className="flex items-center space-x-2">
                  <img src={BusinessCenterIcon} width={20} height={20} className="h-5 w-5" />
                  <div className="text-sm font-medium text-slate-900">Sources of Income</div>
                </div>
              </div> */}

              {payload?.profiles?.map((profile, index) => (
                <div key={index} className="border border-slate-200 rounded-lg mb-3">
                  <RiskCardV3
                    type="source_of_wealth"
                    key={`source-of-wealth-hit-${profile.id}-${index}`}
                    hit={profile}
                    input={commandResult.input_data}
                    handleSubmitFeedback={handleSubmitFeedback}
                    feedback={groupedByWorldCheckId[profile.reference_id ?? profile.id ?? ""]}
                    expandByDefault={index === 0}
                    isLoadingFeedback={isLoadingFeedback}
                    renderContent={() => (
                      <div className="flex flex-col">
                        {/* Profile Details */}
                        <div className="flex justify-between items-center gap-x-10 mb-4">
                          {profile.source_of_wealth_estimation &&
                            profile.source_of_wealth_estimation.estimation_reasoning && (
                              <div className="text-xs text-slate-700 bg-slate-100 p-5 border-b-0 rounded-lg w-2/3">
                                {profile.source_of_wealth_estimation.estimation_reasoning}
                              </div>
                            )}
                          {profile.source_of_wealth_estimation &&
                            profile.source_of_wealth_estimation.current_annual_salary_low != null &&
                            profile.source_of_wealth_estimation.current_annual_salary_high != null && (
                              <div className="text-sm text-slate-700 w-1/3">
                                <div className="flex items-center font-semibold justify-end">
                                  Annual Salary:{" "}
                                  <span className="text-green-700 ml-1">
                                    {formatNumberToCurrency(
                                      profile.source_of_wealth_estimation.current_annual_salary_low,
                                    )}{" "}
                                    -{" "}
                                    {formatNumberToCurrency(
                                      profile.source_of_wealth_estimation.current_annual_salary_high,
                                    )}
                                  </span>
                                </div>
                              </div>
                            )}
                        </div>
                        <div className="border-t border-slate-200 mb-5">
                          {profile.source_of_wealth_estimation?.working_experiences_with_wealth?.map((entry) => (
                            <LinkedInExperienceEntryV2 key={entry.title} {...entry} />
                          ))}
                        </div>

                        {profile.linkedin_profile_url && (
                          <>
                            <h4 className="text-sm font-medium text-slate-900 mt-9 mb-4">Sources</h4>
                            <div className="flex flex-col gap-y-2">
                              {profile.source_of_wealth_estimation?.total_sources?.map((source, index) => (
                                <SourceItem
                                  key={`source-${index}`}
                                  url={source.url}
                                  sourceName={source.url}
                                  title={source.title}
                                  index={source.index}
                                />
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                    )}
                    showAllContent={showAllContent}
                    showVendorId={false}
                    greenColor={true}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : null}

        {payload?.type === "KYCRealEstatePropertyCheckResult" ? (
          <div className="flex justify-center w-full">
            <div className="flex flex-col w-full">
              {/* <div className="flex items-center justify-between bg-white rounded-lg w-full mb-4">
                <div className="flex items-center space-x-2">
                  <img src={BuildingIcon} width={20} height={20} className="h-5 w-5" />
                  <div className="text-sm font-medium text-slate-900">Real Estate</div>
                </div>
              </div> */}

              {payload?.profiles?.map((profile, index) => (
                <div key={index} className="rounded-lg mb-3">
                  <RealEstateCard
                    key={`source-of-wealth-hit-${profile.id}-${index}`}
                    hit={profile}
                    input={commandResult.input_data}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : null}

        {payload?.type === "KYCOpenCorporatesBusinessCheckResult" ? (
          <div className="flex justify-center w-full">
            <div className="flex flex-col w-full">
              {/* <div className="flex items-center justify-between bg-white rounded-lg w-full mb-4">
                <div className="flex items-center space-x-2">
                  <img src={BuildingIcon} width={20} height={20} className="h-5 w-5" />
                  <div className="text-sm font-medium text-slate-900">Open Corporates Officers</div>
                </div>
              </div> */}

              {payload?.profiles?.map((profile, index) => (
                <div key={index} className="rounded-lg mb-3">
                  <SourceOfWealthOpenCorporatesOfficersCard
                    key={`source-of-wealth-hit-${profile.id}-${index}`}
                    hit={profile}
                    input={commandResult.input_data}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : null}

        {payload?.type === "KYCSourceOfWealthCheckResult" ? (
          <div className="flex justify-center w-full">
            <div className="flex flex-col w-full">
              <div className="flex items-center justify-between bg-white rounded-lg w-full mb-4">
                <div className="flex items-center space-x-2">
                  <img src={BusinessCenterIcon} width={20} height={20} className="h-5 w-5" />
                  <div className="text-sm font-medium text-slate-900">Sources of Income</div>
                </div>
              </div>

              <div className="flex flex-col border-b border-slate-200 pb-3">
                {payload?.estimation_confidence === "high" ? (
                  <div className="flex justify-between items-start gap-x-10">
                    {payload?.estimation_reasoning && (
                      <div className="text-xs text-slate-700 bg-slate-100 p-5 rounded-lg w-2/3">
                        {payload.estimation_reasoning}
                      </div>
                    )}
                    <div className="text-right flex flex-col justify-center h-full w-1/3">
                      {payload?.wealth_low != null && payload?.wealth_high != null && (
                        <div className="mb-1">
                          <div className="text-sm text-slate-700 font-semibold">
                            Total Earnings:{" "}
                            <span className="text-sm text-green-700">
                              {formatNumberToCurrency(payload?.wealth_low)} -{" "}
                              {formatNumberToCurrency(payload?.wealth_high)}
                            </span>
                          </div>
                        </div>
                      )}
                      {payload?.current_annual_salary_low != null && payload?.current_annual_salary_high != null && (
                        <div className="mb-2">
                          <div className="text-sm text-slate-700 font-normal">
                            Annual Salary:{" "}
                            <span className="text-sm text-green-700">
                              {formatNumberToCurrency(payload?.current_annual_salary_low)} -{" "}
                              {formatNumberToCurrency(payload?.current_annual_salary_high)}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : payload?.estimation_confidence === "low" &&
                  payload?.working_experiences_with_wealth?.length === 0 ? (
                  <div className="flex items-center gap-4 p-4 bg-red-50 rounded-xl">
                    <div className="text-red-700">
                      <h3 className="text-sm font-semibold">Limited confidence profile</h3>
                      <p className="text-xs">{payload?.estimation_reasoning}</p>
                    </div>
                  </div>
                ) : payload?.estimation_confidence === "low" && payload?.working_experiences_with_wealth?.length > 0 ? (
                  <div className="flex justify-between items-start gap-x-10">
                    {payload?.estimation_reasoning && (
                      <div className="flex items-center gap-4 p-4 bg-red-50 rounded-xl w-2/3">
                        <div className="text-red-700">
                          <h3 className="text-sm font-semibold">Limited confidence profile</h3>
                          <p className="text-xs">{payload?.estimation_reasoning}</p>
                        </div>
                      </div>
                    )}
                    <div className="text-right flex flex-col justify-center h-full w-1/3">
                      {payload?.wealth_low != null && payload?.wealth_high != null && (
                        <div className="mb-1">
                          <div className="text-sm text-slate-700 font-semibold">
                            Total Earnings:{" "}
                            <span className="text-sm text-green-700">
                              {formatNumberToCurrency(payload?.wealth_low)} -{" "}
                              {formatNumberToCurrency(payload?.wealth_high)}
                            </span>
                          </div>
                        </div>
                      )}
                      {payload?.current_annual_salary_low != null && payload?.current_annual_salary_high != null && (
                        <div className="mb-2">
                          <div className="text-sm text-slate-700 font-normal">
                            Annual Salary:{" "}
                            <span className="text-sm text-green-700">
                              {formatNumberToCurrency(payload?.current_annual_salary_low)} -{" "}
                              {formatNumberToCurrency(payload?.current_annual_salary_high)}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>

              {payload?.working_experiences_with_wealth?.map((entry) => (
                <LinkedInExperienceEntry key={entry.title} {...entry} />
              ))}

              {payload.sources && payload.sources.length > 0 && (
                <>
                  <h4 className="text-sm font-medium text-slate-900 mt-5">Sources</h4>
                  <div className="flex flex-col mt-4 gap-y-3">
                    {payload?.sources?.map((entry) => (
                      <SourceItem
                        key={entry.url ?? "xxx"}
                        url={entry.url ?? undefined}
                        sourceName={entry.url ?? undefined}
                        title={entry.url ?? undefined}
                      />
                    ))}
                  </div>
                </>
              )}

              {/*{payload?.sources && payload.sources.length > 0 && (
                <div className="flex flex-row gap-x-4 overflow-x-auto py-2">
                  {payload.sources.map((source, index) => (
                    <div key={index} className="flex-none w-80 bg-slate-50 rounded-lg p-4">
                      <div className="flex items-center mb-4">
                        {source.source_type === "linkedin_profile_link" ? (  
                          <img src={linkedInLogoIcon} alt="LinkedIn" className="w-4 h-4 mr-2" />
                        ) : source.source_type === "facebook_profile_link" ? (
                          <img src={facebookLogo} alt="Facebook" className="w-4 h-4 mr-2" />
                        ) : (
                          <div className="text-xs font-semibold text-red-700 mr-2">Partial Match</div>
                        )}
                        <a 
                          href={source.url} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className="text-xs text-slate-900 hover:text-brand-purple hover:underline max-w-[200px] truncate block"
                        >
                          {source.url}
                        </a>
                      </div>
                      <div className="flex flex-col bg-slate-100 rounded-lg p-3">
                        <div className="flex items-center">
                          {source.hit_match.match === "weak_match" ? (  
                            <div className="text-xs font-semibold text-yellow-700 mr-2">Weak Match</div>
                          ) : source.hit_match.match === "strong_match" ? (
                            <div className="text-xs font-semibold text-green-700 mr-2">Strong Match</div>
                          ) : (
                            <div className="text-xs font-semibold text-red-700 mr-2">Partial Match</div>
                          )}
                          <StrengthIndicator matchRating={source.hit_match.match} hideLabel={true} />
                        </div>
                        <div className="text-xs text-slate-500 mt-1">{source.hit_match.reason}</div>
                      </div>
                    </div>
                  ))}
                </div>
              )} */}
            </div>
          </div>
        ) : null}

        {payload?.type === "CheckDocumentCheckResult" ? (
          <div className="mt-5">{renderBankCheckCard(payload, commandResult.input_data, commandResult)}</div>
        ) : null}

        {!hideFeedbackControls && (
          <div className="flex flex-col gap-y-5 print:hidden">
            <div className="w-full flex justify-between">
              <div className="flex gap-x-3 items-center">
                <ChecksFeedback
                  key={feedbackKey}
                  score={
                    activeFeedbackId === feedbackKey
                      ? false
                      : feedbackData?.find((feedback) => feedback.key === feedbackKey)?.score
                  }
                  onThumbsDownClick={() => onThumbsDownClickHandler(feedbackKey)}
                  onThumbsUpClick={() => onThumbsUpClickHandler(commandResultMessage)}
                />
              </div>
              <div>
                {isLegacyJob ? (
                  <button
                    type="button"
                    className="inline-flex items-center gap-x-1 rounded-md border-solid border-slate-300 border bg-white px-3 py-1.5 text-xs font-medium text-slate-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple"
                    onClick={() => handleViewInAuditLogClick(commandResultMessage)}
                  >
                    View In Audit Log
                    <ArrowRightAlt className="h-4 w-4" aria-hidden="true" />
                  </button>
                ) : auditLogVisible ? (
                  <button
                    type="button"
                    className="inline-flex items-center gap-x-1 rounded-md border-solid border-slate-300 border bg-white px-3 py-3 text-xs font-medium text-slate-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple"
                    onClick={() => setIsAuditLogExpanded((prev) => !prev)}
                  >
                    {isAuditLogExpanded ? "Hide Audit Log" : "View Audit Log"}
                    {isAuditLogExpanded ? (
                      <ArrowUpward sx={{ fontSize: "1rem" }} className="h-4 w-4" aria-hidden="true" />
                    ) : (
                      <ArrowDownward sx={{ fontSize: "1rem" }} className="h-4 w-4" aria-hidden="true" />
                    )}
                  </button>
                ) : null}
              </div>
            </div>
            {activeFeedbackId === feedbackKey && (
              <form onSubmit={(e) => handleSubmitFeedback(e, commandResultMessage)}>
                <div className="flex flex-col gap-y-5 text-sm">
                  <input
                    type="text"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    className="flex-grow flex-shrink-0 flex-basis-0 flex items-center self-stretch box-border border border-solid border-slate-300 rounded-md w-full p-3 placeholder:text-sm placeholder:text-slate-400 text-slate-900 text-sm"
                    placeholder="Please provide feedback on how we can improve..."
                  />
                  {commentError && <div className="text-red-500">{commentError}</div>}
                  <button
                    className="self-end bg-brand-purple text-white py-1.5 px-1 rounded disabled:cursor-not-allowed disabled:bg-slate-300 disabled:text-slate-900"
                    type="submit"
                    disabled={
                      feedbackData?.find((feedback) => feedback.key === activeFeedbackId)?.comment !== null &&
                      feedbackData?.find((feedback) => feedback.key === activeFeedbackId)?.comment === comment
                    }
                  >
                    Send Feedback
                  </button>
                </div>
              </form>
            )}
            {isAuditLogExpanded ? (
              <ChatContent
                isParchaUser={false}
                jobStatus={agentRun.status}
                statusMessages={auditLogMessages}
                feedbackData={feedbackData}
                endpointUrl={endpointUrl}
                caseId={agentRun.input_payload.id}
                showAllContent={showAllContent}
              />
            ) : null}
          </div>
        )}
      </div>
    );
  };

  const renderBankCheckCard = (
    payload: { valid_documents: any[]; invalid_documents: any[] },
    input: any,
    checkResult: any,
  ) => {
    const mergedDocuments = [...payload.valid_documents, ...payload.invalid_documents];
    return mergedDocuments.map((document, index) => (
      <BankCheckCard
        key={index}
        {...document}
        input={input}
        checkResult={checkResult}
        handleDocumentValidationClick={handleDocumentValidationClick}
        endpointUrl={endpointUrl}
        caseId={agentRun.input_payload.id}
      />
    ));
  };

  const handleViewInAuditLogClick = (commandResultMessage: StatusMessage) => {
    const elementId = `${commandResultMessage?.content.agent_key}${commandResultMessage?.timestamp}`;
    const element = document.getElementById(elementId);
    const container = document.getElementById("audit-log-container");

    if (element && container) {
      container.scrollTo({
        top: element.offsetTop - 116,
        behavior: "smooth",
      });
    } else {
      setSelectedSidePanelTabValue("audit_log");
      setTimeout(() => {
        const element = document.getElementById(
          `${commandResultMessage?.content.agent_key}${commandResultMessage?.timestamp}`,
        );
        const container = document.getElementById("audit-log-container");
        container.scrollTo({
          top: element.offsetTop - 134,
          behavior: "smooth",
        });
      }, 500);
    }
  };

  const onContactSupportClickHandler = (errorMsg: ErrorMsg | undefined) => {
    const subject = `Bug report: ${errorMsg?.error_type} (${errorMsg?.error_display})`;
    const body = errorMsg?.full_error_message;
    const mailtoLink = `mailto:support@parcha.ai?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
      body || "no content in the error body...",
    )}`;
    window.open(mailtoLink, "_blank");
  };

  const handleSubmitFeedback = async (e: React.FormEvent, commandResult: CommandResult) => {
    e.preventDefault();

    if (!comment.trim()) {
      setCommentError("Please provide a comment");
      return;
    }

    await sendFeedbackHandler(commandResult, false, comment);
    setActiveFeedbackId(null);
    setComment(null);
  };

  const onThumbsDownClickHandler = (feedbackKey: string) => {
    if (feedbackKey === activeFeedbackId) {
      return;
    }

    setActiveFeedbackId(feedbackKey);
    setComment(null);
    setCommentError(null);
  };

  const onThumbsUpClickHandler = async (commandResult: CommandResult) => {
    await sendFeedbackHandler(commandResult, true);
    setActiveFeedbackId(null);
    setComment(null);
  };

  return (
    <div key={commandInstanceId} className={`${compact ? "p-1" : "p-5 border border-slate-200"} rounded-lg`}>
      <div className="w-full flex flex-col justify-between items-center">
        <Accordion
          expanded={isExpanded}
          title={renderAccordionTitle(messages)}
          main={renderAccordionMain(messages)}
          content={renderAccordionContent(messages)}
          preventExpandCollapse={!commandResult}
          compact={compact}
        />
      </div>
    </div>
  );
};

const LinkedInExperienceEntry = ({
  title,
  company,
  location,
  start,
  end,
  duration,
  salary_explanation,
  annual_salary_high,
  annual_salary_low,
  total_earnings_high,
  total_earnings_low,
  source_url,
  source_type,
  hit_match,
}: LinkedInExperienceEntryProps) => {
  return (
    <div className="flex flex-col gap-y-1 text-slate-900 py-5 border-b border-slate-200">
      <div className="flex justify-between items-start">
        <div className="gap-y-1 w-2/3">
          <div className="text-sm font-semibold inline-flex items-center">
            {title}
            <span className="ml-1 text-xs font-normal text-slate-700">{company}</span>
            {source_url &&
              source_type &&
              (source_type === "linkedin_profile_link" ? (
                <a href={source_url} target="_blank" rel="noopener noreferrer" className="ml-3">
                  <img src={linkedInLogoIcon} alt="LinkedIn" className="w-3 h-3" />
                </a>
              ) : null)}
          </div>
          <div className="text-xs font-normal text-slate-500">
            {start && end
              ? `${start} - ${end}${duration ? ` • ${duration}` : ""}${location ? ` • ${location}` : ""}`
              : duration
                ? `${duration}`
                : null}
          </div>
          <div className="mt-2 text-xs font-normal text-slate-700">{salary_explanation}</div>
        </div>

        <div className="text-right flex flex-col justify-center h-full w-1/3">
          {total_earnings_low != null && total_earnings_high != null && (
            <div className="mb-1">
              <div className="text-xs text-slate-700 font-semibold">
                Total Earnings:{" "}
                <span className="text-xs text-green-700">
                  {formatNumberToCurrency(total_earnings_low)} - {formatNumberToCurrency(total_earnings_high)}
                </span>
              </div>
            </div>
          )}
          {annual_salary_low != null && annual_salary_high != null && (
            <div className="mb-2">
              <div className="text-xs text-slate-700 font-normal">
                Annual Salary:{" "}
                <span className="text-xs text-green-700">
                  {formatNumberToCurrency(annual_salary_low)} - {formatNumberToCurrency(annual_salary_high)}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const LinkedInExperienceEntryV2 = ({
  title,
  company,
  company_logo_url,
  location,
  start,
  end,
  duration,
  salary_explanation,
  annual_salary_high,
  annual_salary_low,
  total_earnings_high,
  total_earnings_low,
  source_url,
  source_type,
  hit_match,
}: LinkedInExperienceEntryV2Props) => {
  return (
    <div className="flex flex-col text-slate-900 py-5 border-b border-slate-200">
      <div className="flex justify-between">
        <img
          src={company_logo_url || defaultCompanyLogo}
          alt={`${company} logo`}
          className="w-10 h-10 mr-3"
          style={{ borderRadius: "4px" }}
        />
        <div className="items-start w-2/3 gap-y-0">
          <div className="text-sm font-semibold inline-flex items-center">
            {title}
            <span className="ml-1 text-xs font-normal text-slate-700">at {company}</span>
            {source_url &&
              source_type &&
              (source_type === "linkedin_profile_link" ? (
                <a href={source_url} target="_blank" rel="noopener noreferrer" className="ml-3">
                  <img src={linkedInLogoIcon} alt="LinkedIn" className="w-3 h-3" />
                </a>
              ) : null)}
          </div>
          <div className="text-xs font-normal text-slate-500">
            {start && end
              ? `${start} - ${end}${duration ? ` • ${duration}` : ""}${location ? ` • ${location}` : ""}`
              : duration
                ? `${duration}`
                : null}
          </div>
          <div className="mt-2 text-xs font-normal text-slate-700">{salary_explanation}</div>
        </div>

        <div className="text-right items-center w-1/3">
          {annual_salary_low != null && annual_salary_high != null && (
            <div className="text-xs text-slate-700 font-normal">
              Annual Salary:{" "}
              <span className="text-xs text-green-700">
                {formatNumberToCurrency(annual_salary_low)} - {formatNumberToCurrency(annual_salary_high)}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckContainer;
