import { useContext, useEffect } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupIcon from "@mui/icons-material/Group";
import ApiIcon from "@mui/icons-material/Api";
import BusinessIcon from "@mui/icons-material/Business";
import ExtensionIcon from "@mui/icons-material/Extension";
import { styled } from "@mui/material/styles";
import UserContext from "@/contexts/UserContext";

const LeftAlignedTab = styled(Tab)(({ theme }) => ({
  minHeight: 48,
  padding: theme.spacing(1, 2),
  justifyContent: "flex-start",
  "& .MuiTab-wrapper": {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  "& .MuiSvgIcon-root": {
    marginRight: theme.spacing(1),
    marginBottom: 0,
  },
}));

export default function AdminView() {
  const location = useLocation();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { hasRole } = userContext;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    const tabs = getTabs();
    if (tabs[newValue]) {
      navigate(`/admin/${tabs[newValue].value}`);
    }
  };

  const getTabs = () => {
    const tabs = [];
    if (hasRole("admin")) {
      tabs.push({
        value: "users",
        label: "Users",
        icon: <GroupIcon />,
      });
    }
    if (hasRole("developer")) {
      tabs.push(
        {
          value: "apiKeys",
          label: "API Keys",
          icon: <ApiIcon />,
        },
        {
          value: "integrations",
          label: "Integrations",
          icon: <ExtensionIcon />,
        },
      );
    }
    if (hasRole("parchaAdmin")) {
      tabs.push({
        value: "tenants",
        label: "Tenants",
        icon: <BusinessIcon />,
      });
    }
    return tabs;
  };

  // Determine which tab to show initially based on permissions
  useEffect(() => {
    if (location.pathname === "/admin") {
      if (hasRole("admin")) {
        navigate("/admin/users");
      } else if (hasRole("developer")) {
        navigate("/admin/apiKeys");
      } else if (hasRole("parchaAdmin")) {
        navigate("/admin/tenants");
      }
    }
  }, [hasRole, location.pathname, navigate]);

  const tabs = getTabs();
  const selectedTab = tabs.findIndex((tab) => tab.value === location.pathname.split("/")[2]);
  const defaultTab = selectedTab === -1 ? 0 : selectedTab;

  return (
    <Box className="flex h-full max-w-[1400px]">
      <Box className="w-64 border-r border-slate-200 flex-shrink-0">
        <Box className="border-b border-slate-200">
          <Box className="flex items-center px-4 py-3 h-[3.75rem]">
            <Box className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center mr-2">
              <SettingsIcon className="text-blue-500" fontSize="small" />
            </Box>
            <Typography variant="h6" className="text-gray-700">
              Settings
            </Typography>
          </Box>
        </Box>
        <Tabs
          orientation="vertical"
          value={defaultTab}
          onChange={handleTabChange}
          aria-label="Admin Tabs"
          className="h-full w-full"
          sx={{
            "& .MuiTabs-indicator": {
              right: 0,
              left: "auto",
            },
          }}
        >
          {tabs.map((tab, index) => (
            <LeftAlignedTab
              key={tab.value}
              value={index}
              icon={tab.icon}
              label={<span style={{ textTransform: "uppercase", fontSize: "0.875rem" }}>{tab.label}</span>}
              iconPosition="start"
            />
          ))}
        </Tabs>
      </Box>
      <Box className="flex-grow overflow-auto">
        <Box p={4} className="h-full">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
