import React from "react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { ShieldCheckIcon, ShieldExclamationIcon } from "@heroicons/react/24/outline";
import DocumentValue from "./DocumentValue";
import { WarningRounded } from "@mui/icons-material";

interface Address {
  street1: string | null;
  street2: string | null;
  city: string | null;
  state: string | null;
  countryCode: string | null;
  postalCode: string | null;
}

interface AllExtractedAddresses {
  principal_office_address: Address;
  mailing_address: Address;
  registered_agent_address: Address;
}

interface VisualInspectionDetail {
  page?: number;
  present: boolean;
  valid: boolean;
  passed: boolean;
  matches_reference: boolean | null;
  confidence: number;
  analysis: string;
  summary: string;
  visual_description: string;
  visual_description_reference: string;
  inspection_summary: string;
  seal_verification: VisualInspectionDetail;
  signature_verification: VisualInspectionDetail;
}

interface VisualInspection {
  official_seal: VisualInspectionDetail;
  official_signature: VisualInspectionDetail;
  inspection_passed: boolean;
  visual_analysis: VisualInspectionDetail;
}

interface ExtractionData {
  is_valid_document: boolean;
  analysis: string;
  summary: string;
  date: string;
  alerts: Record<string, unknown>;
  business_name: string;
  all_business_names: string[];
  incorporation_date: string;
  business_address: Address;
  all_extracted_addresses: AllExtractedAddresses;
  business_activity: string;
  jurisdiction: string;
  business_registration_number: string[];
}

interface IncorporationDocument {
  extraction_data: ExtractionData;
  fraud_verification_data: any;
  visual_inspection: VisualInspection;
  document: {
    url: string;
    file_name: string;
    description: string | null;
    source_type: string;
    num_pages: number | null;
  };
}

export interface KYBIncorporationDocumentsExtractionData {
  type: "KYBIncorporationDocumentsExtractionData";
  valid_documents: IncorporationDocument[];
  invalid_documents: IncorporationDocument[];
}

interface IncorporationDocumentExtractionCardProps {
  data: KYBIncorporationDocumentsExtractionData;
  handleDocumentValidationClick?: (checkResult: any, document: any) => void;
  checkResult?: any;
}

const AddressDisplay: React.FC<{ address: Address; label: string }> = ({ address, label }) => {
  if (!address) return null;

  const hasAddressData = Object.values(address).some((value) => value !== null);
  if (!hasAddressData) return null;

  return (
    <div className="mt-4">
      <span className="font-semibold">{label}</span>
      <div className="mt-1 space-y-0.5">
        {address.street1 && <div>{address.street1}</div>}
        {address.street2 && <div>{address.street2}</div>}
        <div>{[address.city, address.state, address.postalCode].filter(Boolean).join(", ")}</div>
        {address.countryCode && <div>{address.countryCode}</div>}
      </div>
    </div>
  );
};

const VisualInspectionDetail: React.FC<{ detail: VisualInspectionDetail; title: string }> = ({ detail, title }) => {
  return (
    <div className="mt-4">
      <h4 className=" text-slate-500">{title}</h4>
      <div className="mt-2">
        <div className="flex items-center gap-2">
          {detail?.valid || detail?.passed ? (
            <CheckCircleIcon className="h-4 w-4 text-emerald-500" />
          ) : (
            <XCircleIcon className="h-4 w-4 text-red-500" />
          )}
          <span className=" text-slate-700">
            {detail?.present ? "Present" : "Not present"} {detail?.page && `(Page ${detail?.page})`}
          </span>
        </div>
        <p className="mt-1  text-slate-500">{detail?.analysis}</p>
      </div>
    </div>
  );
};

const DocumentSection: React.FC<{
  document: IncorporationDocument;
  handleDocumentValidationClick?: (checkResult: any, document: any) => void;
  checkResult?: any;
}> = ({ document, handleDocumentValidationClick, checkResult }) => {
  const { extraction_data, visual_inspection, fraud_verification_data } = document;

  const renderVisualInspection = () => {
    if (visual_inspection.visual_analysis) {
      return (
        <div className="mt-4 border-t border-slate-200 pt-4">
          <h4 className="font-semibold text-slate-900">Visual Inspection</h4>
          <VisualInspectionDetail detail={visual_inspection.visual_analysis.seal_verification} title="Official Seal" />
          <VisualInspectionDetail
            detail={visual_inspection.visual_analysis.signature_verification}
            title="Official Signature"
          />
        </div>
      );
    } else if (visual_inspection.official_seal) {
      return (
        <div className="mt-4 border-t border-slate-200 pt-4">
          <h4 className="font-semibold text-slate-900">Visual Inspection</h4>
          <VisualInspectionDetail detail={visual_inspection.official_seal} title="Official Seal" />
          <VisualInspectionDetail detail={visual_inspection.official_signature} title="Official Signature" />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="text-xs text-slate-900bg-white rounded-lg border border-slate-200/75 shadow-[0_1px_3px_0_rgb(0,0,0,0.05)] p-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className="min-w-0">
            <p className="text-sm font-semibold truncate">{extraction_data.business_name}</p>
            <DocumentValue
              fileName={document.document.file_name}
              url={document.document.url}
              handleDocumentValidationClick={handleDocumentValidationClick}
              checkResult={checkResult}
            />
          </div>
        </div>
        {extraction_data.is_valid_document ? (
          <ShieldCheckIcon className="h-5 w-5 text-emerald-500 flex-shrink-0" />
        ) : (
          <ShieldExclamationIcon className="h-5 w-5 text-red-500 flex-shrink-0" />
        )}
      </div>

      <div className="mt-6">
        <div className="grid grid-cols-2 gap-x-6 gap-y-4 ">
          <div>
            <span className="font-semibold">Incorporation Date</span>
            <p className="mt-1 text-slate-700">{extraction_data.incorporation_date}</p>
          </div>
          <div>
            <span className="font-semibold">Jurisdiction</span>
            <p className="mt-1 text-slate-700">{extraction_data.jurisdiction}</p>
          </div>
          <div>
            <span className="font-semibold">Business Activity</span>
            <p className="mt-1 text-slate-700">{extraction_data.business_activity}</p>
          </div>
          <div>
            <span className="font-semibold">Registration Numbers</span>
            <p className="mt-1 text-slate-700">{extraction_data.business_registration_number?.join(", ")}</p>
          </div>
        </div>

        <AddressDisplay address={extraction_data.business_address} label="Business Address" />

        {extraction_data.all_extracted_addresses && (
          <>
            <AddressDisplay
              address={extraction_data.all_extracted_addresses.principal_office_address}
              label="Principal Office"
            />
            <AddressDisplay address={extraction_data.all_extracted_addresses.mailing_address} label="Mailing Address" />
            <AddressDisplay
              address={extraction_data.all_extracted_addresses.registered_agent_address}
              label="Registered Agent"
            />
          </>
        )}
      </div>

      <div className="mt-4">
        <h4 className="font-semibold">Document Analysis</h4>
        <p className="mt-1 text-slate-700">{extraction_data.analysis}</p>
        <p className="mt-2 text-slate-700">{extraction_data.summary}</p>
      </div>

      {fraud_verification_data?.verification_data && (
        <div className="mt-6">
          {fraud_verification_data.verification_data
            .filter((entry: any) => entry.type === "RISK")
            .map((entry: any, index: number) => (
              <div key={index} className="flex items-start gap-2 py-2 first:pt-0">
                <WarningRounded className="text-yellow-600 flex-shrink-0" sx={{ fontSize: "1rem" }} />
                <p className="font-semibold text-yellow-900">{entry.title}</p>
                <p className="text-yellow-700">{entry.description}</p>
              </div>
            ))}
        </div>
      )}
      {renderVisualInspection()}
    </div>
  );
};

export const IncorporationDocumentExtractionCard: React.FC<IncorporationDocumentExtractionCardProps> = ({
  data,
  handleDocumentValidationClick,
  checkResult,
}) => {
  return (
    <div className="space-y-6">
      {data.valid_documents.map((doc, index) => (
        <DocumentSection
          key={`valid-${index}`}
          document={doc}
          handleDocumentValidationClick={handleDocumentValidationClick}
          checkResult={checkResult}
        />
      ))}

      {data.invalid_documents.map((doc, index) => (
        <DocumentSection
          key={`invalid-${index}`}
          document={doc}
          handleDocumentValidationClick={handleDocumentValidationClick}
          checkResult={checkResult}
        />
      ))}
    </div>
  );
};

export default IncorporationDocumentExtractionCard;
