import React, { useState, FormEvent } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Chip,
  CircularProgress,
  Alert,
  SelectChangeEvent,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { Tenant, Endpoint } from "../../types";
import BusinessIcon from "@mui/icons-material/Business";
import CloseIcon from "@mui/icons-material/Close";
import { validateEmail, validateDomain } from "@parcha-src/utils/validation";
import InputWithOverlappingLabel from "../TailwindComponents/InputWithOverlappingLabel";

interface AddTenantModalProps {
  open: boolean;
  onClose: () => void;
  onAddTenant: (tenant: Tenant, adminUser: { email: string }) => void;
  isLoading: boolean;
  error: string | null;
  availableAgents: Endpoint[];
}

const AddTenantModal: React.FC<AddTenantModalProps> = ({
  open,
  onClose,
  onAddTenant,
  isLoading,
  error,
  availableAgents,
}) => {
  const [tenantData, setTenantData] = useState<Tenant>({
    name: "",
    selfProvisioningDomains: [],
    customAttributes: { agents: [] },
    id: "",
    domains: [],
    createdTime: null,
  } as Tenant);

  const [adminUser, setAdminUser] = useState({ name: "", email: "" });
  const [formError, setFormError] = useState<string | null>(null);
  const [domainError, setDomainError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTenantData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAgentChange = (event: SelectChangeEvent<string[]>) => {
    setTenantData((prev) => ({
      ...prev,
      customAttributes: { ...prev.customAttributes, agents: event.target.value as string[] },
    }));
  };

  const handleDomainChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const domains = e.target.value.split(",").map((d) => d.trim());
    const invalidDomains = domains.filter((d) => d && !validateDomain(d));

    if (invalidDomains.length > 0) {
      setDomainError(`Invalid domain(s): ${invalidDomains.join(", ")}`);
    } else {
      setDomainError(null);
    }

    setTenantData((prev) => ({ ...prev, selfProvisioningDomains: domains }));
  };

  const handleAdminEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    if (email && !validateEmail(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError(null);
    }
    setAdminUser({ ...adminUser, email });
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (
      !tenantData.name ||
      tenantData.selfProvisioningDomains.length === 0 ||
      !adminUser.email ||
      tenantData.customAttributes.agents.length === 0
    ) {
      setFormError("Please fill in all required fields.");
      return;
    }
    setFormError(null);
    onAddTenant(tenantData, adminUser);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          minWidth: "500px",
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: 1,
            borderColor: (theme) => theme.palette.divider,
          }}
        >
          <BusinessIcon sx={{ marginRight: "8px" }} />
          Add New Tenant
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 20,
              top: 20,
              height: 20,
              width: 20,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <InputWithOverlappingLabel
            fieldName="name"
            label="Tenant Name"
            value={tenantData.name}
            onChangeHandler={handleInputChange}
            required={true}
            disabled={isLoading}
            className="w-full mt-8"
          />

          <InputWithOverlappingLabel
            fieldName="selfProvisioningDomains"
            label="Self Provisioning Domains"
            value={tenantData.selfProvisioningDomains.join(", ")}
            onChangeHandler={handleDomainChange}
            required={true}
            disabled={isLoading}
            className="w-full mt-6"
            labelClassName={domainError ? "text-red-500" : ""}
            inputClassName={domainError ? "ring-red-500" : ""}
          />
          {domainError && <p className="mt-1 text-xs text-red-500">{domainError}</p>}

          <InputWithOverlappingLabel
            fieldName="adminEmail"
            label="Admin Email"
            value={adminUser.email}
            onChangeHandler={handleAdminEmailChange}
            required={true}
            disabled={isLoading}
            className="w-full mt-6"
            labelClassName={emailError ? "text-red-500" : ""}
            inputClassName={emailError ? "ring-red-500" : ""}
          />
          {emailError && <p className="mt-1 text-xs text-red-500">{emailError}</p>}

          <FormControl fullWidth margin="dense" required disabled={isLoading} sx={{ mt: 2 }}>
            <InputLabel id="agent-keys-label">Agent Keys</InputLabel>
            <Select
              labelId="agent-keys-label"
              multiple
              input={<OutlinedInput label="Agent Keys" />}
              value={tenantData.customAttributes.agents}
              onChange={handleAgentChange}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {(selected as string[]).map((value) => (
                    <Chip
                      key={value}
                      label={availableAgents.find((agent) => agent.agentKey === value)?.agentName || value}
                    />
                  ))}
                </Box>
              )}
            >
              {availableAgents.map((agent) => (
                <MenuItem key={agent.agentKey} value={agent.agentKey}>
                  {agent.agentName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
          {formError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {formError}
            </Alert>
          )}
        </DialogContent>
        <DialogActions sx={{ borderTop: 1, borderColor: "divider", padding: 2 }}>
          <Button onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : "Add Tenant"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddTenantModal;
