// @ts-ignore
import { DocsPlayground, FlashLoaderResponse } from "@parcha/docs-playground";
import "@parcha/docs-playground/docs-playground.css";
import { getSessionToken } from "@descope/react-sdk";
import { useNavigate, useSearchParams } from "react-router-dom";
import { twJoin } from "tailwind-merge";
import { AutoAwesome, LockRounded, Menu as MenuIcon } from "@mui/icons-material";
import useAuth from "@parcha-src/hooks/useAuth";
import { useState, useEffect } from "react";
import { format } from "date-fns";
import ParchaApi from "@parcha-src/libs/ParchaApi";
import { AgentRun } from "@parcha-src/types";

const getEndpointUrl = () => {
  const envTier = import.meta.env.VITE_ENV_TIER || "development";
  return envTier === "development"
    ? "localhost:8001"
    : envTier === "staging"
      ? "staging.parcha.ai"
      : envTier === "coinbase"
        ? "coinbase.parcha.ai"
        : "demo.parcha.ai";
};

type DocumentType = "business_proof_of_address" | "incorporation" | "ein" | "individual_proof_of_address";

interface JobHistoryResponse {
  items: ExtendedAgentRun[];
  total: number;
  offset: number;
  limit?: number;
}

interface CheckResult {
  command_id: string;
  command_name: string;
  payload: {
    company_name?: string;
    individual_name?: string;
    type: string;
  };
}

interface ExtendedAgentRun extends AgentRun {
  check_results?: CheckResult[];
}

type BadgeConfig = {
  text: string;
  class: string;
};

const DOCUMENT_BADGES: Record<string, BadgeConfig> = {
  "kyb.proof_of_address_verification": { text: "POA", class: "bg-purple-100 text-purple-800" },
  "kyb.ein_document_verification": { text: "EIN", class: "bg-blue-100 text-blue-800" },
  "kyb.incorporation_document_verification": { text: "INC", class: "bg-indigo-100 text-indigo-800" },
  "kyc.proof_of_address_verification": { text: "IPOA", class: "bg-green-100 text-green-800" },
} as const;

const COMMAND_TO_TAB_TYPE: Record<string, DocumentType> = {
  "kyb.proof_of_address_verification": "business_proof_of_address",
  "kyb.ein_document_verification": "ein",
  "kyb.incorporation_document_verification": "incorporation",
  "kyc.proof_of_address_verification": "individual_proof_of_address",
};

export default function FlashCheckPlayground() {
  const sessionToken = getSessionToken();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAuth();
  const [showMenu, setShowMenu] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDocTypeDropdownOpen, setIsDocTypeDropdownOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<DocumentType>("business_proof_of_address");
  const [jobHistory, setJobHistory] = useState<ExtendedAgentRun[]>([]);
  const [selectedJobId, setSelectedJobId] = useState<string | null>(searchParams.get("jobId"));
  const [isLoading, setIsLoading] = useState(false);
  const publicDocsRemaining = user?.customAttributes?.publicDocsQuota - user?.customAttributes?.publicDocsJobsRun || 0;

  const [response, setResponse] = useState<FlashLoaderResponse | null>(null);

  const selectedJob = jobHistory.find((job) => job.id === selectedJobId);
  const selectedCheckResult = selectedJob?.check_results?.[0];

  // Update selected tab based on selected job's document type
  useEffect(() => {
    if (selectedCheckResult?.command_id) {
      const documentType = COMMAND_TO_TAB_TYPE[selectedCheckResult.command_id];
      if (documentType) {
        setSelectedTab(documentType);
      }
    }
  }, [selectedCheckResult]);

  // Update response when initialResponse changes
  useEffect(() => {
    if (selectedCheckResult) {
      setResponse(selectedCheckResult as FlashLoaderResponse);
    }
  }, [selectedCheckResult]);

  // Fetch job history and handle initial job selection
  const fetchJobHistory = async () => {
    setIsLoading(true); // Always set loading state when fetching
    const api = new ParchaApi(() => {});
    try {
      const response: JobHistoryResponse = await api.getAgentJobHistory(
        getEndpointUrl(),
        ["public-docs-v1", "public-docs-kyc-v1"],
        100,
        0,
        true,
      );
      if (response?.items?.length > 0) {
        setJobHistory(response.items);
        // Only select a job if there's a valid jobId in the URL or if we're updating after a new submission
        const urlJobId = searchParams.get("jobId");
        if (urlJobId && response.items.some((job) => job.id === urlJobId)) {
          setSelectedJobId(urlJobId);
        }
      }
    } catch (error) {
      console.error("Error fetching job history:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchJobHistory();
  }, []); // Only run on mount

  const handleResponse = async (jobResponse: any) => {
    const isIndividualPOA = selectedTab === "individual_proof_of_address";

    // Get the check result from the job's check_results array
    const checkResult = jobResponse.check_results?.[0];

    // Create a new job entry from the job response
    const newJob: ExtendedAgentRun = {
      id: jobResponse.id,
      job_id: jobResponse.id,
      case_id: "",
      status: jobResponse.status,
      progress: 100,
      created_at: jobResponse.created_at,
      started_at: jobResponse.started_at,
      completed_at: jobResponse.completed_at,
      owner_id: jobResponse.owner_id,
      recommendation: jobResponse.recommendation,
      check_results: jobResponse.check_results,
      loading_message: null,
      token_count: null,
      agent_key: jobResponse.agent_id,
      status_messages: [],
      input_payload: jobResponse.input_payload,
    };

    // Add the new job to the top of the history
    setJobHistory((prev) => [newJob, ...prev]);

    // Update response state with the check result
    setResponse(checkResult);

    // Select the new job
    if (newJob.id) {
      setSelectedJobId(newJob.id);
      setSearchParams({ jobId: newJob.id.replace(/-/g, "") });
    }
  };

  const handleJobSelect = (jobId: string | null) => {
    setSelectedJobId(jobId);
    if (jobId) {
      setSearchParams({ jobId: jobId.replace(/-/g, "") });
    } else {
      setSearchParams({});
    }
  };

  const handleReset = () => {
    setSelectedJobId(null);
    setResponse(null);
    setSearchParams({});
    setSelectedTab("business_proof_of_address");
  };

  const documentTypeDescriptions: Record<DocumentType, string> = {
    business_proof_of_address:
      "Verify business addresses using various documents like utility bills, bank statements, and lease agreements. Supports multiple document types with configurable validity periods.",
    incorporation:
      "Validate incorporation documents across different US jurisdictions. Ensures business registration and legal entity verification.",
    ein: "Verify Employer Identification Number (EIN) documents. Confirms tax identification and business registration with the IRS.",
    individual_proof_of_address:
      "Verify individual addresses using various documents like utility bills, bank statements, and lease agreements. Supports multiple document types with configurable validity periods.",
  };

  const handleTabChange = (id: string) => {
    // Only allow tab changes if no job is selected
    if (!selectedJobId) {
      setSelectedTab(id as DocumentType);
    }
  };

  const getStatusColor = (status: string, recommendation: string | null | undefined) => {
    if (status === "complete") {
      return recommendation === "Passed" ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800";
    }
    if (status === "in progress") return "bg-blue-100 text-blue-800";
    if (status === "failed") return "bg-red-100 text-red-800";
    return "bg-slate-100 text-slate-800";
  };

  const getDocumentTypeBadge = (commandId: string): BadgeConfig => {
    return DOCUMENT_BADGES[commandId] || { text: "DOC", class: "bg-slate-100 text-slate-800" };
  };

  return (
    <div className="h-full w-full flex flex-col">
      {/* Top Bar */}
      <div className="px-5 py-[0.8rem] flex flex-col md:flex-row items-center justify-between md:gap-3 border-b border-slate-200">
        <div className="flex items-center justify-between w-full md:w-auto">
          <div className="flex items-center gap-x-10">
            <div className="relative z-50">
              <button
                type="button"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="relative inline-flex items-center justify-between px-6 py-2.5 text-lg font-semibold text-slate-900 min-w-[250px] bg-white"
              >
                <span>Flash Document Playground</span>
                <svg
                  className={twJoin(
                    "w-5 h-5 ml-2 -mr-1 transition-transform text-slate-500",
                    isDropdownOpen ? "rotate-180" : "",
                  )}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              {isDropdownOpen && (
                <button
                  type="button"
                  onClick={() => {
                    setIsDropdownOpen(false);
                    navigate("/reports");
                  }}
                  className="absolute top-full left-0 w-full inline-flex items-center px-6 py-2.5 text-lg font-semibold text-slate-600 bg-white hover:text-slate-900 hover:bg-slate-50"
                >
                  Business Due Diligence Report
                </button>
              )}
            </div>
          </div>
          <div className="md:hidden">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-brand-purple px-3 py-2 text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
              onClick={() => setShowMenu(!showMenu)}
            >
              <MenuIcon className="h-4 w-4" sx={{ fontSize: "1rem" }} aria-hidden="true" aria-label="Menu" />
            </button>
            {showMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-30">
                <button
                  type="button"
                  className="block w-full text-left p-4 text-sm text-slate-700 hover:bg-slate-100"
                  onClick={handleReset}
                >
                  <AutoAwesome className="-ml-0.5 h-4 w-4 mr-1" sx={{ fontSize: "1rem" }} aria-hidden="true" />
                  Analyze New Document
                </button>
                <a
                  href="https://calendly.com/ajasver/request-a-demo"
                  className="block w-full text-left p-4 text-sm text-slate-700 hover:bg-slate-100"
                  target="_blank"
                  rel="norefferrer noopener"
                >
                  <LockRounded
                    className="-ml-0.5 h-4 w-4 text-slate-900 mr-1"
                    sx={{ fontSize: "1rem" }}
                    aria-hidden="true"
                  />
                  Get Full Access
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col items-end md:flex-row md:items-center gap-3">
          <div className="hidden md:block">
            {!Number.isNaN(publicDocsRemaining) && (
              <span
                className={twJoin(
                  "text-xs px-2 py-1 font-semibold rounded truncate",
                  publicDocsRemaining > 0 ? "text-green-700 bg-green-100" : "text-red-700 bg-red-100",
                )}
              >
                {` You have ${publicDocsRemaining} free document check${publicDocsRemaining !== 1 ? "s" : ""} left`}
              </span>
            )}
          </div>

          <div className="hidden md:flex md:flex-row md:items-center gap-3">
            <a
              href="https://calendly.com/ajasver/request-a-demo"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-white text-slate-900 px-3 py-2 text-xs font-semibold border border-slate-200 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed"
              target="_blank"
              rel="norefferrer noopener"
            >
              <LockRounded className="-ml-0.5 h-4 w-4 text-slate-900" sx={{ fontSize: "1rem" }} aria-hidden="true" />
              Get Full Access
            </a>
          </div>
        </div>
      </div>

      {/* Content Area with Sidebar and Main Content */}
      <div className="flex-1 flex overflow-hidden">
        {/* Sidebar - Only show if there are jobs */}
        {jobHistory.length > 0 && (
          <div className="w-[25vw] border-r border-slate-200 bg-white overflow-hidden flex flex-col">
            <div className="flex-1 overflow-y-auto">
              {jobHistory.map((job) => {
                const isIndividualPOA = job.check_results?.[0]?.command_id === "kyc.proof_of_address_verification";
                const displayName = isIndividualPOA
                  ? job.check_results?.[0]?.payload?.individual_name || "---"
                  : job.check_results?.[0]?.payload?.company_name || "---";

                const documentBadge = job.check_results?.[0]?.command_id
                  ? getDocumentTypeBadge(job.check_results[0].command_id)
                  : { text: "DOC", class: "bg-slate-100 text-slate-800" };

                return (
                  <button
                    key={job.id}
                    onClick={() => handleJobSelect(job.id || null)}
                    className={twJoin(
                      "w-full p-3 text-left border-b border-slate-100 hover:bg-slate-50 transition-colors",
                      selectedJobId === job.id ? "bg-slate-50" : "bg-white",
                    )}
                  >
                    <div className="flex flex-col gap-1">
                      <div className="flex items-center justify-between">
                        <span className="text-sm font-medium text-slate-900">{displayName}</span>
                        <span className="text-xs text-slate-500">
                          {format(new Date(job.created_at), "MMM d, h:mm a")}
                        </span>
                      </div>
                      <div className="flex items-center justify-between gap-2">
                        <div className="flex items-center gap-2">
                          <span
                            className={twJoin(
                              "px-2 py-0.5 rounded-full text-xs font-medium",
                              getStatusColor(job.status, job.recommendation || null),
                            )}
                          >
                            {job.status === "complete" ? job.recommendation || "Unknown" : job.status}
                          </span>
                          <span className={twJoin("px-2 py-0.5 rounded-full text-xs font-medium", documentBadge.class)}>
                            {documentBadge.text}
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-end">
                        <span className="text-xs text-slate-400 truncate max-w-[200px]">{job.owner_id}</span>
                      </div>
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        )}

        {/* Main Content */}
        <div className="flex-1 flex flex-col overflow-y-auto">
          <div className="flex-1 bg-slate-50 p-3">
            <div className="w-full max-w-8xl mx-auto bg-white rounded-lg shadow-lg p-4">
              <div className="mb-4">
                <div className="flex items-center justify-between gap-4">
                  <div className="flex-1">
                    <h2 className="text-xl font-medium text-gray-900 mb-3">Select Document Type to Validate:</h2>
                    <div className="relative">
                      <button
                        type="button"
                        onClick={() => setIsDocTypeDropdownOpen(!isDocTypeDropdownOpen)}
                        disabled={!!selectedJobId}
                        className={twJoin(
                          "bg-white text-left px-4 py-3 rounded-lg border border-gray-200 flex items-center justify-between min-w-[200px]",
                          selectedJobId ? "opacity-50 cursor-not-allowed" : "hover:border-brand-purple",
                        )}
                      >
                        <div className="flex items-center gap-2">
                          <span className="text-base text-gray-900">
                            {selectedTab === "business_proof_of_address"
                              ? "Business Proof of Address"
                              : selectedTab === "incorporation"
                                ? "Incorporation Documents"
                                : selectedTab === "ein"
                                  ? "EIN Documents"
                                  : "Individual Proof of Address"}
                          </span>
                        </div>
                        <svg
                          className={twJoin(
                            "w-5 h-5 ml-2 transition-transform text-gray-500",
                            isDocTypeDropdownOpen ? "rotate-180" : "",
                          )}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                      {isDocTypeDropdownOpen && !selectedJobId && (
                        <div className="absolute z-10 mt-1 bg-white rounded-lg shadow-lg border border-gray-200 py-1 min-w-[200px]">
                          <button
                            className={twJoin(
                              "w-full px-4 py-2 text-left text-base hover:bg-slate-50 flex items-center gap-2",
                              selectedTab === "business_proof_of_address"
                                ? "bg-blue-50 text-blue-600"
                                : "text-gray-900",
                            )}
                            onClick={() => {
                              handleTabChange("business_proof_of_address");
                              setIsDocTypeDropdownOpen(false);
                            }}
                          >
                            {selectedTab === "business_proof_of_address" && (
                              <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            )}
                            <span className={selectedTab === "business_proof_of_address" ? "ml-2" : "ml-6"}>
                              Business Proof of Address
                            </span>
                          </button>
                          <button
                            className={twJoin(
                              "w-full px-4 py-2 text-left text-base hover:bg-slate-50 flex items-center gap-2",
                              selectedTab === "individual_proof_of_address"
                                ? "bg-blue-50 text-blue-600"
                                : "text-gray-900",
                            )}
                            onClick={() => {
                              handleTabChange("individual_proof_of_address");
                              setIsDocTypeDropdownOpen(false);
                            }}
                          >
                            {selectedTab === "individual_proof_of_address" && (
                              <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            )}
                            <span className={selectedTab === "individual_proof_of_address" ? "ml-2" : "ml-6"}>
                              Individual Proof of Address
                            </span>
                          </button>
                          <button
                            className={twJoin(
                              "w-full px-4 py-2 text-left text-base hover:bg-slate-50 flex items-center gap-2",
                              selectedTab === "incorporation" ? "bg-blue-50 text-blue-600" : "text-gray-900",
                            )}
                            onClick={() => {
                              handleTabChange("incorporation");
                              setIsDocTypeDropdownOpen(false);
                            }}
                          >
                            {selectedTab === "incorporation" && (
                              <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            )}
                            <span className={selectedTab === "incorporation" ? "ml-2" : "ml-6"}>
                              Incorporation Documents
                            </span>
                          </button>
                          <button
                            className={twJoin(
                              "w-full px-4 py-2 text-left text-base hover:bg-slate-50 flex items-center gap-2",
                              selectedTab === "ein" ? "bg-blue-50 text-blue-600" : "text-gray-900",
                            )}
                            onClick={() => {
                              handleTabChange("ein");
                              setIsDocTypeDropdownOpen(false);
                            }}
                          >
                            {selectedTab === "ein" && (
                              <svg className="w-4 h-4" viewBox="0 0 20 20" fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            )}
                            <span className={selectedTab === "ein" ? "ml-2" : "ml-6"}>EIN Documents</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {(selectedJobId || response) && (
                    <button
                      type="button"
                      className="inline-flex items-center gap-x-1.5 rounded-md bg-brand-purple px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-purple/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-purple disabled:opacity-60 disabled:cursor-not-allowed self-end"
                      onClick={handleReset}
                    >
                      <AutoAwesome className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                      Upload New Document
                    </button>
                  )}
                </div>
                <div className="mt-4 p-3 bg-slate-50 rounded-lg">
                  <p className="text-sm text-slate-700">{documentTypeDescriptions[selectedTab]}</p>
                </div>
              </div>
              <DocsPlayground
                key={selectedJobId}
                baseUrl={`${
                  import.meta.env.VITE_ENV_TIER === "development" ? "http" : "https"
                }://${getEndpointUrl()}/auth`}
                agentKey={selectedTab === "individual_proof_of_address" ? "public-docs-kyc-v1" : "public-docs-v1"}
                apiKey={sessionToken}
                type={selectedTab}
                initialResponse={selectedJob}
                playgroundMode={false}
                onResponse={handleResponse}
                sampleDocumentUrls={{
                  individual_proof_of_address:
                    "https://parcha-ai-public-assets.s3.us-east-2.amazonaws.com/sample-docs/customer-poai.pdf",
                  business_proof_of_address:
                    "https://parcha-ai-public-assets.s3.us-east-2.amazonaws.com/sample-docs/parcha-poa.pdf",
                  ein: "https://parcha-ai-public-assets.s3.us-east-2.amazonaws.com/sample-docs/parcha_ein.pdf",
                  incorporation:
                    "https://parcha-ai-public-assets.s3.us-east-2.amazonaws.com/sample-docs/parcha-inc.pdf",
                }}
              />
              {isLoading && (
                <div className="absolute inset-0 bg-white/50 flex items-center justify-center">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-brand-purple"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
