import PinIcon from "@mui/icons-material/Pin";
import BadgeIcon from "@mui/icons-material/Badge";
import BusinessIcon from "@mui/icons-material/Business";
import ChatIcon from "@mui/icons-material/Chat";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import TourIcon from "@mui/icons-material/Tour";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import DescriptionIcon from "@mui/icons-material/Description";
import { format } from "date-fns";
import React from "react";
import { DomainDisabled, MarkunreadMailbox } from "@mui/icons-material";
import { AddressType } from "@/types";

export function getFileType(url: string) {
  const match = url.match(/\.(pdf|jpg|png|jpeg)(?=[?#]|$)/i);
  const extension = match ? match[1].toLowerCase() : null;

  switch (extension) {
    case "pdf":
      return "pdf";
    case "jpg":
    case "png":
    case "jpeg":
      return "image";
    default:
      return "website";
  }
}

export function getLogoFromAgentKey(agentKey: string, props: any): React.ReactElement {
  return <img src="/parcha-white.svg" style={props} />;
}

export function getLogoFromCommandId(commandId: string, props: any) {
  switch (commandId) {
    case "proof_of_address_extractor":
    case "kyb.proof_of_address_verification":
    case "kyc.proof_of_address_verification":
      return <MarkunreadMailbox style={props} />;
    case "kyb.high_risk_industry_tool":
      return <DomainDisabled style={props} />;
    case "kyb.tin_verification.tin_check_tool":
      return <PinIcon style={props} />;
    case "extractors.business_due_diligence_extractor_tool":
    case "kyb.business_due_diligence.open_source_business_due_diligence_tool":
      return <BusinessIcon style={props} />;
    case "kyb.dilisense_sanctions_loader":
    case "kyc.dilisense_sanctions_loader":
    case "kyb.sanctions_watchlist_check":
    case "kyc.sanctions_watchlist_check":
      return <VisibilityIcon style={props} />;
    case "kyb.screening.adverse_media_tool":
    case "kyc.adverse_media_check":
    case "adverse_media_webcheck_kyc":
      return <ReceiptLongIcon style={props} />;
    case "kyc-v0-bridge":
    case "kyc.government_id_verification":
      return <BadgeIcon style={props} />;
    case "kyc.dilisense_pep_loader":
    case "kyc.pep_screening_check":
      return <TourIcon style={props} />;
    case "kyb.business_ownership_verification_tool":
      return <RequestQuoteIcon style={props} />;
    case "kyb.incorporation_document_verification":
      return <DescriptionIcon style={props} />;
    default:
      return <ChatIcon style={props} />;
  }
}

export function isAgentSupervisor(agentKey: string): boolean {
  return agentKey === "bridge-v2";
}
//TODO replace this with either bringing agentType from bakend or with generic kyb supervisor key.

export function snakeCaseToProperCase(id: string) {
  return typeof id === "string"
    ? id.replace(/_/g, " ").replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase())
    : "";
}

export function toTitleCase(str: string) {
  if (typeof str !== "string") return "";

  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());
}

export function percentageToString(percentageValue: number): string {
  return percentageValue >= 0 && percentageValue <= 1
    ? `${(percentageValue * 100).toFixed(0)}%`
    : percentageValue % 1 === 0
      ? `${Math.round(percentageValue)}%`
      : `${percentageValue.toFixed(2)}%`;
}

export function tryToParseDate(dateStr: string): string {
  const parsedDate = new Date(dateStr);
  if (isNaN(parsedDate.getTime())) {
    return dateStr;
  }
  return format(parsedDate, "MMM. d yyyy");
}

export function getJobName(selfAttestedData: any) {
  const name = `${selfAttestedData.first_name ? selfAttestedData.first_name : ""} ${
    selfAttestedData.middle_name ? selfAttestedData.middle_name : ""
  } ${selfAttestedData.last_name ? selfAttestedData.last_name : ""}`;

  return (
    selfAttestedData?.name || selfAttestedData?.business_name || selfAttestedData?.registered_business_name || name
  );
}

export function isAddressProvided(address: {
  street1: string;
  street2: string;
  city: string;
  state: string;
  countryCode: string;
  postalCode: string;
}) {
  if (!address) {
    return false;
  }

  const { street1, street2, city, state, countryCode, postalCode } = address;

  return !(
    (!street1 && !street2 && !city && !state && !countryCode && !postalCode) ||
    Object.entries(address).every(([k, v]) => k === "type" || v === null || v === "unverified" || v === "")
  );
}

export function getFormattedAddressOneLine(address: AddressType) {
  return `${address.street_1}${address.street_2 ? ` ${address.street_2}` : ""}${
    address.city ? `, ${address.city}` : ""
  }${address.state ? `, ${address.state}` : ""}${address.postal_code ? ` ${address.postal_code}` : ""}${
    address.country_code ? `, ${address.country_code}` : ""
  }`;
}

export function formatNumberToCurrency(num: number | null | undefined): string {
  // Return early if num is null/undefined
  if (num === null || num === undefined) {
    return "$0";
  }

  // Convert to number if it's a string
  const value = Number(num);

  // Check if conversion resulted in a valid number
  if (isNaN(value)) {
    return "$0";
  }

  if (value >= 1e9) {
    return `$${(value / 1e9).toFixed(value % 1e9 === 0 ? 0 : 2)}B`; // Billion
  } else if (value >= 1e6) {
    return `$${(value / 1e6).toFixed(value % 1e6 === 0 ? 0 : 2)}M`; // Million
  } else if (value >= 1e3) {
    return `$${(value / 1e3).toFixed(value % 1e3 === 0 ? 0 : 2)}K`; // Thousand
  } else {
    return `$${value.toFixed(value % 1 === 0 ? 0 : 2)}`; // Less than thousand
  }
}

export const calculateAge = (dateOfBirth: string) => {
  let birthDate;
  try {
    birthDate = new Date(dateOfBirth.replaceAll("-", "/"));
    if (isNaN(birthDate.getTime())) {
      throw new Error("Invalid date format");
    }
  } catch (error) {
    console.error("Error parsing date of birth:", error);
    return "Unable to calculate age"; // or handle the error as needed
  }
  const currentDate = new Date();
  const age = currentDate.getFullYear() - birthDate.getFullYear();
  const monthDifference = currentDate.getMonth() - birthDate.getMonth();
  const dayDifference = currentDate.getDate() - birthDate.getDate();

  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    return age - 1;
  }
  return age;
};
