import { useState, useEffect } from "react";
import { Agent, Endpoint, User } from "@/types";
import React from "react";
import { RBACService } from "@/services/RBACService";

interface Roles {
  [key: string]: boolean;
}

interface UserContextType {
  agent: Agent | null;
  user: User | null;
  endpoints: Endpoint[] | null;
  rbacService: RBACService | null;
  roles: Roles;
  login: (user: User) => void;
  logout: () => void;
  saveEndpoints: (agents: Endpoint[]) => void;
  saveAgent: (agent: Agent) => void;
  hasRole: (role: string) => boolean;
  selectedEndpoint: Endpoint | null;
  setSelectedEndpoint: (endpoint: Endpoint | null) => void;
  isRolesReady: boolean;
}

interface UserProviderProps {
  children: React.ReactNode;
}

const UserContext = React.createContext<UserContextType>({} as UserContextType);

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [endpoints, setEndpoints] = useState<Endpoint[] | null>(null);
  const [agent, setAgent] = useState<Agent | null>(null);
  const [rbacService, setRbacService] = useState<RBACService | null>(null);
  const [roles, setRoles] = useState<Roles>({
    parchaUser: false,
    parchaAdmin: false,
    tenantAdmin: false,
    admin: false,
    developer: false,
    analyst: false,
    reader: false,
  });
  const [selectedEndpoint, setSelectedEndpoint] = useState<Endpoint | null>(null);
  const [isRolesReady, setIsRolesReady] = useState(false);

  useEffect(() => {
    if (user && user.userTenants && user.userTenants.length > 0) {
      const userRoles = user.userTenants.flatMap((tenant) => (tenant.roleNames ? tenant.roleNames : []));
      const newRbacService = new RBACService(userRoles);
      setRbacService(newRbacService);

      // Update roles
      const updatedRoles = {
        tenantAdmin: newRbacService.checkIsTenantAdmin(),
        parchaUser: user.userTenants.some((tenant) => tenant.tenantName.includes("Parcha")),
        parchaAdmin: false,
        admin: false,
        developer: false,
        analyst: false,
        reader: false,
      };

      if (updatedRoles.tenantAdmin) {
        updatedRoles.admin = true;
        updatedRoles.developer = true;
        updatedRoles.analyst = true;
        updatedRoles.reader = true;
      }

      updatedRoles.parchaAdmin = updatedRoles.tenantAdmin && updatedRoles.parchaUser;

      setRoles(updatedRoles);
    } else {
      setRbacService(null);
      setRoles({
        parchaUser: false,
        parchaAdmin: false,
        tenantAdmin: false,
        admin: false,
        developer: false,
        analyst: false,
        reader: false,
      });
    }
  }, [user]);

  useEffect(() => {
    if (rbacService && selectedEndpoint) {
      const highestRole = rbacService.getHighestRole(selectedEndpoint.agentKey, selectedEndpoint);
      setRoles((prevRoles) => ({
        ...prevRoles,
        admin: prevRoles.tenantAdmin || highestRole === "admin",
        developer: prevRoles.tenantAdmin || highestRole === "developer" || highestRole === "admin",
        analyst:
          prevRoles.tenantAdmin || highestRole === "analyst" || highestRole === "developer" || highestRole === "admin",
        reader:
          prevRoles.tenantAdmin ||
          highestRole === "reader" ||
          highestRole === "analyst" ||
          highestRole === "developer" ||
          highestRole === "admin",
      }));
      setIsRolesReady(true);
    }
  }, [rbacService, selectedEndpoint]);

  const login = (user: User) => {
    setUser(user);
  };

  const logout = () => {
    setUser(null);
    setEndpoints(null);
    setRbacService(null);
  };

  const saveEndpoints = (endpoints: Endpoint[]) => {
    setEndpoints(endpoints);
  };

  const saveAgent = (agent: Agent) => {
    setAgent(agent);
  };

  const hasRole = (role: string): boolean => {
    return roles[role] || false;
  };

  const handleSetSelectedEndpoint = (endpoint: Endpoint | null) => {
    setIsRolesReady(false);
    setSelectedEndpoint(endpoint);
    if (endpoint) {
      saveAgent({ agent_key: endpoint.agentKey } as Agent);
    } else {
      setAgent(null);
    }
  };

  return (
    <UserContext.Provider
      value={{
        agent,
        saveAgent,
        login,
        logout,
        user,
        endpoints,
        saveEndpoints,
        rbacService,
        roles,
        hasRole,
        selectedEndpoint,
        setSelectedEndpoint: handleSetSelectedEndpoint,
        isRolesReady,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};

export default UserContext;
