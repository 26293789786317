import React, { useState, useCallback, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Skeleton,
  Button,
  Chip,
  Stack,
  Select,
  MenuItem,
  SelectChangeEvent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Tooltip,
  CircularProgress,
  OutlinedInput,
  InputLabel,
  FormControl,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import { useParams, useNavigate } from "react-router-dom";

import { Endpoint, UserDetails } from "../../types";
import InviteUserModal from "./InviteUserModal";
import { useParchaApi } from "@parcha-src/hooks/useParchaApi";
import AdminNavbar from "./AdminNavbar";
import { useUserContext } from "@/contexts/UserContext";

const UsersTable: React.FC = () => {
  const { endpoints, agent: contextAgent, hasRole, rbacService } = useUserContext();
  const [users, setUsers] = useState<UserDetails[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<string>(contextAgent?.agent_key || "");
  const [isLoading, setIsLoading] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserDetails | null>(null);
  const [openRoleConfirmation, setOpenRoleConfirmation] = useState(false);
  const [openRemoveConfirmation, setOpenRemoveConfirmation] = useState(false);
  const [pendingRoleChange, setPendingRoleChange] = useState<{ user: UserDetails; newRole: string } | null>(null);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isChangingRole, setIsChangingRole] = useState(false);
  const parchaApi = useParchaApi();
  const [adminAgents, setAdminAgents] = useState<Endpoint[]>([]);
  const [availableAgents, setAvailableAgents] = useState<Endpoint[]>([]);
  const [canViewUsers, setCanViewUsers] = useState<boolean>(false);
  const { agentKey } = useParams();
  const navigate = useNavigate();

  const fetchUsers = useCallback(
    async (agentKey: string) => {
      setIsLoading(true);
      try {
        const fetchedUsers = await parchaApi.getAgentUsers(agentKey);
        setUsers(fetchedUsers || []);
      } catch (error) {
        setUsers([]);
      } finally {
        setIsLoading(false);
      }
    },
    [parchaApi],
  );

  const handleInvite = async (newUser: { email: string; role: string }) => {
    try {
      await parchaApi.addAccessToAgent({
        target_user_id: newUser.email,
        agent_key: selectedAgent,
        agent_role: newUser.role,
      });
      setOpenInviteModal(false);
      await fetchUsers(selectedAgent);
    } catch (error) {
      console.error("Error inviting user:", error);
    }
  };

  const handleAgentChange = (event: SelectChangeEvent<string>) => {
    const newAgentKey = event.target.value;
    if (newAgentKey) {
      navigate(`/admin/users/${newAgentKey}`);
    } else {
      navigate('/admin/users');
    }
  };

  const handleRoleChangeConfirmation = (user: UserDetails, newRole: string) => {
    setPendingRoleChange({ user, newRole });
    setOpenRoleConfirmation(true);
  };

  const handleRoleChange = async () => {
    if (!pendingRoleChange) return;
    const { user, newRole } = pendingRoleChange;

    setIsChangingRole(true);
    const currentRole = getRoleForSelectedAgent(user);

    try {
      if (newRole === "No Access") {
        // Remove role
        await parchaApi.updateUserRole({
          user_id: user.user_id,
          agent_key: selectedAgent,
          role: currentRole,
          action: "delete",
        });
      } else {
        // Add or change role
        await parchaApi.updateUserRole({
          user_id: user.user_id,
          agent_key: selectedAgent,
          role: newRole,
          action: "add",
        });

        // If changing from an existing role, remove the old role
        if (currentRole !== "No Access") {
          await parchaApi.updateUserRole({
            user_id: user.user_id,
            agent_key: selectedAgent,
            role: currentRole,
            action: "delete",
          });
        }
      }

      await fetchUsers(selectedAgent);
    } catch (error) {
      console.error("Error updating user role:", error);
    } finally {
      setIsChangingRole(false);
      setOpenRoleConfirmation(false);
      setPendingRoleChange(null);
    }
  };

  const handleOpenRemoveConfirmation = (user: UserDetails) => {
    setSelectedUser(user);
    setOpenRemoveConfirmation(true);
  };

  const handleRemoveAccess = async () => {
    if (!selectedUser || !selectedAgent) return;

    setIsRemoving(true);
    const currentRole = getRoleForSelectedAgent(selectedUser);

    try {
      if (currentRole !== "No Access") {
        await parchaApi.updateUserRole({
          user_id: selectedUser.user_id,
          agent_key: selectedAgent,
          role: currentRole,
          action: "delete",
        });
      }
      await fetchUsers(selectedAgent);
    } catch (error) {
      console.error("Error removing user access:", error);
    } finally {
      setIsRemoving(false);
      setSelectedUser(null);
      setOpenRemoveConfirmation(false);
    }
  };

  const getRoleForSelectedAgent = (user: UserDetails) => {
    const role = user.roles.find((r) => r.startsWith(selectedAgent));
    return role ? role.split("_")[1] : "No Access";
  };

  const getAgentName = useCallback(
    (agentKey: string) => {
      return endpoints?.find((agent) => agent.agentKey === agentKey)?.agentName || agentKey;
    },
    [endpoints],
  );

  const roleDescriptions = {
    admin: "All access including member management",
    developer: "API and configuration access",
    analyst: "Data analysis and reporting access",
    reader: "View and run case access",
  };

  useEffect(() => {
    if (endpoints) {
      const filteredAgents = endpoints.filter((agent) => {
        // Check if the user has admin role for this agent
        const isAdmin = rbacService?.checkDynamicRole(agent.agentKey, "admin");
        return isAdmin && agent.rbac === true;
      });
      setAdminAgents(filteredAgents);
    }
  }, [endpoints, hasRole, rbacService]);

  useEffect(() => {
    if (agentKey && endpoints) {
      const agentExists = endpoints.some(endpoint => endpoint.agentKey === agentKey);
      if (agentExists) {
        setSelectedAgent(agentKey);
      } else {
        // If agent doesn't exist, redirect to base users page
        navigate('/admin/users');
      }
    }
  }, [agentKey, endpoints, navigate]);

  useEffect(() => {
    if (endpoints) {
      // Show all agents the user has any access to
      const userAccessibleAgents = endpoints.filter((agent) => {
        return rbacService?.getHighestRole(agent.agentKey, agent) !== null;
      });
      setAvailableAgents(userAccessibleAgents);
    }
  }, [endpoints, rbacService]);

  useEffect(() => {
    if (selectedAgent && rbacService) {
      const selectedEndpoint = endpoints?.find(endpoint => endpoint.agentKey === selectedAgent);
      // Can view users if user is admin AND agent has RBAC enabled
      const hasAdminAccess = rbacService.checkDynamicRole(selectedAgent, "admin");
      const hasRbacEnabled = selectedEndpoint?.rbac === true;
      setCanViewUsers(hasAdminAccess && hasRbacEnabled);

      // Only fetch users if we have permission to view them
      if (hasAdminAccess && hasRbacEnabled) {
        fetchUsers(selectedAgent);
      } else {
        setUsers([]);
      }
    }
  }, [selectedAgent, rbacService, endpoints, fetchUsers]);

  const renderTableContent = () => {
    if (!selectedAgent) {
      return (
        <TableRow>
          <TableCell colSpan={6} align="center">
            <Typography>Please select an agent to view users.</Typography>
          </TableCell>
        </TableRow>
      );
    }

    if (!canViewUsers) {
      return (
        <TableRow>
          <TableCell colSpan={6} align="center">
            <Stack spacing={1} alignItems="center" className="my-6 ">
              <Typography variant="body2" fontWeight="bold">
                This feature is not available for {getAgentName(selectedAgent)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Either the agent doesn't support user management or you don't have sufficient permissions.
              </Typography>
            </Stack>
          </TableCell>
        </TableRow>
      );
    }

    if (isLoading) {
      return [...Array(5)].map((_, index) => (
        <TableRow key={index}>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell align="right">
            <Skeleton variant="rectangular" width={60} height={24} />
          </TableCell>
          <TableCell align="right">
            <Skeleton variant="circular" width={24} height={24} />
          </TableCell>
        </TableRow>
      ));
    }

    if (users.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={6} align="center">
            <Stack spacing={2} alignItems="center">
              <Typography variant="body1">No users have access to this agent yet.</Typography>
              <Button 
                variant="contained" 
                startIcon={<PersonAddIcon />} 
                onClick={() => setOpenInviteModal(true)}
                disabled={!canViewUsers}
              >
                Invite New Members
              </Button>
            </Stack>
          </TableCell>
        </TableRow>
      );
    }

    return users.map((user) => (
      <TableRow key={user.user_id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell component="th" scope="row">
          {user.descope_user.status === "invited" ? (
            <Typography variant="body1" sx={{ fontStyle: "italic", color: "text.secondary" }}>
              -
            </Typography>
          ) : (
            <Typography variant="body1">{user.name}</Typography>
          )}
        </TableCell>
        <TableCell>
          <Typography variant="body2" color="text.secondary">
            {user.email}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body2" color="text.secondary">
            {user.tenant_info?.name || '-'}
          </Typography>
        </TableCell>
        <TableCell>
          <Select
            value={getRoleForSelectedAgent(user)}
            onChange={(e) => handleRoleChangeConfirmation(user, e.target.value as string)}
            size="small"
            variant="standard"
            disableUnderline
            sx={{
              "&:before": { borderBottom: "none" },
              "&:after": { borderBottom: "none" },
              "&:hover:not(.Mui-disabled):before": { borderBottom: "none" },
            }}
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="developer">Developer</MenuItem>
            <MenuItem value="analyst">Analyst</MenuItem>
            <MenuItem value="reader">Reader</MenuItem>
          </Select>
        </TableCell>
        <TableCell align="right">
          {user.descope_user.status === "enabled" ? (
            <Chip
              label="Active"
              size="small"
              sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
                color: (theme) => theme.palette.primary.contrastText,
              }}
            />
          ) : user.descope_user.status === "invited" ? (
            <Chip
              label="Invited"
              size="small"
              sx={{
                backgroundColor: (theme) => theme.palette.grey[300],
                color: (theme) => theme.palette.grey[700],
              }}
            />
          ) : (
            user.descope_user.status || "Unknown"
          )}
        </TableCell>
        <TableCell align="right">
          <IconButton onClick={() => handleOpenRemoveConfirmation(user)}>
            <DeleteIcon className="text-red-600" />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <>
      <AdminNavbar
        title="Users"
        leftContent={
          <FormControl>
            <InputLabel id="agent-label">Agent</InputLabel>
            <Select
              input={<OutlinedInput label="Agent" />}
              value={selectedAgent || ''}
              onChange={handleAgentChange}
              displayEmpty
              inputProps={{ "aria-label": "Select agent" }}
              size="small"
              sx={{ minWidth: 120 }}
            >
              <MenuItem value="" disabled>
                Select Agent
              </MenuItem>
              {availableAgents.map((agent) => (
                <MenuItem key={agent.agentKey} value={agent.agentKey}>
                  {agent.agentName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
        rightContent={
          <Button
            variant="contained"
            startIcon={<PersonAddIcon />}
            onClick={() => setOpenInviteModal(true)}
            disabled={!selectedAgent || !canViewUsers}
          >
            Add Members
          </Button>
        }
      />

      <TableContainer component={Paper} elevation={0}>
        <Table sx={{ minWidth: 650 }} aria-label="users table">
          <TableHead>
            <TableRow className="bg-slate-100" sx={{ height: "62px" }}>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Tenant</TableCell>
              <TableCell>
                Role
                <Tooltip
                  title={
                    <div>
                      {Object.entries(roleDescriptions).map(([role, description]) => (
                        <p key={role}>
                          <strong>{role.charAt(0).toUpperCase() + role.slice(1)}:</strong> {description}
                        </p>
                      ))}
                    </div>
                  }
                  arrow
                >
                  <IconButton size="small" sx={{ ml: 1 }}>
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderTableContent()}
          </TableBody>
        </Table>
      </TableContainer>

      <InviteUserModal
        open={openInviteModal}
        onClose={() => setOpenInviteModal(false)}
        onInvite={(users) => Promise.all(users.map(handleInvite)).then(() => {})}
        agentKey={selectedAgent}
      />

      <Dialog
        open={openRoleConfirmation}
        onClose={() => !isChangingRole && setOpenRoleConfirmation(false)}
        PaperProps={{
          style: {
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: 1,
            borderColor: (theme) => theme.palette.divider,
          }}
        >
          <EditIcon sx={{ marginRight: "8px" }} />
          Change role
          <IconButton
            aria-label="close"
            onClick={() => !isChangingRole && setOpenRoleConfirmation(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            disabled={isChangingRole}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ mb: 2, mt: 4 }}>
          <DialogContentText>
            Are you sure you want to change the role of {pendingRoleChange?.user.name} to {pendingRoleChange?.newRole}{" "}
            for {getAgentName(selectedAgent)}?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            borderTop: 1,
            borderColor: (theme) => theme.palette.divider,
            p: 2,
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={() => setOpenRoleConfirmation(false)} disabled={isChangingRole}>
            Cancel
          </Button>
          <Button
            onClick={handleRoleChange}
            variant="contained"
            disabled={isChangingRole}
            startIcon={isChangingRole ? <CircularProgress size={20} color="inherit" /> : null}
          >
            {isChangingRole ? "Changing..." : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openRemoveConfirmation}
        onClose={() => !isRemoving && setOpenRemoveConfirmation(false)}
        PaperProps={{
          style: {
            borderRadius: "8px",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: 1,
            borderColor: (theme) => theme.palette.divider,
          }}
        >
          <PersonRemoveIcon sx={{ marginRight: "8px" }} />
          Remove access
          <IconButton
            aria-label="close"
            onClick={() => !isRemoving && setOpenRemoveConfirmation(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            disabled={isRemoving}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ mb: 2, mt: 4 }}>
          <DialogContentText>
            {selectedUser?.name || selectedUser?.email} will immediately lose access to {getAgentName(selectedAgent)}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            borderTop: 1,
            borderColor: (theme) => theme.palette.divider,
            p: 2,
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={() => setOpenRemoveConfirmation(false)} disabled={isRemoving}>
            Cancel
          </Button>
          <Button
            onClick={handleRemoveAccess}
            variant="contained"
            disabled={isRemoving}
            startIcon={isRemoving ? <CircularProgress size={20} color="inherit" /> : null}
          >
            {isRemoving ? "Removing..." : "Remove"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UsersTable;
