import { AddressValueProps } from "./AddressValue";
import WrappedValue from "./WrappedValue";
import ValueContainer from "./ValueContainer";
import PDFThumbnail from "./PDFThumbnail";

type EINCardProps = {
  addressOfOperation: AddressValueProps;
  businessName: string;
  documents: {
    document: {
      file_name: string;
      url: string;
    };
    date: string;
    document_type: string;
    summary: string;
    is_valid_document: boolean | null;
  }[];
  einNumber: string;
  level: "success" | "failure" | "normal" | undefined;
  handleDocumentValidationClick?: (checkResult: any, document: any) => void;
  checkResult?: any;
};

const EINCard = ({
  addressOfOperation = {
    street1: null,
    street2: null,
    city: null,
    state: null,
    countryCode: null,
    postalCode: null,
  },
  businessName,
  documents,
  einNumber,
  level,
  handleDocumentValidationClick,
  checkResult,
}: EINCardProps) => {
  if (!documents?.length) return null;

  const { street1, street2, city, state, countryCode, postalCode } = addressOfOperation;
  const addressLine1 = `${street1 ?? ""}${street2 ? `, ${street2}` : ""}`;
  const addressLine2 = `${city ?? ""}${state ? `, ${state}` : ""}${countryCode ? ` ${countryCode}` : ""}${
    postalCode ? ` ${postalCode}` : ""
  }`;

  return (
    <div className="grid grid-cols-2 gap-4 text-xs w-[40rem]">
      <div className="flex flex-col gap-y-2">
        <ValueContainer label="Valid Document" value={documents[0]?.is_valid_document ? "Yes" : "No"} />
        <ValueContainer label="EIN" value={<WrappedValue text={einNumber} level={level} />} />
        <ValueContainer label="Registered Business Name" value={businessName} />
        <ValueContainer
          label="Address of Operation"
          value={
            <>
              <p>{addressLine1}</p>
              <p>{addressLine2}</p>
            </>
          }
        />
      </div>
      <div className="flex flex-col gap-y-2 h-full">
        {documents.map((document) => (
          <>
            <div className="h-full">
              <PDFThumbnail
                documentURL={document.document.url}
                documentName={document.document.file_name}
                onDocumentNameClick={() =>
                  handleDocumentValidationClick?.(checkResult, {
                    file_name: document.document.file_name,
                    url: document.document.url,
                  })
                }
              />
            </div>

            <ValueContainer label="Document Type" value={document.document_type} />
          </>
        ))}
      </div>
    </div>
  );
};

export default EINCard;
